<template>

    <CreateAndEditLayaout :name="name" :path="path" :breadcrumbs="breadcrumbs" :isState="true"/>

</template>


<script>
import CreateAndEditLayaout from '@/components/general/CreateAndEditLayaout.vue'

export default {
    name: 'NewState',
    components: { CreateAndEditLayaout },
    data() {
        return {
            name: {
                button: 'Guardar Estado',
                title: 'Nuevo Estado'
            },
            path: '/states',
            breadcrumbs: [
                { label: 'Expedientes', to: '/expedientes' },
                { label: 'Estados', to: '/expedientes/estados' },
                { label: 'Nuevo Estado', to: '/expedientes/estados/crear-estado' }],
        }
    }

}
</script>
