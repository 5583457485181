<template>
    <div class="notification">
        <img v-if="!read" src="@/assets/icons/ellipse.svg" alt="ellipse">
        
        <div class="date-box">
            <p class="date">{{ date }}</p>
            <p class="time">{{ time }}</p>
        </div>

        <div class="body">
            <p v-if="!fileNumber">Se ha añadido nueva documentación por el informante <strong>{{ instructor }}</strong> en el expediente.</p>
            <p v-if="fileNumber">Se ha añadido nueva documentación por el informante <strong>{{ instructor }}</strong> en el <strong>expediente Nº{{ fileNumber }}</strong> en el caso más reciente.</p>
            <div class="buttons" v-if="acceptance">
                <button v-if="!acceptButton" @click="accept" class="aceptar">Aceptar</button>
                <button v-if="acceptButton" class="aceptada">Aceptada</button>
                <button v-if="!denyButton" @click="deny" class="denegar">Denegar</button>
                <button v-if="denyButton" class="denegada">Denegada</button>
            </div>
        </div>



    </div>
</template>


<script>
export default {
    name: 'NotificationBoxComponent',
    props: {
        read: Boolean,
        acceptance: Boolean,
        date: String,
        time: String,
        instructor: String,
        fileNumber: Number
    },
    data() {
        return {
            acceptButton: false,
            denyButton: false
        };
    },
    methods: {
        accept() {
            if (!this.denyButton) {
                this.acceptButton = true;
            }
        },
        deny() {
            if (!this.acceptButton) {
                this.denyButton = true;
            }
        }
    }

}
</script>
 
<style scoped lang="scss">

* {
    font-family: "InterRegular";
}

.notification {
    display: flex;
    margin: 12px 16px 12px 16px;
    position: relative;
    border-bottom: 1px solid var(--Verde-Menta-0, #E7F0F2);
    img {
        position: absolute;
        width: 6px;
        height: 6px;
        color: #163F49;
        top: 12px;
        left: 9px
    }
    .date-box {
        margin: 4px 10px 12px 26px;
        .date, .time {
            font-size: 10px;
            font-weight: 500;
            line-height: 13px;
            text-align: left;
            color: #A2ABAD;
            width: 47px;
            height: 12px;
        }
    }

    .body {
        margin-bottom: 12px;
        width: 100%;;
        p {
            height: auto;
            font-size: 12px;
            font-weight: 400;
            line-height: 15.6px;
            text-align: left;
            color: #0E0E0F;
        }
        .buttons {
            display: flex;
            justify-content: space-around;
            margin-top: 12px;
            button {
                width: 100%;
                height: 32px;
                border-radius: 4px;
                border: 1px solid var(--Verde-Ultra-Oscuro-Dedavara, #0D2930);
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                text-align: center;
                color: #163F49;
                background: none;
                cursor: pointer;
            }
            .aceptar, .aceptada {
                margin-right: 12px;
            }
            .aceptada {
                background-color: #163F49;
                color: #F9FBFC;
                border: none;
            }
            .denegada {
                background-color: #55737A;
                color: #F9FBFC;
                border: none;
            }
        }
    }
}

@media (min-width: 769px) {
    .notification {
        min-height: 72px;
        border-radius: 4px;
        img {
            top: 8px;
        }
        .date-box {
            display: flex;
            width: 119px;
            margin-top: 2px;
            margin-right: 32px;
            .date, .time {
                width: auto;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                text-align: left;

            }
            .date::after {
                content: '-';
                color: #A2ABAD;
                margin: 0 2px 0 2px;
            }
        }

        .body {
            display: flex;
            width: 100%;
            p {
                margin-right: 12px;
                font-size: 16px;
                font-weight: 400;
                line-height: 20.8px;
                text-align: left;

                strong {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20.8px;
                    text-align: left;
                }
            }

            .buttons {
                margin-left: auto;
                width: auto;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                text-align: center;
                margin-top: 0;
    
                button {
                    width: 99px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.36px;
                    text-align: center;
                }
            }
        }

    }
}

</style>