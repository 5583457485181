<template>
    <div ref="companyLayaout" class="template" :class="{ 'message-on-screen': this.messageOnScreen }">

        <generalTitle
            :title="this.editMode ? this.$t(`${this.$route.name}.editTitle`) : this.$t(`${this.$route.name}.newTitle`)"
            :breadcrumbs="this.breadcrumbsList" @goBack="this.goBack()" />

        <div :style="this.menssageActive ? { opacity: '1' } : { opacity: '0' }" class="mensaje-div">
            <InfoMessage :isActive="true" :text="this.messageText" :icon="this.messageIcon ? 'info' : 'error'"
                :color="!messageColorDefault ? '#C74D4D' : ''" />
        </div>

        <div class="company-departments">
            <form action="#" class="new-company-form"
                @submit.prevent="this.editMode ? this.editCompany() : this.createCompany()">
                <div class="form__inputs">
                    <div class="inputs__logo" @click="this.openInput()">
                        <input type="file" name="companyLogo" id="companyLogo" class="input-logo" accept="image/*"
                            @change="this.changeImage($event)">
                        <img v-if="this.previewSrc !== null" :src="this.previewImage()" alt="" class="company-logo"
                            id="company-logo">
                        <span v-else class="logo__placeholder">{{
                            this.$t(`${this.$route.name}.form.companyLogoPlaceholder`) }}</span>
                    </div>
                    <div class="inputs__body">
                        <GeneralInput :vModel="this.name.value" :error="this.name.error"
                            :errorMessage="this.name.errorMessage"
                            :labelName="this.$t(`${this.$route.name}.form.companyFullName`)" labelFor="fullName"
                            @update-value="this.name.value = $event;" class="input-text" />
                        <GeneralInput :vModel="this.brand.value" :error="this.brand.error"
                            :errorMessage="this.brand.errorMessage"
                            :labelName="this.$t(`${this.$route.name}.form.companyBrand`)" labelFor="companyBrand"
                            @update-value="this.brand.value = $event;" class="input-text" />
                        <GeneralInput :vModel="this.cif.value" :error="this.cif.error"
                            :errorMessage="this.cif.errorMessage"
                            :labelName="this.$t(`${this.$route.name}.form.companyCif`)" labelFor="companyCIF"
                            @update-value="this.cif.value = $event;" class="input-text" />
                        <GeneralInput :vModel="this.address.value" :error="this.address.error"
                            :errorMessage="this.address.errorMessage"
                            :labelName="this.$t(`${this.$route.name}.form.companyAddress`)" labelFor="companyAddress"
                            @update-value="this.address.value = $event;" class="input-text" />
                        <GeneralInput :vModel="this.email.value" :error="this.email.error"
                            :errorMessage="this.email.errorMessage"
                            :labelName="this.$t(`${this.$route.name}.form.companyEmail`)" labelFor="companyEmail"
                            @update-value="this.email.value = $event;" class="input-text" />
                        <GeneralInput :vModel="this.tlf.value" :error="this.tlf.error"
                            :errorMessage="this.tlf.errorMessage"
                            :labelName="this.$t(`${this.$route.name}.form.companyTlf`)" labelFor="companyTlf"
                            @update-value="this.tlf.value = $event;" class="input-text" />
                        <GeneralSelect name="language" id="language" :options="this.languageOptions"
                            :error="this.language.error" :optionSelected="this.language.value"
                            @optionChanged="this.language.value = $event;" :errorMessage="this.language.errorMessage"
                            :placeholder="this.$t(`${this.$route.name}.form.companyCountryPlaceholder`)"
                            :label="this.$t(`${this.$route.name}.form.companyLanguage`)" class="body-select" />
                        <GeneralSelect name="country" id="country" :options="this.countriesList"
                            :error="this.country.error" :optionSelected="this.country.value"
                            @optionChanged="this.country.value = $event;" :errorMessage="this.country.errorMessage"
                            :placeholder="this.$t(`${this.$route.name}.form.companyCountryPlaceholder`)"
                            :label="this.$t(`${this.$route.name}.form.companyCountry`)" class="body-select" />
                    </div>
                </div>
                <div class="form__button">
                    <button class="save-company" v-if="!this.loading">{{ this.$t(`${this.$route.name}.form.saveCompany`)
                        }}</button>
                    <button class="save-company" v-else>
                        <VueSpinner size="30" color="#FFFFFF"></VueSpinner>
                    </button>
                </div>
            </form>
            <div class="departments-container" :class="{ 'message-on-screen': this.messageOnScreen }">
                <div class="title-button">
                    <h2>{{ this.$t(`${this.$route.name}.departments`) }}</h2>
                    <button @click="toggleModalDepartment" class="departments-button">Crear departamento</button>
                </div>
                <div v-if="!editMode" class="departments">
                    <div class="departments-list">
                        <DepartmentBox @editDepartmentActive="editDepartmentActive" class="department-box"
                            v-for="(department, index) in this.$store.getters.getDepartments" :key="index"
                            :name="department.name" :language="department.language.label" :index="index"
                            @deletedDepartment="this.deleteDepartment($event)" :languageOptions="languageOptions" />
                    </div>
                </div>
                <div v-else class="departments">
                    <div class="departments-list">
                        <DepartmentBox @editDepartmentActive="editDepartmentActive" class="department-box"
                            v-for="(department, index) in this.departments" :key="index" :name="department.name"
                            :language="department.languageText" :id="department.id"
                            @deletedDepartment="this.deleteDepartment($event)" :languageOptions="languageOptions" />
                    </div>

                </div>
                <button v-if="!this.loading" class="save-company-departments"
                    @click="this.editMode ? this.editCompany() : this.createCompany()">{{
                        this.$t(`${this.$route.name}.form.saveCompany`) }}
                </button>
                <button v-else class="save-company-departments"
                    @click="this.editMode ? this.editCompany() : this.createCompany()">
                    <VueSpinner size="30" color="#FFFFFF"></VueSpinner>
                </button>
            </div>
        </div>
    </div>


    <!--Modal de eliminación-->

    <DeleteModalTable v-if="isDeleteModal" @trigger-fetch="getDepartments" :lastSingularId="lastSingularId"
        :deleteModalText="deleteModalText" option="simple" @toggleModal="toggleDeleteModal" path="/departments" />

    <!--Modal de departamento-->

    <ModalDepartment v-if="isModalDeparment" @getDepartments="getDepartments" :companyId="id"
        @setEditDeparmentToFalse="setEditDeparmentToFalse" :editMode="editMode" :editDeparment="editDeparment"
        :languageOptions="languageOptions" @toggle="toggleModalDepartment" />

    <!-- Modal de advertencia si no hay departamentos creados -->

    <div v-if="advertModal" @mousedown="(e) => existModal(e)" class="modal">
        <div class="box">
            <h2>No se ha añadido ningún departamento</h2>
            <p>¿Desea crear la empresa sin añadir departamentos? <br /> <br /> Podrá añadir departamentos siempre que lo
                desee al pulsar editar en la empresa.</p>
            <div class="buttons">
                <button @click="toggleAdverModal" class="cancel-btn">Volver para atrás</button>
                <button @click="postCompany" class="action-btn2">Crear</button>
            </div>
        </div>
    </div>


</template>


<script>
import DepartmentBox from '@/components/empresa/DepartmentBox.vue';
// import Pagination from '@/components/forms/Pagination.vue';
import GeneralInput from '@/components/forms/Input.vue';
import GeneralSelect from '@/components/forms/Select.vue';
// import alert from '@/components/general/Alert.vue';
import axios from 'axios';
import { VueSpinner } from 'vue3-spinners';
import generalTitle from '@/components/general/generalTitle.vue';
import InfoMessage from '@/components/general/InfoMessage.vue';
import ModalDepartment from '../general/ModalDepartment.vue';
import { mapGetters } from 'vuex';
import DeleteModalTable from '../categories/DeleteModalTable.vue';



export default {
    name: 'DashboardComponent',
    components: {
        DepartmentBox,
        // Pagination,
        GeneralInput,
        GeneralSelect,
        // alert,
        VueSpinner,
        generalTitle,
        InfoMessage,
        ModalDepartment,
        DeleteModalTable
    },
    props: {

    },

    data() {
        return {
            isErrorMessage: false,
            lastSingularId: '',
            isDeleteModal: false,
            deleteModalText: {
                title: '¿Desea eliminar el departamento seleccionado?',
                namePlural: 'departamento',
                textSingular: 'Se eliminará el departamento seleccionado',
                errorSingular: 'No se puede eliminar el departamento seleccionado porque se está usando en expedientes',
            },
            advertModal: false,
            isModalDeparment: false,
            editDeparment: {
                isEdit: false,
                name: '',
                language: '',
                index: 0,
                deparmentId: ''
            },
            departmentData: {
                name: {
                    value: '',
                    error: false,
                }
            },
            shouldWatch: {
                name: true,
                brand: true,
                previewSrc: true,
                language: true,
                cif: true,
                address: true,
                email: true,
                tlf: true,
                country: true
            },
            shouldCheck: {
                name: true,
                brand: true,
                previewSrc: true,
                language: true,
                cif: true,
                address: true,
                email: true,
                tlf: true,
                country: true
            },
            previewSrc: null,
            id: null,
            name: {
                value: '',
                error: false,
                errorMessage: ''
            },
            brand: {
                value: '',
                error: false,
                errorMessage: ''
            },
            cif: {
                value: '',
                error: false,
                errorMessage: ''
            },
            address: {
                value: '',
                error: false,
                errorMessage: ''
            },
            email: {
                value: '',
                error: false,
                errorMessage: ''
            },
            tlf: {
                value: '',
                error: false,
                errorMessage: ''
            },
            country: {
                value: '',
                error: false,
                errorMessage: ''
            },
            language: {
                value: '',
                error: false,
                errorMessage: ''
            },
            languageOptions: [],
            img: null,
            editMode: false,
            departments: [],
            pagination: {
                previousPage: 0,
                currentPage: 1,
                nextPage: 2,
                lastPage: 0
            },
            limit: 25,
            success: false,
            messageText: '',
            messageIcon: true,
            messageColorDefault: true,
            errorMessage: 'Error al crear la empresa. Inténtelo de nuevo.',
            error: false,
            menssageActive: false,
            isDisable: true,
            loading: false,
            breadcrumbsList: [
                {
                    label: 'a',
                    to: '/companies'
                },
                {
                    label: 'a',
                    to: '/companies/create-company'
                }
            ],
            countriesList: [
                { value: 'spain', label: 'España' }
            ]

        };
    },
    created() {
        this.getLanguages();
        var storeCompany = this.$store.getters.getCompany;
        this.breadcrumbsList = [
            {
                label: this.$t(`${this.$route.name}.breadCrumbs.companies`),
                to: '/companies'
            },
            {
                label: this.$t(`${this.$route.name}.breadCrumbs.newCompany`),
                to: '/companies/create-company'
            }
        ]
        if (storeCompany !== null) {
            this.name.value = storeCompany.name;
            this.brand.value = storeCompany.brand;
            this.cif.value = storeCompany.cif;
            this.address.value = storeCompany.address;
            this.email.value = storeCompany.email;
            this.tlf.value = storeCompany.tlf;
            this.country.value = storeCompany.country;
            this.previewSrc = storeCompany.logo;
            this.language.value = storeCompany.language;
        }
        if (this.$route.params.company) {
            this.id = this.$route.params.company;
            this.editMode = true;
            this.getCompanyInfo();
            this.getDepartments();
            this.breadcrumbsList = [
                {
                    label: this.$t(`${this.$route.name}.breadCrumbs.companies`),
                    to: '/companies'
                },
                {
                    label: this.$t(`${this.$route.name}.breadCrumbs.editCompany`),
                    to: `/companies/edit-company/${this.id}`
                }
            ]
        }

    },
    methods: {

        toggleDeleteModal() {
            this.isDeleteModal = !this.isDeleteModal
        },
        editDepartmentActive(value) {

            this.editDeparment.isEdit = true
            this.editDeparment.name = value.name
            this.editDeparment.language = value.language
            if (value.existed) {
                this.editDeparment.deparmentId = value.deparmentId
            } else {
                this.editDeparment.index = value.index
            }


            this.toggleModalDepartment()


        },
        setEditDeparmentToFalse() {
            this.editDeparment.isEdit = false
        },
        toggleAdverModal() {
            this.advertModal = !this.advertModal
        },
        existModal(e) {
            if (e.target.closest('.box')) return
            this.toggleAdverModal()
        },
        toggleModalDepartment() {
            this.isModalDeparment = !this.isModalDeparment
            this.menssageActive = false
        },

        setDefaultValueOfCheck() {
            this.shouldCheck.name = true
            this.shouldCheck.brand = true
            this.shouldCheck.previewSrc = true
            this.shouldCheck.language = true
            this.shouldCheck.cif = true
            this.shouldCheck.address = true
            this.shouldCheck.email = true
            this.shouldCheck.tlf = true
            this.shouldCheck.country = true
        },
        eliminateMessage(name) {


            if (this.isErrorMessage) {
                this.menssageActive = false
                this.isErrorMessage = false
            }

            if (!this.menssageActive) return

            if (this.shouldCheck[name]) {
                this.shouldCheck[name] = false
            } else {
                this.shouldCheck[name] = true
                this.menssageActive = false
            }
        },
        setToFalse(name) {

            if (!this[name].error) return

            if (this.shouldWatch[name]) {
                this.shouldWatch[name] = false
            } else {
                this.shouldWatch[name] = true
                this[name].error = false
            }

        },
        getCompanyInfo() {
            axios.get(`/companies/${this.id}`)
                .then((result) => {
                    this.name.value = result.data.fullName;
                    this.brand.value = result.data.brand;
                    this.cif.value = result.data.cif;
                    this.address.value = result.data.fullAddress;
                    this.email.value = result.data.email;
                    this.tlf.value = result.data.tlf;
                    this.country.value = result.data.country;

                    this.language.value = result.data.language;
                }).catch((err) => {
                    this.error = true;
                    this.loading = false;
                    this.menssageActive = true
                    this.messageIcon = false
                    this.messageText = 'Se ha producido un error al intentar obtener los datos de la empresa'
                    this.messageColorDefault = false
                    this.isErrorMessage = true
                    console.log(err);
                });
        },
        getDepartments() {
            axios.get(`/departments?itemsPerPage=${this.limit}&page=${this.pagination.currentPage}&company.id=${this.id}`)
                .then((response) => {
                    this.departments = response.data['hydra:member'];
                    //Se necesita el host para obtener la url completa
                    var host = window.location.protocol + "//" + window.location.host;
                    if (response.data['hydra:view']['hydra:last'] !== undefined) {
                        this.pagination.lastPage = Number((new URL(host + response.data['hydra:view']['hydra:last'])).searchParams.get('page'));
                    }
                    if (response.data['hydra:view']['hydra:next'] !== undefined) {
                        this.pagination.nextPage = Number((new URL(host + response.data['hydra:view']['hydra:next'])).searchParams.get('page'));
                    }
                    if (response.data['hydra:view']['hydra:previous'] !== undefined) {
                        this.pagination.previousPage = Number((new URL(host + response.data['hydra:view']['hydra:previous'])).searchParams.get('page'));
                    }
                    // Si la página actual es mayor que la última página, se establece la última página como página actual
                    if (this.pagination.currentPage > this.pagination.lastPage && this.pagination.lastPage > 0) {
                        this.pagination.currentPage = this.pagination.lastPage;
                    }
                    // En caso de busqueda exacta no devuelve la paginación
                    if (response.data['hydra:view']['hydra:last'] === undefined) {
                        this.pagination.currentPage = 1;
                        this.pagination.lastPage = 1;
                        this.pagination.nextPage = 0;
                    }
                }).catch((err) => {
                    this.error = true;
                    this.loading = false;
                    this.menssageActive = true
                    this.messageIcon = false
                    this.messageText = 'Se ha producido un error al intentar obtener los departamentos'
                    this.messageColorDefault = false
                    this.isErrorMessage = true
                    console.log(err);
                });
        },
        deleteDepartment(id) {
            if (this.editMode) {
                this.toggleDeleteModal()
                this.lastSingularId = id
            } else {
                this.$store.dispatch('deleteDepartment', id);
            }
        },
        openInput() {
            document.querySelector('.input-logo').click();
        },
        changeImage(event) {
            this.previewSrc = event.target.files[0];
        },
        previewImage() {
            return URL.createObjectURL(this.previewSrc);
        },
        triggerInput() {
            document.querySelector('.input-file').click();
        },
        goBack() {
            this.$store.dispatch('setCompany', null);
            this.$store.dispatch('setDepartments', []);
            this.$router.push('/companies');
        },
        getLanguages() {
            axios.get('/languages?itemsPerPage=100')
                .then((result) => {
                    this.languageOptions = result.data['hydra:member'].map((language) => {
                        return {
                            value: language['@id'],
                            label: language.name
                        }
                    });
                }).catch((err) => {
                    this.error = true;
                    this.loading = false;
                    this.menssageActive = true
                    this.messageIcon = false
                    this.messageText = 'Se ha producido un error al intentar obtener los idiomas'
                    this.messageColorDefault = false
                    console.log(err);
                });
        },
        editCompany() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            if (this.checkInputs()) {
                axios.patch(`/companies/${this.id}`, {
                    fullName: this.name.value,
                    brand: this.brand.value,
                    language: this.language.value,
                    cif: this.cif.value,
                    fullAddress: this.address.value,
                    email: this.email.value,
                    tlf: this.tlf.value,
                    country: this.country.value
                })
                    .then(() => {
                        this.menssageActive = true;
                        this.messageText = 'Empresa editada correctamente'
                        this.messageIcon = true
                        this.messageColorDefault = true
                        this.isDisable = false
                    }).catch(() => {
                        this.error = true;
                        this.loading = false;
                        this.menssageActive = true
                        this.messageIcon = false
                        this.messageText = 'Se ha producido un error. Consulte con el servicio técnico.'
                        this.messageColorDefault = false
                        this.errorMessage = this.$t(`${this.$route.name}.errorCompanyCreation`);
                        this.isErrorMessage = true
                    })
                    .finally(() => {
                        this.scrollToTop()
                        this.loading = false
                    })
            } else {
                this.loading = false;
            }

        },
        scrollToTop() {
            const element = this.$refs.companyLayaout;
            element.scrollIntoView({ behavior: "smooth" });
        },
        postCompany() {
            this.isErrorMessage = false

            axios.post('/companies', {
                fullName: this.name.value,
                brand: this.brand.value,
                language: this.language.value,
                owner: `/api/v1/users/${this.$store.getters.getUser.id}`,
                departments: this.$store.getters.getDepartments,
                cif: this.cif.value,
                fullAddress: this.address.value,
                email: this.email.value,
                tlf: this.tlf.value,
                country: this.country.value
            })
                .then(() => {
                    this.$store.dispatch('setCompany', null);

                    this.loading = false;
                    this.successMessage = this.$t(`${this.$route.name}.successCompanyCreation`);

                    this.clearInputs();
                    this.$store.dispatch('setDepartments', []);
                    this.menssageActive = true;
                    this.messageText = 'Empresa creada correctamente'
                    this.messageIcon = true
                    this.messageColorDefault = true
                    this.isDisable = false



                }).catch(() => {

                    this.error = true;
                    this.loading = false;
                    this.menssageActive = true
                    this.messageIcon = false
                    this.messageText = 'Se ha producido un error. Consulte con el servicio técnico.'
                    this.messageColorDefault = false
                    this.errorMessage = this.$t(`${this.$route.name}.errorCompanyCreation`);
                    this.isErrorMessage = true
                })
                .finally(() => {
                    this.advertModal = false
                    this.scrollToTop()

                })

        },
        createCompany() {

            if (this.loading) {
                return;
            }

            if (this.checkInputs()) {
                this.menssageActive = false
                // pone todo listo para que aparezca el mensaje y se quite al pulsar un tecla tanto si es de error como de informacion
                this.setDefaultValueOfCheck()
                this.loading = true;

                if (this.getDepartmentsVuex.length > 0) {
                    //significa que hay departamentos creados
                    this.postCompany()

                } else {
                    //no hay departamentos creados lanzamos un modal
                    this.loading = false;
                    this.toggleAdverModal()
                }

            } else {
                this.loading = false;
            }
        },
        clearInputs() {
            this.name.value = '';
            this.brand.value = '';
            this.language.value = '';
            this.cif.value = '';
            this.address.value = '';
            this.email.value = '';
            this.tlf.value = '';
            this.country.value = '';
            this.previewSrc = null;
            this.$store.dispatch('setDepartments', []);
        },
        checkInputs() {
            if (this.name.value === '') {
                this.name.error = true;
                this.name.errorMessage = this.$t(`${this.$route.name}.formErrors.name.empty`);
            } else {
                this.name.error = false;
                this.name.errorMessage = '';
            }
            if (this.brand.value === '') {
                this.brand.error = true;
                this.brand.errorMessage = this.$t(`${this.$route.name}.formErrors.brand.empty`);
            } else {
                this.brand.error = false;
                this.brand.errorMessage = '';
            }

            //check NIF/CIF
            let nifRegex = /^(0[1-9]|[1-9]\d)\d{6}([-]?)([a-zA-Z]|\d)[-]?$/;
            let cifRegex = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;

            if (this.cif.value === "" || this.cif.value === undefined) {
                this.cif.error = true;
                this.cif.errorMessage = this.$t(`${this.$route.name}.formErrors.cif.empty`);
            } else if (
                nifRegex.test(this.cif.value) === false &&
                cifRegex.test(this.cif.value) === false
            ) {
                this.cif.error = true;
                this.cif.errorMessage = this.$t(`${this.$route.name}.formErrors.cif.regex`);
            } else {
                this.cif.error = false;
                this.cif.errorMessage = "";
            }
            //check address input
            if (this.address.value === "" || this.address.value === undefined) {
                this.address.error = true;
                this.address.errorMessage = this.$t(`${this.$route.name}.formErrors.address.empty`);
            } else {
                this.address.error = false;
                this.address.errorMessage = "";
            }
            //check email input
            let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

            if (this.email.value === "" || this.email.value === undefined) {
                this.email.error = true;
                this.email.errorMessage = this.$t(`${this.$route.name}.formErrors.email.empty`);
            } else if (!emailRegex.test(this.email.value)) {
                this.email.error = true;
                this.email.errorMessage = this.$t(`${this.$route.name}.formErrors.email.regex`);
            } else {
                this.email.error = false;
                this.email.errorMessage = "";
            }
            //check phone input
            if (this.tlf.value === "" || this.tlf.value === undefined) {
                this.tlf.error = true;
                this.tlf.errorMessage = this.$t(`${this.$route.name}.formErrors.tlf.empty`);
            } else {
                this.tlf.error = false;
                this.tlf.errorMessage = "";
            }
            //check country input
            if (this.country.value === "" || this.country.value === undefined) {
                this.country.error = true;
                this.country.errorMessage = this.$t(`${this.$route.name}.formErrors.country.empty`);
            } else {
                this.country.error = false;
                this.country.errorMessage = "";
            }
            //check language input
            if (this.language.value === '') {
                this.language.error = true;
                this.language.errorMessage = this.$t(`${this.$route.name}.formErrors.language.empty`);
            } else {
                this.language.error = false;
                this.language.errorMessage = '';
            }



            return !this.name.error && !this.brand.error && !this.cif.error && !this.address.error && !this.email.error && !this.tlf.error && !this.country.error && !this.language.error;

        },
        handleChangePage(page) {
            this.pagination.currentPage = page;
            this.pagination.previousPage = page - 1;
            this.pagination.nextPage = page + 1;
        },
    },
    watch: {
        name: {
            handler(newVal) {
                this.eliminateMessage('name')
                this.setToFalse('name')
                if (!this.shouldWatch.name || this.shouldCheck.name) return
                if (!this.editMode) {
                    var company = {
                        name: newVal.value,
                        brand: this.brand.value,
                        logo: this.previewSrc,
                        language: this.language.value,
                        cif: this.cif.value,
                        address: this.address.value,
                        email: this.email.value,
                        tlf: this.tlf.value,
                        country: this.country.value

                    }
                    this.$store.dispatch('setCompany', company)
                }
            },
            deep: true
        },
        brand: {
            handler(newVal) {
                this.eliminateMessage('brand')
                this.setToFalse('brand')
                if (!this.shouldWatch.brand || this.shouldCheck.brand) return
                if (!this.editMode) {
                    var company = {
                        name: this.name.value,
                        brand: newVal.value,
                        logo: this.previewSrc,
                        language: this.language.value,
                        cif: this.cif.value,
                        address: this.address.value,
                        email: this.email.value,
                        tlf: this.tlf.value,
                        country: this.country.value

                    }
                    this.$store.dispatch('setCompany', company)
                }
            },
            deep: true
        },
        previewSrc: {
            handler(newVal) {
                this.eliminateMessage('previewSrc')
                this.setToFalse('previewSrc')
                if (!this.shouldWatch.previewSrc || this.shouldCheck.previewSrc) return
                if (!this.editMode) {
                    var company = {
                        name: this.name.value,
                        brand: this.brand.value,
                        language: this.language.valuex,
                        logo: newVal,
                        cif: this.cif.value,
                        address: this.address.value,
                        email: this.email.value,
                        tlf: this.tlf.value,
                        country: this.country.value

                    }
                    this.$store.dispatch('setCompany', company)
                }
            },
            deep: true
        },
        language: {
            handler(newVal) {
                this.eliminateMessage('language')
                this.setToFalse('language')
                if (!this.shouldWatch.language || this.shouldCheck.language) return
                if (!this.editMode) {
                    var company = {
                        name: this.name.value,
                        brand: this.brand.value,
                        logo: this.previewSrc,
                        language: newVal.value,
                        cif: this.cif.value,
                        address: this.address.value,
                        email: this.email.value,
                        tlf: this.tlf.value,
                        country: this.country.value

                    }
                    this.$store.dispatch('setCompany', company)
                }
            },
            deep: true
        },
        cif: {
            handler(newVal) {
                this.eliminateMessage('cif')
                this.setToFalse('cif')
                if (!this.shouldWatch.cif || this.shouldCheck.cif) return
                if (!this.editMode) {
                    var company = {
                        name: this.name.value,
                        brand: this.brand.value,
                        logo: this.previewSrc,
                        cif: newVal.value,
                        address: this.address.value,
                        email: this.email.value,
                        tlf: this.tlf.value,
                        country: this.country.value,
                        language: this.language.value

                    }
                    this.$store.dispatch('setCompany', company)
                }
            },
            deep: true
        },
        address: {
            handler(newVal) {
                this.eliminateMessage('address')
                this.setToFalse('address')
                if (!this.shouldWatch.address || this.shouldCheck.address) return
                if (!this.editMode) {
                    var company = {
                        name: this.name.value,
                        brand: this.brand.value,
                        logo: this.previewSrc,
                        cif: this.cif.value,
                        address: newVal.value,
                        email: this.email.value,
                        tlf: this.tlf.value,
                        country: this.country.value,
                        language: this.language.value

                    }
                    this.$store.dispatch('setCompany', company)
                }
            },
            deep: true
        },
        email: {
            handler(newVal) {
                this.eliminateMessage('email')
                this.setToFalse('email')
                if (!this.shouldWatch.email || this.shouldCheck.email) return
                if (!this.editMode) {
                    var company = {
                        name: this.name.value,
                        brand: this.brand.value,
                        logo: this.previewSrc,
                        email: newVal.value,
                        tlf: this.tlf.value,
                        country: this.country.value,
                        cif: this.cif.value,
                        address: this.address.value,
                        language: this.language.value
                    }
                    this.$store.dispatch('setCompany', company)
                }
            },
            deep: true
        },
        tlf: {
            handler(newVal) {
                this.eliminateMessage('tlf')
                this.setToFalse('tlf')
                if (!this.shouldWatch.tlf || this.shouldCheck.tlf) return
                if (!this.editMode) {
                    var company = {
                        name: this.name.value,
                        brand: this.brand.value,
                        logo: this.previewSrc,
                        email: this.email.value,
                        tlf: newVal.value,
                        country: this.country.value,
                        cif: this.cif.value,
                        address: this.address.value,
                        language: this.language.value
                    }
                    this.$store.dispatch('setCompany', company)
                }
            },
            deep: true
        },
        country: {
            handler(newVal) {
                this.eliminateMessage('country')
                this.setToFalse('country')
                if (!this.shouldWatch.country || this.shouldCheck.country) return
                if (!this.editMode) {
                    var company = {
                        name: this.name.value,
                        brand: this.brand.value,
                        logo: this.previewSrc,
                        country: newVal.value,
                        email: this.email.value,
                        tlf: this.tlf.value,
                        cif: this.cif.value,
                        address: this.address.value,
                        language: this.language.value
                    }
                    this.$store.dispatch('setCompany', company)
                }
            },
            deep: true
        },

    },
    computed: {
        ...mapGetters({
            getDepartmentsVuex: 'getDepartments'
        }),
        messageOnScreen() {
            return this.success || this.error;
        },
        breadcrumb() {
            return this.$t(`${this.$route.name}.breadCrumbs.companies`);
        }
    },

}

</script>

<style scoped lang="scss">
.modal {

    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;
    padding-bottom: 20px;



    .box {
        width: 90%;
        background-color: #FFFFFF;
        padding: 24px;
        border-radius: 8px;
        text-align: left;
        max-width: fit-content;
        display: flex;
        flex-direction: column;
        gap: 32px;

        h2 {
            margin: 0;
        }


        .buttons {
            display: flex;
            justify-content: space-between;

            @media (max-width: 350px) {
                flex-direction: column;
                gap: 16px;
            }
        }

        .cancel-btn {
            color: #163F49;
            font-size: 16px;
            font-family: 'InterMedium';
            border: 1px solid #163F49;
            background-color: transparent;
            border-radius: 4px;
            padding: 7px 14px;
            align-self: stretch;

            @media (max-width: 350px) {
                height: 48px;
            }

        }

        .action-btn2 {
            background-color: #163F49;
            color: #F9FBFC;
            border-radius: 4px;
            border: none;
            padding-inline: 20px;
            display: block;
            width: 100%;
            height: 48px;
            max-width: fit-content;

            @media (max-width: 350px) {
                max-width: 100%;
            }


        }


        @media (max-width: 500px) {
            width: 100%;
        }

    }
}




select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
}

button {
    border: none;
    cursor: pointer;
    font-family: 'InterRegular', sans-serif;
}

.mensaje-div {
    width: 100%;

    &>div {
        margin-bottom: 0;
    }
}

.disable-link {

    &>a {
        pointer-events: none;
    }

    cursor: not-allowed !important;
    opacity: .5;

}



.template {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 24px;

    .active {
        width: 100%;
        margin: 20px 0;
        display: flex;
    }

    .title__main {
        width: 100%;
        font-size: 30px;
        text-align: left;
        color: #163F49;
        font-family: 'InterSemiBold', sans-serif;
    }

    .company-departments {
        width: 100%;
        display: flex;
        gap: 36px;
        margin: 0 0 0 0;

        .departments-container {
            display: flex;
            flex-direction: column;
            width: 450px;
            gap: 45px 0;

            .title-button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                gap: 20px;

                h2 {
                    display: inline-block;
                    height: 19px;
                    font-size: 25px;
                    font-weight: 600;
                    line-height: 24.2px;
                    text-align: left;
                    color: #163F49;
                    font-family: 'InterRegular', sans-serif;
                    margin: 0;
                }

                .departments-button {
                    margin: 0;
                    max-width: 212px;
                    color: #F9FBFC;

                }


            }

            .active {
                display: none;
            }

            .departments {
                margin-top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                height: 100%;
                gap: 24px;

                .departments-list {
                    width: 100%;
                    max-height: 436px;
                    min-height: 120px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    overflow-y: auto;
                    gap: 20px;
                    padding: 0 0 30px;
                }
            }

            .filters {
                margin: 24px 0 0 0;
            }

            .save-company-departments {
                width: 100%;
                height: 48px;
                border-radius: 4px;
                margin-top: 48px;
                background-color: #163F49;
                color: #F9FBFC;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 24px;
                display: none;
            }

            .alert {
                display: none;
            }
        }

        .new-company-form {
            flex-grow: 1;
            display: flex;
            min-width: 500px;
            flex-direction: column;
            background: #FFFFFF;
            height: fit-content;
            padding: 24px;
            gap: 24px;
            border-radius: 10px;

            .form__inputs {
                display: flex;
                gap: 24px;
                align-items: flex-start;
                width: 100%;

                .inputs__logo {
                    width: 145px;
                    height: 145px;
                    min-width: 145px;
                    border-radius: 8px;
                    background: #F9FBFC;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    border: 1.25px dashed #B3BBBC;
                    cursor: pointer;

                    .input-logo {
                        display: none;
                    }

                    .company-logo {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        // display: none;
                        cursor: pointer;
                    }

                    .logo__placeholder {
                        color: #707C7E;
                        font-family: 'InterSemiBold', sans-serif;
                    }
                }

                .inputs__body {
                    flex-grow: 1;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 24px;

                    .input-text {
                        width: calc(50% - 12px);
                    }

                    .body-select {
                        width: calc(50% - 12px);

                        :deep(.custom-select) {
                            select {
                                height: 48px;
                            }
                        }
                    }
                }
            }

            .form__button {
                display: flex;
                width: 100%;
                justify-content: flex-end;

                .save-company {
                    width: 305px;
                    height: 48px;
                    border-radius: 4px;
                    background-color: #163F49;
                    color: #F9FBFC;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }
    }
}



.departments-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 32px;
    border-radius: 4px;
    background-color: #163F49;
    color: #F9FBFC;
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
    border: none;
}

@media (max-width:1550px) {
    .template {
        .company-departments {
            .new-company-form {
                .form__inputs {
                    flex-direction: column;
                }
            }
        }
    }
}



@media (max-width:1024px) {
    .template {
        .active {

            display: none;
        }

        .company-departments {
            flex-direction: column;
            gap: 24px;
            margin: 0;

            .departments-container {
                width: 100%;
                padding: 0 24px;
                gap: 32px;

                .title-button {
                    flex-direction: column;
                    align-items: flex-start;

                    h2 {
                        margin-top: 0;
                        font-size: 20px
                    }

                    .departments-button {
                        width: 100%;
                        max-width: none;
                        font-size: 14px;
                        margin-bottom: 24px;
                    }
                }

                .active {
                    margin: 32px 0;
                    display: flex;
                }

                .departments {
                    width: 100%;
                    margin: 0;
                    // padding: 80px 0 0 0;
                }

                .save-company-departments {
                    display: block;
                    font-size: 16px;
                    margin: 12px 0;
                }

                .alert {
                    display: flex;
                }
            }

            .departments-container.message-on-screen {
                gap: 0;
            }

            .new-company-form {
                min-width: 0;
                height: auto;
                padding: 48px 24px;

                .form__inputs {
                    flex-direction: column;
                    align-items: flex-start;

                    .inputs__body {
                        width: 100%;
                        flex-direction: column;

                        .input-code {
                            width: 100%;
                        }

                        .general-input {
                            width: 100%;
                        }

                        .select-language {
                            width: 85%;
                        }

                        .custom-select__container {
                            width: 100%;
                        }
                    }
                }

                .form__button {
                    display: none;
                }

                .alert {
                    display: none;
                }
            }
        }
    }

    .template.message-on-screen {
        gap: 24px;
    }
}
</style>