<template>
    <div :style="styleContainer" class="general-input" >
        <label :for="labelFor">{{ labelName }}</label>
        <div class="input-wrapper" :class="{'error':this.error}">
            <input ref="generalInput" :class="this.disabled && 'disabled'" :type="currentInputType" :id="labelFor" v-bind="$attrs" :autofocus="autofocus" v-model.trim="this.inputValue" :autocomplete="this.autocomplete" :required="this.required" :disabled="this.disabled">
            <button v-if="isPasswordField" type="button" @click="togglePasswordVisibility" class="eye-password-button">
                <img :src="passwordVisible ? hideIcon : showIcon" alt="Password Icon">
            </button>
        </div>
        <span class="error-message" v-if="this.error">
            {{ this.errorMessage }}
        </span>
    </div>
</template>
  
<script>
export default {
    name: 'GeneralInput',
    props: {
        labelName: String,
        labelFor: String,
        inputType: {
            type: String,
            default: 'text'
        },
        error:{
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: ''
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        vModel:{
            type: String,
            default: ''
        },
        isPasswordField: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        styleContainer: {
            type: Object
        },
        isFocus: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            passwordVisible: false,
            inputValue: ''
        };
    },
    emits: ['update-value'],
    created() {
        this.inputValue = this.vModel;
        
    },
    mounted() {
        if(this.isFocus){
            this.$refs.generalInput.focus()
        }
    },
    computed: {
        currentInputType() {
            if (this.isPasswordField) {
                return this.passwordVisible ? 'text' : 'password';
            }
            return this.inputType;
        },

        showIcon() {
            return require('@/assets/icons/eye.svg');
        },
        hideIcon() {
            return require('@/assets/icons/hide-icon.svg');
        },
    },
    methods: {
        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible;
        },
    },
    watch: {
        inputValue(newValue) {
            this.$emit('update-value', newValue);
        },
        vModel(newValue) {
            this.inputValue = newValue;
        }
    }
}
</script>
  
<style scoped lang="scss">


.disabled{
   opacity: .5;
}

.general-input {
    width: 100%;
    // margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    label {
        font-size: 14px;
        color: #707C7E;
        font-family: 'interMedium', sans-serif;
        font-weight: 500;
        line-height: normal;
        display: inline-block;
        margin-bottom: 8px;
        width: fit-content;
        text-align: left;
    }

    input {
        border-radius: 8px;
        width: 100%;
        height: 54px;
        border: 1px solid #CBDEE2;
        outline: none;
        font-size: 16px;
        color: #0E0E0F;
        font-family: 'interRegular', sans-serif;
        font-weight: 400;
        padding: 0 24px;


    }
    .error-message{
        color: #C74D4D;
        font-size: 12px;
        margin-top: 8px;
        font-family: 'InterRegular', sans-serif;
        text-align: left;
    }
    .eye-password-button {
        position: absolute;
        right: 25px;
    }

    input:focus {
        border: 1px solid #B3BBBC;
    }
}

.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}
.input-wrapper.error input {
    border: 1px solid #C74D4D;
}

.input-wrapper input {
    flex-grow: 1;
}

.input-wrapper button {
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

@media (max-width: 500px) {
    .general-input {
        label {
            font-size: 12px;
        }

        input {
            font-size: 14px;
            height: 42px;
        }
        .error-message{
            font-size: 10px;
            line-height: 9px;
        }
    }
}
</style>
  