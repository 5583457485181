<template>
    <div ref="outsideTable" class="relative">
        <div class="table">
            <p v-if="isElementCreated === ''">Cargando...</p>
            <InfoMessage v-else-if="isElementCreated === 'no'" :text="tableInfoMessage.notCreate" :isActive="true"
                icon="info" />
            <InfoMessage v-else-if="isElementCreated === 'error'"
                text="Ha habido un error consulte con el servicio técnico" :isActive="true" icon="error"
                color="#C74D4D" />
            <InfoMessage v-else-if="isElementCreated === 'notFound' && tableInfoMessage.notSearch"
                :text="tableInfoMessage.notSearch" :isActive="true" icon="info" />
            <div v-show="isElementCreated === 'yes'" class="table-container" ref="tableScroll">
                <table>
                    <thead>
                        <tr>
                            <th v-if="tableColumns.stateBold"></th>
                            <th class="all" v-if="tableColumns.chekbox"><button v-if="!tableColumns?.noneAll"
                                    @click="selectAll"></button></th>
                            <th v-if="tableColumns.arrow" class="arrow-td"></th>
                            <th class="column-name" v-for="(column, index) in tableColumns.columnTitle"
                                :key="'columnName-' + index">
                                <span>{{ column.title }}
                                    <button :class="{
                                        'btn-order': true,
                                        'is-up': column.isUp,
                                        'is-down': !column.isUp
                                    }" @click="orderChange(column.title)">
                                        <svg width="13" height="11" viewBox="0 0 13 11" fill=""
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_376_1705)">
                                                <path
                                                    d="M6.39336 7.39421L4.16167 9.40315V0.468284C4.16167 0.210728 3.92758 0 3.64146 0C3.35535 0 3.12125 0.210728 3.12125 0.468284V9.40315L0.88956 7.39421C0.686679 7.21158 0.358948 7.21158 0.156067 7.39421C-0.046814 7.57684 -0.046814 7.87186 0.156067 8.05449L3.27732 10.8642C3.32413 10.9063 3.38136 10.9438 3.44898 10.9672C3.51141 10.9906 3.57904 11.0047 3.64666 11.0047C3.71429 11.0047 3.78192 10.9906 3.84434 10.9672C3.90677 10.9438 3.96399 10.911 4.01601 10.8642L7.13726 8.05449C7.34014 7.87186 7.34014 7.57684 7.13726 7.39421C6.93438 7.21158 6.60665 7.21158 6.39856 7.39421H6.39336Z" />
                                                <path
                                                    d="M9.88492 4.91211H7.28388C6.99777 4.91211 6.76367 5.12284 6.76367 5.38039C6.76367 5.63795 6.99777 5.84868 7.28388 5.84868H9.88492C10.171 5.84868 10.4051 5.63795 10.4051 5.38039C10.4051 5.12284 10.171 4.91211 9.88492 4.91211Z" />
                                                <path
                                                    d="M11.4455 2.57568H7.28388C6.99777 2.57568 6.76367 2.78641 6.76367 3.04397C6.76367 3.30152 6.99777 3.51225 7.28388 3.51225H11.4455C11.7317 3.51225 11.9658 3.30152 11.9658 3.04397C11.9658 2.78641 11.7317 2.57568 11.4455 2.57568Z" />
                                                <path
                                                    d="M12.4808 0.234375H7.28388C6.99777 0.234375 6.76367 0.445103 6.76367 0.702659C6.76367 0.960216 6.99777 1.17094 7.28388 1.17094H12.4808C12.7669 1.17094 13.001 0.960216 13.001 0.702659C13.001 0.445103 12.7669 0.234375 12.4808 0.234375Z" />
                                            </g>
                                        </svg>
                                    </button>
                                </span>
                            </th>
                            <th v-if="tableColumns.options"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(element, index) in elementsWithSelect" :key="element.id">
                            <tr :class="[
                                { 'tr-element': tableColumns.detailsRowName.length > 0 },
                                { 'tr-element-blue': element.isSelect }

                            ]" :id="element.id" @click="(e) => toggleVisibility(index, element.id, e)">
                                <td v-if="tableColumns.stateBold"><span
                                        :style="{ backgroundColor: element?.state?.color }" class="bold"></span></td>
                                <td v-if="tableColumns.chekbox" class="checkbox-img">
                                    <Checkbox class="checkbox" @click.stop="elementSelect(element.id)" ref="check" />
                                </td>

                                <td v-if="tableColumns.arrow" class="td-flecha"></td>
                                <td class="text-td" :class="[{ 'cursor': tableColumns.detailsRowName.length > 0 },]"
                                    v-for="(row, index) in mainRow[index]" :key="element.id + index">
                                    <span class="grid">
                                        <span
                                            v-if="tableColumns.mainRowName[index] === 'name' && !element.verified && pendingRegistration"
                                            class="pendiente-registro">Pendiente de registro</span>
                                        <span v-else :title="row">{{ row }}</span>
                                    </span>
                                </td>
                                <td v-if="tableColumns.options" class="ellipses-td">
                                    <span @click="(e) => calculateDistance(e)">
                                        <TdDropdown :ref="'TdDropdown' + element.id" :isImpersonal="isImpersonal"
                                            :identifier="element.id" :toggleModalSimple="toggleModalSimple"
                                            :editPath="editPath" :DropdownPositionY="DropdownPositionY"
                                            :DropdownPositionX="DropdownPositionX"
                                            @activeDropdownOpen="activeDropdownOpen" />
                                    </span>
                                </td>
                            </tr>

                            <template v-if="element.isSelect">
                                <tr class="tr-details" v-for="(details, idx) in detailsRow[index]"
                                    :key="'details' + index + idx"
                                    :class="{ 'desk': true, 'tr-border-last': idx === detailsRow[index].length - 1, 'tr-first': idx === 0 }">
                                    <td></td>
                                    <td class="description-title-td ">{{ tableColumns.titleDetailsRowName[idx] }}</td>
                                    <td class="description">
                                        {{ details === '' ? 'No se ha añadido una descripción' : details }}</td>
                                    <td></td>
                                </tr>
                            </template>

                            <template v-if="element.isSelect">
                                <template v-for="(details, idx) in detailsRow[index]" :key="'details' + index + idx">
                                    <tr :class="{ 'mobile': true, 'mobile-tr': true, 'tr-first': idx === 0 }">
                                        <td v-if="tableColumns.stateBold"></td>
                                        <td v-if="tableColumns.options"></td>
                                        <td v-if="tableColumns.arrow"></td>
                                        <td :colspan="tableColumns.mobileColSpan" class="text-th description-title-td">
                                            {{
                                                tableColumns.titleDetailsRowName[idx] }}
                                        </td>
                                    </tr>
                                    <tr
                                        :class="{ 'mobile': true, 'mobile-tr': true, 'tr-border-last': idx === detailsRow[index].length - 1 }">
                                        <td v-if="tableColumns.stateBold"></td>
                                        <td v-if="tableColumns.options"></td>
                                        <td v-if="tableColumns.arrow"></td>
                                        <td :colspan="tableColumns.mobileColSpan" class="description">{{ details === ''
                                            ? 'No se ha añadido una descripción' :
                                            details }}</td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Checkbox from '../formulario_denuncia/Checkbox.vue';
import TdDropdown from './TdDropdown.vue';
import InfoMessage from '../general/InfoMessage.vue';

function reduceFromArray(valor, arrayToReduce) {
    const arrayKeysToKeep = valor;
    const newArray = arrayToReduce.map(obj =>
        arrayKeysToKeep.reduce((acc, key) => {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                acc[key] = obj[key];
            }
            return acc;
        }, {})
    );

    return newArray;
}

export default {
    name: 'TableComponent',
    props: {
        isImpersonal: {
            type: Boolean
        },
        elementArray: {
            type: Array,
            required: true
        },
        tableColumns: {
            type: Object,
            require: true
        },
        isElementCreated: {
            type: String,
            required: true
        },
        toggleModalSimple: {
            type: Function,
            required: true
        },
        editPath: {
            type: String,
            required: true
        },
        tableInfoMessage: {
            type: Object,
            required: true
        },
        pendingRegistration: {
            type: Boolean,
            default: false
        }
    },
    data() {
        const elementsWithSelect = this.elementArray.map(element => ({ ...element, isSelected: false }))

        return {
            elementsWithSelect: elementsWithSelect,
            isUp: false,
            active: false,
            DropdownPositionX: 0,
            DropdownPositionY: 0,
            isDropdownOpen: false,

        };

    },
    mounted() {
        this.$refs.tableScroll.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        this.$refs.tableScroll.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
        mainRow() {
            const newArray = reduceFromArray(this.tableColumns.mainRowName, this.elementArray)
            const arrayOfObjectValues = newArray.map(obj => Object.values(obj))
            const lastCheck = arrayOfObjectValues.map(valueArray => valueArray.map(value => {
                if (typeof value === 'object') {
                    const { name } = value
                    return name
                } else {
                    return value
                }
            }))
            return lastCheck
        },
        detailsRow() {
            const newArray = reduceFromArray(this.tableColumns.detailsRowName, this.elementArray)
            const arrayOfObjectValues = newArray.map((obj) => [Object.values(obj)]).flat()
            const lastCheck = arrayOfObjectValues.map(valueArray => valueArray.map(value => {
                if (typeof value === 'object') {
                    const { name } = value
                    return name
                } else {
                    return value
                }
            }))
            return lastCheck

        },


    },
    components: {
        Checkbox,
        TdDropdown,
        InfoMessage
    },
    watch: {
        elementArray() {
            this.elementsWithSelect = this.elementArray.map(element => ({ ...element, isSelected: false }))
        }
    },
    methods: {
        toggleVisibility(index, id, e) {
            if (this.tableColumns.detailsRowName.length === 0) return
            if (e.target.closest(".ellipses-td")) return
            this.elementsWithSelect = this.elementsWithSelect.map(element => {
                return element.id === id ? { ...element, isSelect: !element.isSelect } : element
            })

        },
        elementSelect(id) {
            this.$emit('elementSelected', id);
        },
        selectAll() {
            if (!this.active) {
                if (Array.isArray(this.$refs.check)) {
                    this.$refs.check.forEach((checkbox) => {
                        checkbox.setCheckboxTrue();
                    })
                }
                let all = []

                this.elementsWithSelect.map(element => {
                    const id = element.id
                    all.push(id)
                })

                this.$emit('addAllElements', all)
                this.active = !this.active
            }


            else {
                this.checkBoxToFalse()
                this.active = !this.active
            }
        },
        checkBoxToFalse() {
            if (Array.isArray(this.$refs.check)) {
                this.$refs.check.forEach((checkbox) => {
                    checkbox.setCheckboxFalse();
                })
            }
            let all = []
            this.$emit('addAllElements', all)
        }
        ,
        orderChange(title) {
            this.$emit('changeorder', title)
        },
        calculateDistance(e) {

            if (e.target.closest('.dropdown-menu') !== null) return

            //cogemos la posicion x e y del padre  y del boton clicado
            const container = this.$refs.outsideTable;
            const dropdown = e.target

            const containerRect = container.getBoundingClientRect();
            const dropdownRect = dropdown.getBoundingClientRect();

            // buscamos la diferencia de ambos valores para saber cuanto top y left darle al elemento position absolute
            this.DropdownPositionX = dropdownRect.left - containerRect.left
            this.DropdownPositionY = dropdownRect.top - containerRect.top

            this.DropdownPositionX -= 80
            this.DropdownPositionY += 20

            // si es movil, necesitamos quitarle px en left porque se sale de la pantalla al ser una sola columna
            /* if (window.innerWidth <= 768) {
                 this.noteDropdownPositionX -= 50
             }
                 */

        },

        handleScroll() {
            //ocultar los dropdwon  cuando se haga scroll ya que debido al overflow

            //Para que solo se ejecute quitar los dropdown cuando este abierto uno y se haga scroll 
            if (!this.isDropdownOpen) return
            this.elementsWithSelect.forEach((element) => {

                const refName = 'TdDropdown' + element.id
                this.$refs[refName][0].closeDropdown()
            });
            this.isDropdownOpen = false
        },

        activeDropdownOpen() {
            this.isDropdownOpen = true
        },

    },


}
</script>

<style scoped lang="scss">
.bold {

    width: 8px;
    height: 8px;
    border-radius: 100%;
    display: block;
}

.pendiente-registro {
    color: #E7885F;
    border: 1px solid var(--Naranja-Dedavara, #E7885F);
    border-radius: 17px;
    width: 195px;
    text-align: center;
    padding: 4px 8px;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;

    @media (max-width: 1000px) {
        width: 145px;
        font-size: 12px;
        font-weight: 500;
    }

}

.mobile {
    display: none;
}

.is-up {
    svg {
        transform: rotate(-180deg);
        transition: all .5s linear;
    }
}

.is-down {
    svg {
        transform: rotate(0deg);
        transition: all .5s linear;
    }
}

.all {
    padding: 0;

    button {
        cursor: pointer;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        background-color: transparent;
        border: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
        transition: background-color, .4s linear;

        &:hover {
            background-color: #163F49;
        }
    }
}

.relative {
    position: relative;
}

.table {
    width: 100%;
    overflow: auto;
}

.table-container {
    max-height: 560px;
    overflow: auto;
    padding: 0 20px 0 0;
}

.rotated,
.rotate-inverse {
    transition: transform 0.5s ease;
}

.rotated {
    transform: rotate(90deg);
}

.rotate-inverse {
    transform: rotate(0deg);
}



table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;

    tr {
        width: 100%;
        border-bottom: 1px solid #E7F0F2;
    }




    .column-name span {
        display: flex;
        align-items: center;
        gap: 15px;


    }

    .btn-order {
        cursor: pointer;
        padding: 0;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        transition: background-color, .4s linear;

        svg {
            fill: #B3BBBC;
        }

        img {
            padding: 5px 3px 4px 4px;

        }

        &:hover {
            background-color: #163F49;

            svg {
                fill: white;
            }
        }


    }

    th {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;

        padding: 8px;
        /* Asegura que el contenido no se superponga con el borde */
        box-shadow: inset 0 -1px 0 0 #B3BBBC;
    }

    .cursor,
    .td-flecha {
        cursor: pointer;
    }

    .text-td,
    .ellipses-td,
    .td-flecha {
        transition: background-color, .2s linear;
    }





    .tr-element-blue {
        .text-td {
            background-color: #CBDEE2;
        }

        .ellipses-td {
            background-color: #CBDEE2;
        }

        .td-flecha {
            background-color: #CBDEE2;
        }
    }

    .tr-element:hover {
        .text-td {
            background-color: #CBDEE2;
        }

        .ellipses-td {
            background-color: #CBDEE2;
        }

        .td-flecha {
            background-color: #CBDEE2;
        }
    }

    .text-td {
        font-size: 16px;
        text-align: left;
        color: #0E0E0F;
        font-family: "InterRegular", sans-serif;

        .grid{
            display: grid;

            span {
            overflow: auto;
            max-width: 100%;
        }

        }



    }

    .description {
        font-family: 'Inter', sans-serif;
        color: #0E0E0F;
        font-size: 14px;
        line-height: 16.94px;
        font-weight: 400;
    }

    th,
    td {
        text-align: left;
        font-size: 12px;
        overflow: auto;
    }

    th,
    .text-th {
        padding: 12px 8px;
        font-family: 'InterSemiBold', sans-serif;
        color: #B3BBBC;
        text-wrap: nowrap;

    }

    .description-title-td {
        font-family: 'Inter', sans-serif;
        color: #B3BBBC;
        font-weight: 600;
        font-size: 12px;
        line-height: 14.52px;
        padding-right: 49px;
    }

    td {
        padding: 14px 8px;
        font-family: 'Inter', sans-serif;
        color: #0E0E0F;
        // position: relative;

        .edit-delete {
            position: absolute;
            top: calc(50% - 10px);
            right: calc(50% - 10px);
        }
    }

    .checkbox {
        width: 16px;
        height: 16px;
    }

    .desk,
    .mobile {
        border: none;

        td {
            padding-bottom: 6px;
        }
    }

    .tr-first {
        td {
            padding-top: 24px;
        }
    }

    .tr-border-last {
        border-bottom: 1px solid #E7F0F2;

        td {
            padding-bottom: 24px !important;
        }

    }


    .ellipses-td {
        width: 60px;

    }

    .checkbox-img {
        text-wrap: nowrap;
        width: 1%;
        padding-right: 22px;
        padding-left: 0;

    }

    .td-flecha {
        width: 1%;
        padding-right: 22px;
        padding-left: 0;
    }

    .flecha {
        cursor: pointer;
    }

    .idioma-th,
    .idioma-td {
        display: none;
    }

    .idioma-desplegable {
        border: none;
    }

    .ellipsis {
        cursor: pointer;
    }


}



@media (min-width: 1200px) {
    .idioma-desplegable {
        display: none;
    }

    .table {

        .idioma-th,
        .idioma-td {
            display: block;
        }

        .text-td {
            font-size: 16px;
            line-height: 19.36px;
            font-family: "InterRegular", sans-serif;
        }
    }

}

@media (min-width: 769px) {
    .description-title-td {
        width: 1%;
    }
}

@media (max-width: 768px) {

    table {
        th {
            img {
                margin-left: 6px;
            }
        }

        .description-title-td {
            padding-right: 12px;
            padding-left: 8.95px;
        }


        .column-name {
            padding: 0;

            span {
                gap: 0px;
            }
        }

    }

    .desk {
        display: none;
    }

    .mobile {
        display: table-row;
    }


    .mobile-tr {
        td {
            font-size: 12px;
            padding-block: 12px;
        }

        .description {
            padding-top: 6px;
            padding-left: 8.95px;
            padding-bottom: 6px;
        }
    }

    .checkbox {
        margin: 0 !important;
    }

}


@media (max-width: 400px) {

    table {
        .text-td {
            max-width: 50px;
        }
    }

    .table {
        padding: 0 !important;
    }



    .mobile-tr>.text-td {
        padding-right: 0;
    }

    table {
        .description {
            padding-right: 0;
        }
    }

    .checkbox-img {
        padding-right: 8px !important;

    }

    .td-flecha {
        padding-right: 8px !important;
    }

    .ellipses-td {
        width: 1% !important;
        padding-inline: 4px !important;
    }

    .table-container {
        max-height: 560px;
        overflow: auto;
        padding-right: 10px;
    }




}
</style>
