<template>
    
    <TableMenuLayaout :tableOptions="tableOptions" :descriptionName="descriptionName" :dbUrl="dbUrl"
        :breadcrumbs="breadcrumbs" :tableInfoMessage="tableInfoMessage" :deleteModalText="deleteModalText" />
        
</template>

<script>
import TableMenuLayaout from "@/components/general/TableMenuLayaout.vue"

export default {
    name: 'CategoriesComponent',
    components: { TableMenuLayaout},
    data() {
        return {
            tableOptions: {
                chekbox: true,
                arrow: true,
                columnTitle: [{ title: 'Categorías', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha',  isUp: false  }],
                options: true,
                //Tiene que hacer match con los datos
                mainRowName: ['name'],
                titleDetailsRowName: ['Descripción'],
                //Tiene que hacer match con los datos
                detailsRowName: ['description']
            },
            descriptionName: {
                title: 'Categorías',
                buttonAddName: 'Nueva'
            },
            dbUrl: {
                path: "/categories",
                pathToCreate: "categorias/crear-categoria",
                pathToEdit: "categorias/editar-categoria"
            },
            breadcrumbs: [{ label: 'Categorías', to: '/categorias' }],
            tableInfoMessage: {
                notCreate: 'No hay categorías creadas',
                notSearch: 'No se encontraron categorías'
            },
            deleteModalText: {
                title: '¿Desea eliminar las categorías seleccionadas?',
                namePlural: 'categoría',
                textSingular: 'Se eliminará la categoría seleccionada',
                errorSingular: 'No se puede eliminar la categoría seleccionada porque se está usando en expedientes',
                errorPlural: 'No se han podido eliminar algunas de las categorías seleccionadas porque están usandose en expedientes'
            }
        }
    },
    
}

</script>