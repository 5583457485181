
<template>
    <input :style="checkStyle" type="checkbox" :checked="isChecked" name="check-complaint"  @change="handleChange" :id="labelIdenty">
</template>


<script>
export default {
    name: 'CheckboxComponent',
    props: {
      modelValue: Boolean,
      labelIdenty: String,
      checkStyle: Object
    },
    watch: {
      modelValue(newValue){
        this.isChecked = newValue
      }
    },
    data(){
      return{
        isChecked: this.modelValue
      }
    },
  methods: {
    handleChange(event) {
      this.$emit('update:modelValue', event.target.checked);
    },
    setCheckboxTrue(){
        this.isChecked = true
    },
    setCheckboxFalse(){
        this.isChecked = false
    }
  },
 

}
</script>
 
<style scoped lang="scss">
    input[type="checkbox"] {
        min-width: 16px;
        min-height: 12px;
        border-radius: 4px;
        border: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
        appearance: none;
        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        background-color: #163F49;
    }

@media (max-width: 450px) {

}

</style>