<template>

    <LayaoutTableDinamic :tableInfoMessage="tableInfoMessage" title="Logs" :breadcrumbs="this.breadcrumbsList"
        path="/logs" :tableColumnsData="tableColumnsData"  :notCreateButton="true"
        :checkBoxAvailable="false" :optionMenuAvailable="false" :date="true" :changeRoleName="true"
        :changeDate="true" editPath="" />

</template>


<script>

import LayaoutTableDinamic from '../users/LayaoutTableDinamic.vue';

export default {
    name: 'LogsComponent',
    components: {
        LayaoutTableDinamic
    },
    data() {
        return {
            breadcrumbsList: [
                { label: 'Logs', to: '/logs' },
            ],
            tableInfoMessage: {
                notCreate: 'No hay Logs creados',
                notSearch: 'No se encontraron logs'
            },
            tableColumnsData: {
                desk: {
                    columnTitle: [
                        { title: 'Fecha y hora', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false },
                        { title: 'Acción', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false },
                        { title: 'Usuario', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false },
                        { title: 'Rol', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['createdAt', 'action', 'userName', 'userRole'],
                    titleDetailsRowName: [],
                    //Tiene que hacer match con los datos
                    detailsRowName: [],
                },

                mobile: {
                    columnTitle: [{ title: 'Fecha y hora', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }, { title: 'Acción', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['createdAt', 'action',],
                    titleDetailsRowName: ['Usuario', 'Rol'],
                    //Tiene que hacer match con los datos
                    detailsRowName: ['userName', 'userRole'],
                }

            }
        }
    },

}
</script>
