<template>
  <div class="file-explorer" ref="fileExplorer">
    <!-- Topbar -->
    <div class="title-grid">
      <h2>{{ title }}</h2>
      <div class="message">

        <p>
          <img src="@/assets/icons/info.svg" alt="info" />
          Deberá mantener la confidencialidad de todos los documentos
          asociados al expediente
        </p>
      </div>

      <div class="contacted-space">
        <p><span>Espacio contratado:</span> 181,3/500 MB</p>
      </div>


      <ProgressBar v-if="showProgress" :progress="progress" />

      <!-- Opciones de selección y visualización -->
      <div class="options">
        <div>
          <checkbox margin="8px" width="16px" id="select-all-files" checkboxText="Seleccionar todo"
            @update-value="selectAllFiles" :checked="isAllSelected" />

          <!--Ruta del directorio actual-->
          <div class="folder-path">
            <p>
              <span v-for="(folder, index) in currentFolder
                .split('/')
                .filter(Boolean)" :key="index" :class="{
                  'current-folder':
                    index === currentFolder.split('/').filter(Boolean).length - 1,
                }">
                {{ folder }}
                <span v-if="
                  index !== currentFolder.split('/').filter(Boolean).length - 1
                ">
                  /
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="list-buttons">
        <button :class="listOption && 'button-selected'" @click="changeToList">
          <svg viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_393_781)">
              <path
                d="M0.842105 4.8002C1.30719 4.8002 1.68421 4.44202 1.68421 4.0002C1.68421 3.55837 1.30719 3.2002 0.842105 3.2002C0.377023 3.2002 0 3.55837 0 4.0002C0 4.44202 0.377023 4.8002 0.842105 4.8002Z" />
              <path
                d="M0.842105 7.9999C1.30719 7.9999 1.68421 7.64173 1.68421 7.1999C1.68421 6.75807 1.30719 6.3999 0.842105 6.3999C0.377023 6.3999 0 6.75807 0 7.1999C0 7.64173 0.377023 7.9999 0.842105 7.9999Z" />
              <path
                d="M4.20984 1.33327H15.4379C15.7523 1.33327 15.9993 1.0986 15.9993 0.799935C15.9993 0.501268 15.7523 0.266602 15.4379 0.266602H4.20984C3.89546 0.266602 3.64844 0.501268 3.64844 0.799935C3.64844 1.0986 3.89546 1.33327 4.20984 1.33327Z" />
              <path
                d="M15.4379 3.4668H4.20984C3.89546 3.4668 3.64844 3.70146 3.64844 4.00013C3.64844 4.2988 3.89546 4.53346 4.20984 4.53346H15.4379C15.7523 4.53346 15.9993 4.2988 15.9993 4.00013C15.9993 3.70146 15.7523 3.4668 15.4379 3.4668Z" />
              <path
                d="M0.842105 1.6C1.30719 1.6 1.68421 1.24183 1.68421 0.8C1.68421 0.358172 1.30719 0 0.842105 0C0.377023 0 0 0.358172 0 0.8C0 1.24183 0.377023 1.6 0.842105 1.6Z" />
              <path
                d="M15.4379 6.6665H4.20984C3.89546 6.6665 3.64844 6.90117 3.64844 7.19984C3.64844 7.4985 3.89546 7.73317 4.20984 7.73317H15.4379C15.7523 7.73317 15.9993 7.4985 15.9993 7.19984C15.9993 6.90117 15.7523 6.6665 15.4379 6.6665Z" />
            </g>
            <defs>
              <clipPath id="clip0_393_781">
                <rect width="16" height="8" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button :class="!listOption && 'button-selected'" @click="changeToSquare">
          <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_393_776)">
              <path
                d="M4.69499 0H1.34142C0.601405 0 0 0.601405 0 1.34142V4.69499C0 5.435 0.601405 6.03641 1.34142 6.03641H4.69499C5.435 6.03641 6.03641 5.435 6.03641 4.69499V1.34142C6.03641 0.601405 5.435 0 4.69499 0ZM5.14213 4.69499C5.14213 4.94091 4.94091 5.14213 4.69499 5.14213H1.34142C1.0955 5.14213 0.894283 4.94091 0.894283 4.69499V1.34142C0.894283 1.0955 1.0955 0.894283 1.34142 0.894283H4.69499C4.94091 0.894283 5.14213 1.0955 5.14213 1.34142V4.69499Z" />
              <path
                d="M12.6579 0H9.30432C8.5643 0 7.96289 0.601405 7.96289 1.34142V4.69499C7.96289 5.435 8.5643 6.03641 9.30432 6.03641H12.6579C13.3979 6.03641 13.9993 5.435 13.9993 4.69499V1.34142C13.9993 0.601405 13.3979 0 12.6579 0ZM13.105 4.69499C13.105 4.94091 12.9038 5.14213 12.6579 5.14213H9.30432C9.05839 5.14213 8.85717 4.94091 8.85717 4.69499V1.34142C8.85717 1.0955 9.05839 0.894283 9.30432 0.894283H12.6579C12.9038 0.894283 13.105 1.0955 13.105 1.34142V4.69499Z" />
              <path
                d="M4.69499 7.96338H1.34142C0.601405 7.96338 0 8.56478 0 9.3048V12.6584C0 13.3984 0.601405 13.9998 1.34142 13.9998H4.69499C5.435 13.9998 6.03641 13.3984 6.03641 12.6584V9.3048C6.03641 8.56478 5.435 7.96338 4.69499 7.96338ZM5.14213 12.6584C5.14213 12.9043 4.94091 13.1055 4.69499 13.1055H1.34142C1.0955 13.1055 0.894283 12.9043 0.894283 12.6584V9.3048C0.894283 9.05888 1.0955 8.85766 1.34142 8.85766H4.69499C4.94091 8.85766 5.14213 9.05888 5.14213 9.3048V12.6584Z" />
              <path
                d="M12.6579 7.96338H9.30432C8.5643 7.96338 7.96289 8.56478 7.96289 9.3048V12.6584C7.96289 13.3984 8.5643 13.9998 9.30432 13.9998H12.6579C13.3979 13.9998 13.9993 13.3984 13.9993 12.6584V9.3048C13.9993 8.56478 13.3979 7.96338 12.6579 7.96338ZM13.105 12.6584C13.105 12.9043 12.9038 13.1055 12.6579 13.1055H9.30432C9.05839 13.1055 8.85717 12.9043 8.85717 12.6584V9.3048C8.85717 9.05888 9.05839 8.85766 9.30432 8.85766H12.6579C12.9038 8.85766 13.105 9.05888 13.105 9.3048V12.6584Z" />
            </g>
            <defs>
              <clipPath id="clip0_393_776">
                <rect width="14" height="14" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>

    <!-- Grid de ficheros -->

    <!--Convertirlo en 1 -->

    <div ref="fileScroll" :class="listOption ? 'files-list' : 'files'">
      <!-- Botón de ir hacia atrás si estamos en una carpeta -->
      <div class="file before" v-if="currentFolder" @click="navigateToParentFolder">
        <img src="@/assets/icons/folder-back.svg" />
        <h3>Atrás</h3>
      </div>

      <!-- Carpetas y archivos -->
      <div class="file file-grid" :class="{ selected: isSelected(file.id) }" v-for="(file) in filteredFiles"
        :key="file.path" @click="handleClick(file, $event)">

        <div class="checkbox">
          <checkbox :id="'file-' + file.id" width="12px" :checked="isAllSelected"
            @update-value="toggleFileSelection(file.id)" />
        </div>
        <div class="dropdown-div" @click="(e) => calculateDistance(e)">
          <Dropdown @activeDropdownOpen="activeFileDropdownOpen" :isEditPosition="true"
            :estilos="{ top: `${fileDropdownPositionY}px`, left: `${fileDropdownPositionX}px` }" width="10px"
            :links="links" :ref="'dropdownNote' + file.id" />

        </div>
        <img v-if="file.type === 'file' && !listOption" src="@/assets/icons/general-file.svg" />
        <img v-if="file.type !== 'file'" src="@/assets/icons/folder.svg" />
        <h3 :title="file.name">{{ file.name }}</h3>
      </div>
    </div>


    <!-- Mensaje si no hay archivos -->




    <!--Opciones sobre los documentos-->
    <div class="general-options">
      <GeneralButton to="/" text="Nuevo documento" />

      <div class="download-upload-options">

        <div class="selectConfigureOption">
          <SelectConfigDropdown :links="dropdownLinks">

            <template #modalContent1>
              <!-- Contenido del modal para la primera opción -->
              <p>Introduzca el nombre:</p>
            </template>

            <template #modalContent2>
              <!-- Contenido del modal para la segunda opción -->
              <p>Este es el contenido del segundo modal.</p>
            </template>
            <!-- Añadir más slots según sea necesario -->
          </SelectConfigDropdown>
        </div>
        <div class="dowload-btn">
          <GeneralButton to="/" text="Descargar" v-if="downloadButton" />
        </div>

        <div :class="['update-files', { 'all-space': !downloadButton }, { 'half-space': downloadButton }]">
          <InputFile text="Subir archivos" @files-selected="(files) => uploadFiles(files)"
            :estilos="{ backgroundColor: '#94A7AA', color: '#F9FBFC' }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/forms/Checkbox.vue";
import Dropdown from "../general/Dropdown.vue";
import GeneralButton from "@/components/general/Button.vue";
import InputFile from "@/components/forms/InputFile.vue";
import ProgressBar from "../general/ProgressBar.vue";
import SelectConfigDropdown from "@/components/forms/SelectConfigDropdown.vue";

export default {
  name: "FileExplorer",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    Checkbox,
    Dropdown,
    GeneralButton,
    InputFile,
    ProgressBar,
    SelectConfigDropdown,
  },
  data() {
    return {
      listOption: false,
      files: [
        {
          id: 1,
          name: "video textoooo muyyy muyyy largooo para comprobarrrrr comoooo se vee este texto tan largooooo.mp4",
          type: "file",
          path: "video.mp4",
          size: 0,
          lastModified: "2023-06-20T13:30:00Z",
        },
        {
          id: 2,
          name: "imagen1.png",
          type: "file",
          path: "imagen1.png",
          size: 2.5,
          lastModified: "2023-06-21T14:00:00Z",
        },
        {
          id: 3,
          name: "documento1.pdf",
          type: "file",
          path: "documento1.pdf",
          size: 1.8,
          lastModified: "2023-06-22T15:30:00Z",
        },
        {
          id: 4,
          name: "archivo1.zip",
          type: "file",
          path: "archivo1.zip",
          size: 5.2,
          lastModified: "2023-06-23T10:45:00Z",
        },
        {
          id: 5,
          name: "documento2.docx",
          type: "file",
          path: "documento2.docx",
          size: 0.9,
          lastModified: "2023-06-24T11:15:00Z",
        },
        {
          id: 6,
          name: "imagen2.jpg",
          type: "file",
          path: "imagen2.jpg",
          size: 3.1,
          lastModified: "2023-06-25T12:30:00Z",
        },
        {
          id: 7,
          name: "documento3.pdf",
          type: "file",
          path: "documento3.pdf",
          size: 2.3,
          lastModified: "2023-06-26T13:45:00Z",
        },
        {
          id: 8,
          name: "archivo2.zip",
          type: "file",
          path: "archivo2.zip",
          size: 7.6,
          lastModified: "2023-06-27T14:00:00Z",
        },
        {
          id: 9,
          name: "documento4.docx",
          type: "file",
          path: "documento4.docx",
          size: 1.2,
          lastModified: "2023-06-28T15:15:00Z",
        },
        {
          id: 10,
          name: "imagen3.png",
          type: "file",
          path: "imagen3.png",
          size: 4.2,
          lastModified: "2023-06-29T16:30:00Z",
        },
        {
          id: 11,
          name: "documento5.pdf",
          type: "file",
          path: "documento5.pdf",
          size: 3.1,
          lastModified: "2023-06-30T17:45:00Z",
        },
        {
          id: 12,
          name: "archivo3.zip",
          type: "file",
          path: "archivo3.zip",
          size: 9.8,
          lastModified: "2023-07-01T18:00:00Z",
        },
        {
          id: 13,
          name: "video.mp4",
          type: "file",
          path: "video.mp4",
          size: 0,
          lastModified: "2023-06-20T13:30:00Z",
        },
        {
          id: 14,
          name: "carpeta1",
          type: "folder",
          path: "carpeta1/",
          size: 0,
          lastModified: "2025-08-05T16:45:00Z",
        },
        {
          id: 15,
          name: "imagen98.jpg",
          type: "file",
          path: "carpeta1/imagen98.jpg",
          size: 3.3,
          lastModified: "2025-08-07T09:00:00Z",
        },
        {
          id: 16,
          name: "documento194.pdf",
          type: "file",
          path: "carpeta1/documento194.pdf",
          size: 1.2,
          lastModified: "2025-08-09T11:15:00Z",
        },
        {
          id: 17,
          name: "documento195.docx",
          type: "file",
          path: "carpeta1/documento195.docx",
          size: 0.7,
          lastModified: "2025-08-11T14:30:00Z",
        },
        {
          id: 18,
          name: "carpeta2",
          type: "folder",
          path: "carpeta2/",
          size: 0,
          lastModified: "2025-08-13T16:45:00Z",
        },
        {
          id: 19,
          name: "imagen99.png",
          type: "file",
          path: "carpeta2/imagen99.png",
          size: 2.7,
          lastModified: "2025-08-15T09:00:00Z",
        },
        {
          id: 20,
          name: "documento196.pdf",
          type: "file",
          path: "carpeta2/documento196.pdf",
          size: 1.3,
          lastModified: "2025-08-17T11:15:00Z",
        },
        {
          id: 21,
          name: "documento197.docx",
          type: "file",
          path: "carpeta2/documento197.docx",
          size: 0.8,
          lastModified: "2025-08-19T14:30:00Z",
        },
        {
          id: 22,
          name: "carpeta3",
          type: "folder",
          path: "carpeta3/",
          size: 0,
          lastModified: "2025-08-21T16:45:00Z",
        },
        {
          id: 23,
          name: "imagen100.jpg",
          type: "file",
          path: "carpeta3/imagen100.jpg",
          size: 3.3,
          lastModified: "2025-08-23T09:00:00Z",
        },
        {
          id: 24,
          name: "documento198.pdf",
          type: "file",
          path: "carpeta3/documento198.pdf",
          size: 1.2,
          lastModified: "2025-08-25T11:15:00Z",
        },
        {
          id: 25,
          name: "documento199.docx",
          type: "file",
          path: "carpeta3/documento199.docx",
          size: 0.7,
          lastModified: "2025-08-27T14:30:00Z",
        },
        {
          id: 26,
          name: "imagen101.png",
          type: "file",
          path: "imagen101.png",
          size: 2.5,
          lastModified: "2025-08-29T12:00:00Z",
        },
        {
          id: 27,
          name: "documento200.txt",
          type: "file",
          path: "documento200.txt",
          size: 0.4,
          lastModified: "2025-08-31T15:30:00Z",
        },
        {
          id: 28,
          name: "Expediente de denuncia 123",
          type: "folder",
          path: "denuncia123/",
          size: 0,
          lastModified: "2023-06-20T13:30:00Z",
        },
        {
          id: 29,
          name: "documento1.pdf",
          type: "file",
          path: "denuncia123/documento1.pdf",
          size: 1.2,
          lastModified: "2023-06-01T12:00:00Z",
        },
        {
          id: 30,
          name: "documento2.docx",
          type: "file",
          path: "denuncia123/documento2.docx",
          size: 0.8,
          lastModified: "2023-06-05T08:30:00Z",
        },
        {
          id: 31,
          name: "imagen1.png",
          type: "file",
          path: "denuncia123/imagen1.png",
          size: 2.5,
          lastModified: "2023-06-10T14:20:00Z",
        },
        {
          id: 32,
          name: "video.mp4",
          type: "file",
          path: "denuncia123/video.mp4",
          size: 15.6,
          lastModified: "2023-06-12T16:45:00Z",
        },
        {
          id: 33,
          name: "presentacion.pptx",
          type: "file",
          path: "denuncia123/presentacion.pptx",
          size: 4.3,
          lastModified: "2023-06-15T09:15:00Z",
        },
        {
          id: 34,
          name: "archivo.zip",
          type: "file",
          path: "denuncia123/archivo.zip",
          size: 10.1,
          lastModified: "2023-06-18T11:00:00Z",
        },
        {
          id: 35,
          name: "subcarpeta1",
          type: "folder",
          path: "denuncia123/subcarpeta1/",
          size: 0,
          lastModified: "2023-07-05T09:40:00Z",
        },
        {
          id: 36,
          name: "documento3.pdf",
          type: "file",
          path: "denuncia123/subcarpeta1/documento3.pdf",
          size: 1.1,
          lastModified: "2023-06-25T10:05:00Z",
        },
        {
          id: 37,
          name: "imagen2.jpg",
          type: "file",
          path: "denuncia123/subcarpeta1/imagen2.jpg",
          size: 3.2,
          lastModified: "2023-06-28T12:50:00Z",
        },
        {
          id: 38,
          name: "musica.mp3",
          type: "file",
          path: "denuncia123/subcarpeta1/musica.mp3",
          size: 7.4,
          lastModified: "2023-07-01T14:30:00Z",
        },
        {
          id: 39,
          name: "hoja_calculo.xlsx",
          type: "file",
          path: "denuncia123/subcarpeta1/hoja_calculo.xlsx",
          size: 2.9,
          lastModified: "2023-07-03T16:15:00Z",
        },
        {
          id: 40,
          name: "documento4.odt",
          type: "file",
          path: "denuncia123/subcarpeta1/documento4.odt",
          size: 0.9,
          lastModified: "2023-07-07T11:25:00Z",
        },
        {
          id: 41,
          name: "subcarpeta2",
          type: "folder",
          path: "denuncia123/subcarpeta2/",
          size: 0,
          lastModified: "2023-07-09T13:55:00Z",
        },
        {
          id: 42,
          name: "documento5.pdf",
          type: "file",
          path: "denuncia123/subcarpeta2/documento5.pdf",
          size: 2.5,
          lastModified: "2023-07-12T09:30:00Z",
        },
        {
          id: 43,
          name: "documento6.docx",
          type: "file",
          path: "denuncia123/subcarpeta2/documento6.docx",
          size: 1.8,
          lastModified: "2023-07-14T11:45:00Z",
        },
        {
          id: 44,
          name: "imagen3.png",
          type: "file",
          path: "denuncia123/subcarpeta2/imagen3.png",
          size: 4.2,
          lastModified: "2023-07-16T14:20:00Z",
        },
        {
          id: 45,
          name: "subcarpeta3",
          type: "folder",
          path: "denuncia123/subcarpeta2/subcarpeta3/",
          size: 0,
          lastModified: "2023-07-18T16:40:00Z",
        },
        {
          id: 46,
          name: "documento7.pdf",
          type: "file",
          path: "denuncia123/subcarpeta2/subcarpeta3/documento7.pdf",
          size: 1.3,
          lastModified: "2023-07-20T09:00:00Z",
        },
        {
          id: 47,
          name: "imagen4.jpg",
          type: "file",
          path: "denuncia123/subcarpeta2/subcarpeta3/imagen4.jpg",
          size: 3.6,
          lastModified: "2023-07-22T12:15:00Z",
        },
        {
          id: 48,
          name: "documento8.docx",
          type: "file",
          path: "denuncia123/subcarpeta2/subcarpeta3/documento8.docx",
          size: 1.1,
          lastModified: "2023-07-24T14:30:00Z",
        },
        {
          id: 49,
          name: "subcarpeta4",
          type: "folder",
          path: "denuncia123/subcarpeta2/subcarpeta4/",
          size: 0,
          lastModified: "2023-07-26T16:45:00Z",
        },
        {
          id: 50,
          name: "imagen5.png",
          type: "file",
          path: "denuncia123/subcarpeta2/subcarpeta4/imagen5.png",
          size: 2.8,
          lastModified: "2023-07-28T09:00:00Z",
        },
        {
          id: 51,
          name: "documento9.pdf",
          type: "file",
          path: "denuncia123/subcarpeta2/subcarpeta4/documento9.pdf",
          size: 1.7,
          lastModified: "2023-07-30T11:15:00Z",
        },
      ],

      links: [
        { text: "Descargar", href: "/detalles-expediente" },
        { text: "Renombrar", href: "/editar-expediente" },
        { text: "Eliminar", href: "/eliminar-expediente" },
      ],

      dropdownLinks: [
        { action: 'modal', modalProps: { modalTitle: 'Nueva carpeta', submitTitle: 'Crear carpeta' }, text: 'Nueva carpeta', slotName: 'modalContent1' },
        { action: 'modal', modalProps: { modalTitle: 'Eliminar', submitTitle: 'Eliminar carpeta' }, text: 'Eliminar', slotName: 'modalContent2' }
      ],

      fileOptions: [
        { text: "Nueva carpeta", href: "/detalles-expediente" },
        { text: "Eliminar", href: "/eliminar-expediente" },
      ],
      fileDropdownPositionX: 0,
      fileDropdownPositionY: 0,
      isFileDropdownOpen: false,
      showProgress: false,
      progress: 0,
      currentFolder: "", // Ruta de la carpeta actual
      selectedFiles: [], // IDs de archivos seleccionados
      downloadButton: false,
    };
  },

  mounted() {
    this.$refs.fileScroll.addEventListener('scroll', this.handleScroll);
  },

  beforeUnmount() {
    this.$refs.fileScroll.removeEventListener('scroll', this.handleScroll);
  },

  computed: {
    filteredFiles() {
      if (!this.currentFolder) {
        // Mostrar archivos y carpetas en la raíz
        return this.files.filter((file) => {
          // Filtrar los archivos y carpetas que están directamente en la raíz
          return file.path.split("/").filter(Boolean).length === 1;
        });
      } else {
        // Mostrar archivos y subcarpetas dentro de la carpeta actual
        const currentFolderPath = this.currentFolder;
        return this.files.filter((file) => {
          const filePath = file.path;
          if (file.type === "folder") {
            // Mostrar solo subcarpetas directas dentro de la carpeta actual
            return (
              filePath.startsWith(currentFolderPath) &&
              filePath !== currentFolderPath && // Excluir la carpeta actual
              filePath.split("/").filter(Boolean).length ===
              currentFolderPath.split("/").filter(Boolean).length + 1
            );
          } else {
            // Mostrar archivos dentro de la carpeta actual
            return filePath.startsWith(currentFolderPath);
          }
        });
      }
    },

    isAllSelected() {
      return (
        this.filteredFiles.length > 0 &&
        this.filteredFiles.every((file) => this.selectedFiles.includes(file.id))
      );
    },

    isSomeSelected() {
      return this.selectedFiles.length > 0;
    },
  },

  methods: {
    calculateDistance(e) {

      //cogemos la posicion x e y del padre de las notas y del boton clicado

      const widgetNotes = this.$refs.fileExplorer;
      const dropdown = e.target

      const widgetRect = widgetNotes.getBoundingClientRect();
      const dropdownRect = dropdown.getBoundingClientRect();

      // buscamos la diferencia de ambos valores para saber cuanto top y left darle al elemento position absolute
      this.fileDropdownPositionX = dropdownRect.left - widgetRect.left
      this.fileDropdownPositionY = dropdownRect.top - widgetRect.top

      // si es movil, necesitamos quitarle px en left porque se sale de la pantalla al ser una sola columna
      if (window.innerWidth <= 768) {
        this.fileDropdownPositionX -= 50
      }
    },
    activeFileDropdownOpen() {
      this.isFileDropdownOpen = true
    },
    handleScroll() {
      //ocultar los dropdwon de notas cuando se haga scroll ya que debido al overflow
      if (!this.isFileDropdownOpen) return

      this.filteredFiles.forEach((file,) => {
        const refName = 'dropdownNote' + file.id
        this.$refs[refName][0].closeDropdown()
      });
      this.isFileDropdownOpen = false
    },
    changeToList() {
      this.listOption = true
    },
    changeToSquare() {
      this.listOption = false
    },
    uploadFiles(files) {
      // Ejemplo de simulación de carga con un temporizador
      this.showProgress = true;
      const totalSize = files.reduce((acc, file) => acc + file.size, 0);
      let uploadedSize = 0;

      const interval = setInterval(() => {
        uploadedSize += 100000; // Ejemplo de incremento en bytes (simulado)
        this.progress = Math.min((uploadedSize / totalSize) * 100, 100);

        if (uploadedSize >= totalSize) {
          clearInterval(interval);
          this.showProgress = false;
          // Aquí puedes realizar acciones adicionales después de completar la carga
        }
      }, 1500); // Ejemplo de intervalo de actualización (simulado)
    },

    selectAllFiles(checked) {
      if (checked) {
        this.selectedFiles = this.filteredFiles.map((file) => file.id);
        this.downloadButton = true;
      } else {
        this.selectedFiles = [];
        this.downloadButton = false;
      }
    },

    toggleFileSelection(fileId) {
      const index = this.selectedFiles.indexOf(fileId);
      if (index > -1) {
        this.selectedFiles.splice(index, 1);
      } else {
        this.selectedFiles.push(fileId);
        this.downloadButton = true;
      }

      if (this.selectedFiles.length === 0) {
        this.downloadButton = false;
      }
    },

    handleClick(file, e) {
      if (file.type === "folder") {
        if (!e.target.closest(".dropdown") && !e.target.closest(".checkbox")) {
          // Nos aseguramos de que no se entre a la carpeta si el click se hace en el dropdown o en el checkbox
          if (file.type === "folder") {
            this.currentFolder = file.path;
            this.selectedFiles = [];
            this.downloadButton = false;
          }
          this.currentFolder = file.path;
          this.selectedFiles = [];
          this.downloadButton = false;
        }
      }
    },

    navigateToParentFolder() {
      if (this.currentFolder && this.currentFolder !== "") {
        const parts = this.currentFolder.split("/").filter(Boolean);
        if (parts.length > 1) {
          parts.pop(); // Eliminar la última parte (nombre de la carpeta actual)
          this.currentFolder = parts.join("/") + "/";
          this.selectedFiles = [];
        } else {
          this.currentFolder = ""; // Si estamos en la raíz, volver al inicio
          this.selectedFiles = [];
        }
      }
    },

    isSelected(fileId) {
      // Función para verificar si un archivo está seleccionado
      return this.selectedFiles.includes(fileId);
    },
  },
};
</script>

<style scoped lang="scss">
.update-files {
  width: 151px;

}

.before{
      display: flex;
    }

.file-explorer {
  width: 100%;
  overflow: visible;
  background: #fff;
  padding: 24px;
  border-radius: 4px;
  position: relative;


  .title-grid {
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: auto auto;
    display: grid;
    gap: 16px 10px;

  }

  h2 {
    grid-column: 1 / 2;
    grid-row: 1 /2;
    text-align: left;
    color: #0e0e0f;
    font-size: 16px;
    font-weight: 600;
    font-family: "InterSemiBold", sans-serif;
    margin-right: 15px;
    margin: 0;
  }

  .message {
    display: inline-flex;
    align-items: center;
    grid-column: 2 / 3;
    grid-row: 1 /2;



    p {
      display: flex;
      align-items: start;
      text-align: left;
      font-size: 12px;
      color: #55737a;
      margin: 0;
      font-family: "InterMedium", sans-serif;
      font-weight: 500;


      img {
        width: 13px;
        margin-right: 5px;
      }
    }
  }


  .contacted-space {
    grid-column: 3 / 4;
    grid-row: 1 /2;
    justify-self: end;
    display: flex;
    align-items: flex-start;

    p {
      color: #a2abad;
      font-size: 12px;
      font-weight: 500;
      font-family: "InterMedium", sans-serif;
      margin: 0;
      text-align: left;
    }

    span {
      font-weight: 400;
      font-family: "InterRegular", sans-serif;
    }
  }




  .options {
    grid-column: 1 / 2;
    grid-row: 2 /3;
    display: inline-flex;
    align-items: center;

    .folder-path {
      margin-left: 38px;

      p {
        font-size: 12px;
        color: #a2abad;
        font-family: "InterRegular", sans-serif;
        margin: 0;
      }

      span.current-folder {
        color: #365961;
        font-weight: 500;
        font-family: "InterMedium", sans-serif;
      }
    }
  }

  .list-buttons {
    grid-column: 3 / 4;
    grid-row: 2 /3;
    justify-self: end;
    display: inline-flex;
    align-items: center;

    button {
      width: 24px;
      height: 24px;
      background: none;
      border: none;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        fill: #a2abad;
        width: 16px;
      }
    }

    button:first-child {
      margin-right: 10px;
    }

    button:hover,
    .button-selected {
      background: #163f49;

      svg {
        fill: #e7f0f2;
      }
    }
  }

  .files-list {
    grid-template-columns: repeat(auto-fill, minmax(200px, auto));

    @media (max-width: 330px) {
      grid-template-columns: repeat(auto-fill, minmax(140px, auto));
    }

    .file {
      height: 34px;
      display: flex;

    }

    




    .flex-container {
      display: flex;
      gap: 9px;
      align-items: center;
    }

    .checkbox {
      margin-right: 14px;
    }



    img {
      width: 16px;
      height: 16px;
      display: block;
      margin-right: 9px;
    }

    .dropdown-div {
      margin-left: auto;
      order: 4
    }

    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      margin-right: 14px;

      @media (max-width: 350px) {
        max-width: 90px;
      }
    }
  }

  .files {
    grid-template-columns: repeat(auto-fill, minmax(96px, 1fr));

    .file {
      height: 96px;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
    }


    .file-grid {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
    }

    .checkbox {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    h3 {
      grid-column: 1 / -1;
      grid-row: 3 / 4;
      margin-right: 3px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 6px;
      overflow: hidden;
    }

    img {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
      justify-self: center;
    }

    .dropdown-div {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      justify-self: flex-end;
    }

    img {
      width: 32px;
      height: 32px;
    }
  }



  .files,
  .files-list {
    display: grid;
    padding: 0 24px;

    grid-gap: 10px;
    margin: 25px 0;
    max-height: 250px;
    overflow: auto;


    .file {
      min-width: 96px;
      background: #f4f8f9;
      padding: 3px 3px 6px 6px;



      align-items: center;
      border-radius: 4px;
      cursor: pointer;

      h3 {
        text-align: center;
        font-size: 10px;
        color: #0e0e0f;
        font-weight: 400;
        font-family: "InterRegular", sans-serif;

      }
    }

    .file:hover,
    .file.selected {
      background: #e0ebee;
    }
  }

  .general-options {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;

    .download-upload-options {
      display: inline-flex;
      gap: 16px;

      a {
        width: fit-content !important;
        padding-inline: 10px
      }

      button:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}


@media (max-width: 750px) {

  .file-explorer {

    .title-grid {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto;
    }

    h2 {
      display: flex;
      align-items: end;
    }

    .message {
      grid-row: 3 / 4;
      grid-column: 1 / -1;
    }

    .contacted-space {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
      justify-self: end;
    }

    .options {
      grid-column: 1 / -1;
      grid-row: 4 / 5;
    }

    .list-buttons {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    .files, .files-list {
      padding: 12px;
    }


    .general-options {
      flex-direction: column;



      &>a {

        width: 100% !important;
      }

      .download-upload-options {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        width: 100%;

        .selectConfigureOption {
          justify-self: end;
          grid-column: 2 / 3;
          grid-row: 2 / 3;
          margin-right: -16px;
        }

        .update-files {
          grid-row: 1 / 2;
          width: 100%;

        }




        .dowload-btn {
          grid-column: 2 / -1;
          grid-row: 1 / 2;
          width: 100%;


          a {
            width: 100% !important;
            padding-inline: 10px;
          }
        }

        button:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

  }

  .all-space {
    grid-column: 1 / -1;
  }

  .half-space {
    grid-column: 1 / 2;
  }
}


@media (max-width: 350px) {
  .all-space {
    grid-column: 1 / -1;
  }

  .half-space {
    grid-column: 1 / -1;

  }



  .file-explorer {


    .general-options {

      .download-upload-options {
        grid-template-rows: auto auto auto;

        .selectConfigureOption {
          grid-row: 3 / 4;
        }

        .update-files {
          grid-row: 1 / 2;
          width: 100%;

        }

        .dowload-btn {
          grid-column: 1 / -1;
          grid-row: 2 / 3;
          width: 100%;
        }
      }
    }

  }
}
</style>
