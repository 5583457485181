<template>
    <div :style="divStyle"  class="general-title">
        <Breadcrumb v-if="!noBreadcrumbs" :breadcrumbs="this.breadcrumbs"></Breadcrumb>
        <h1 class="general-title__main">{{ this.title }}</h1>
        <div class="general-title__responsive">
            <img v-if="!this.root" src="@/assets/icons/vector.svg" alt="Vector" class="reponsive__icon" @click="this.goBack()">
            <h1 class="responsive__text">{{ this.title }}</h1>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/general/Breadcrumb.vue'
export default {
    name: 'generalTitle',
    components: {
        Breadcrumb
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        breadcrumbs: {
            type: Array,
            default: () => []
        },
        root: {
            type: Boolean,
            default: false
        },
        noBreadcrumbs: {
            type: Boolean,
            default: false
        },
        divStyle: {
            type: Object,
            required: false
        }
    },
    methods: {
        goBack() {
            this.$emit('goBack')
        }
    }
}
</script>
<style lang="scss" scoped>

    h1{
        margin: 0;
        text-align: left
    }
    .general-title{
        display: flex;
        flex-direction: column;
        gap:24px;
        width:100%;
       
        .general-title__main{
            font-size: 30px;
            font-family: 'InterSemibold', sans-serif;
            color: #163F49;
            width: fit-content;
        }
        .general-title__responsive{
            display: none;
            align-items: center;
            gap: 24px;
            .reponsive__icon{
                width: 8px;
                cursor: pointer;
                height: 15px;
            }
            .responsive__text{
                font-size: 20px;
                font-family: 'InterSemibold', sans-serif;
                color: #163F49;
            }
        }
    }
@media(max-width: 1024px){
    .general-title{
        .breadcrumb{
            display: none;
        }
        .general-title__main{
            display: none;
        }
        .general-title__responsive{
            display: flex;
        }
    }
}
</style>