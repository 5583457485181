<template>
  <CaseFileLayaout :titleName="titleName" :createbutton="createbutton" :pathData="pathData" :breadcrumbs="breadcrumbs"
    :dropdownData="dropdownData" :deleteModalInfo="deleteModalInfo" />

</template>



<script>
import CaseFileLayaout from '../general/CaseFileLayaout.vue';


export default {
  name: "CaseFilesComponent",
  components: {
    CaseFileLayaout
  },
  data() {
    return {
      titleName: 'Expedientes',
      createbutton: {
        to: '/expedientes/create-proceeding',
        text: 'Nuevo Expediente'
      },
      isSubCase: false,
      pathData: {
        getData: '/case_files',
        pathKey: 'files'
      },
      breadcrumbs: [{ label: 'Expedientes', to: '/expedientes' }],
      deleteModalInfo: {
        deleteModalText: {
          title: '¿Desea eliminar el expediente seleccionado?',
          namePlural: 'expediente',
          textSingular: 'Se eliminará el expediente seleccionado',
          errorSingular: 'No se puede eliminar por alguna de las siguientes razones: tiene subexpedientes asociados o incluye alguna nota en sus detalles'
        },
        path: "/case_files"
      }
    }
  },
  methods: {
    dropdownData(caseID) {
      return [
        { text: 'Visualizar', href: '/expedientes/' + caseID },
        { text: 'Editar', href: '/expedientes/editar-expediente/' + caseID },
        { text: 'Eliminar', data: { action: 'toggleModalSimple', id: caseID }, estilos: {color: '#C74D4D'} },
        { text: 'Subexpedientes', href: '/expedientes/sub-expediente/' + caseID }
      ]

    }
  },
}


</script>
