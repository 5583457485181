<template>

    <CreateAndEditLayaout :name="name" :path="path" :breadcrumbs="breadcrumbs" />

</template>


<script>
import CreateAndEditLayaout from '@/components/general/CreateAndEditLayaout.vue'

export default {
    name: 'NewCategory',
    components: { CreateAndEditLayaout },
    data() {
        return {
            name: {
                button: 'Guardar Categoría',
                title: 'Nueva Categoría'
            },
            path: '/categories',
            breadcrumbs: [{ label: 'Categorías', to: '/categorias' }, { label: 'Nueva Categoría', to: '/categorias/crear-categoria' }],
        }
    }

}
</script>
