<template>
    <div class="data-box">

        <div class="data-box-body">
            <div class="body-top">
                <span class="department">Departamento</span>
                <span :title="this.name" class="company-name">{{ this.name }}</span>
            </div>
            <div class="body-bottom">
                <span class="language">{{ this.language }}</span>
              
            </div>
        </div>
        <div class="absolute">
            <Dropdown :isEditPosition="true" :estilos="{top: '15px', left: '-100px'}" @editDepartment="this.editDepartment" @editInCreateCompany="this.editInCreateCompany" :links="this.dropdownOptions" @deleteCompany="this.$emit('deletedDepartment',this.id!=='' ? this.id:this.index)"/>
        </div>
       
    </div>
</template>

<script>
import Dropdown from '@/components/general/Dropdown.vue';
export default {
    name: 'CompanyBoxComponent',
    data() {
        return {
            desplegableOpen: false,
            dropdownOptions:[
            {text: this.$t('generalPanel.editText'),data: {action: this.id!== '' ? 'editDepartment' :  'editInCreateCompany'} },
            {text: this.$t(`generalPanel.deleteText`),data: {action:'deleteCompany',index:this.index},  estilos: {color: '#C74D4D'}  }
            ]
        }
    },
    components: {
        Dropdown
    },
    methods: {
        editDepartment(){

            let parsedLanguage = this.languageOptions.find((language) => {
                return language.label === this.language;
            });
            this.$emit('editDepartmentActive', {name: this.name, language: parsedLanguage, deparmentId: this.id, existed: true})
        },
        editInCreateCompany(){
         
            let parsedLanguage = this.languageOptions.find((language) => {
                return language.label === this.language;
            });
          

            this.$emit('editDepartmentActive', {name: this.name, language: parsedLanguage, index: this.index, existed: false})
           
        }
    },

    props: {
        id: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        language: {
            type: String,
            default: ''
        },
        index:{
            type: Number,
            default: 0
        },
        languageOptions: {
            type: Array,

        },
        
    },
}
</script>

<style scoped lang="scss">

.absolute{
    position: absolute;
            top: 12px;
            right: 18px;
}
.data-box {
    width: 100%;
    height: 91px;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    padding: 24px;
    position: relative;
    align-items: center;
    gap: 16px;
    .data-box-body{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .body-top{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 50%;
            gap: 10px;
            .department{
                font-size: 14px;
                color: #A0A0A0;
                max-width: 120px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
            }
            .company-name{
                font-size: 16px;
                color: #000000;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
            }
        }
        .body-bottom{
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: flex-end;
            width: 40%;
            gap: 10px;
            .company-code{
                font-size: 14px;
                color: #A0A0A0;
                max-width:70px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: right;
            }
            .language{
                font-size: 14px;
                color: #A0A0A0;
                max-width:80px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: right;
            }
        }
    }
    .ellipses{
        width: 50px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .editar-borrar{
        position: absolute;
        top: auto;
        right: 35px;
        bottom: -35px;
    }
}
@media (max-width: 768px){
    .data-box{
        padding: 16px;
        .body-top{
            width: 55%;
            .department{
                font-size: 14px;
            }
            .company-name{
                font-size: 14px;
                width: 120px;
            }
        }
        .body-bottom{
            width: 30%;
            .company-code{
                font-size: 14px;
            }
            .language{
                font-size: 14px;
            }
        }
        .dropdown{
            height: fit-content;
            :deep(.dropdown-toggle){
                padding: 0;

            }
        }
    }

}
</style>