<template>

    <div class="configuration">

        <h1>Configuración</h1>

        <!--Options-->
        <div class="options">

            <!--Manage subscription
            <div class="option">
                <h2>Suscripción <span class="state active">Activa</span></h2>
                <a class="manage-suscription" href="#">Gestionar <span>
                        suscripción</span></a>
            </div>
            -->

            <!--Close time casefile-->
            <div class="option">
                <h2>Cierre automático del expediente</h2>
                <div class="input">
                    <ConfigurationInput placeholder="45" value="45" text="Días" />
                </div>

            </div>

            <!--Remove time casefile-->
            <div class="option">
                <h2>Tiempo de eliminación definitiva del expediente</h2>
                <div class="input">
                    <ConfigurationInput placeholder="20" text="Años" />
                </div>
            </div>

            <!--Change language-->
            <div class="option">
                <h2>Cambiar idioma</h2>
                <div class="select">
                    <Select name="languageSelect" id="languageSelect" :options="languageOptions" :optionSelected="this.$store.getters.getSelectedLanguage" @optionChanged="this.$store.dispatch('setSelectedLanguage',$event)"/>
                </div>

            </div>

            <!--Enable/disable MFA-->
            <div class="option">
                <h2>Doble verificación (MFA)</h2>
                <ToggleSwitch @input="handleToggleSwitchChange" />
            </div>

            <!--Remove account-->
            <div class="option remove-account">
                <a href="#"><img src="../../assets/icons/remove.svg">Eliminar cuenta</a>
            </div>

        </div>

        <div class="copyright">
            <CopyrightText />
        </div>

        <!--Modal of QR code-->
        <ModalQr :isVisible="modals.modal2" @close="closeModal('modal2')"
            modalTitle="Configuración de doble verificación(MFA)" submitTitle="He escaneado el código QR"
            QrValue="563423423423423">

            <h3>Instrucciones</h3>

            <p>Escaneé este código QR con la camara de su dispositivo desde una aplicación como Google Authenticator o
                similar.
            </p>

            <p>La aplicación Authenticator de su preferencia generará un código cada cierto tiempo que le será
                solicitado en el inicio de sesión de esta aplicación siempre que esta opción esté activada.</p>

        </ModalQr>

    </div>
</template>


<script>
import ModalQr from '@/components/modal/ModalQr.vue'
import ConfigurationInput from '@/components/forms/ConfigurationInput.vue'
import Select from '@/components/forms/Select.vue'
import ToggleSwitch from '@/components/forms/ToggleSwitch.vue'
import CopyrightText from '@/components/general/CopyrightText.vue'


export default {
    name: 'ConfigurationComponent',
    data() {
        return {
            modals: {
                modal1: false,
                modal2: false,
            },

            switchValue: false,
            languageOptions: [
                { label: 'Español', value: 'es' },
                { label: 'Francés', value: 'fr' },
                { label: 'Inglés', value: 'en' },
            ],
        }
    },
    components: {
        Select,
        ToggleSwitch,
        ConfigurationInput,
        CopyrightText,
        ModalQr,
    },

    methods: {
        openModal(modalId) {
            this.modals[modalId] = true;
        },

        closeModal(modalId) {
            this.switchValue = false;
            this.modals[modalId] = false;

        },

        handleToggleSwitchChange(newValue) {
            this.switchValue = newValue;
            if (this.switchValue === true) {
                this.openModal('modal2');
            }
        },
    },

    props: {

    },


}
</script>

<style scoped lang="scss">
.configuration {
    width: 100%;
    height: 100%;

    h1 {
        text-align: left;
        margin: 25px 0 24px 0;
        font-size: 30px;
    }

    .options {
        width: 100%;
        text-align: left;
        margin-top: 113px;

        .option {
            width: 100%;
            min-height: 92px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 40px 24px 24px;
            border-top: 1px solid #B3BBBC;

            .select {
                width: 100%;
                max-width: 148px;
            }

            .input {
                width: 100%;
                max-width: 141px;
            }

            h2 {
                margin: 0;
                font-family: 'InterMedium';
                font-weight: 500;
                font-size: 16px;
                color: #0E0E0F;
                padding-right: 15px;


            }

            .manage-suscription {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #163F49;
                padding: 10px;
                border-radius: 4px;
                font-family: 'interMedium';

                span {
                    margin-left: 4px;
                }
            }


            span.state {
                font-size: 16px;
                font-family: 'interMedium';
                margin-left: 10px;
                border-radius: 17px;
                padding: 5px 14px;
            }

            span.state.active {
                color: #55737A;
                border: 1px solid #55737A;
            }

            span.state.pending {
                color: #E7885F;
                border: 1px solid #E7885F;
            }

            span.state.pending {
                color: #C74D4D;
                border: 1px solid #C74D4D;
            }
        }


        .remove-account a {
            color: #C74D4D;
            display: flex;
            align-items: center;

            img {
                margin-right: 8px;
            }
        }


        .option:last-child {
            border-bottom: 1px solid #B3BBBC;
        }
    }

    .copyright {
        display: none;
    }
}




@media(max-width: 768px) {
    .configuration {

        h1 {
            font-size: 20px;
            margin: 0;

        }

        .options {
            margin-top: 48px;

            .option {
                padding: 10px;
                min-height: 63px;

                .manage-suscription {
                    max-width: 103px;
                    font-size: 12px;

                    span {
                        display: none;
                    }

                }

                span.state {
                    font-size: 12px;
                }

                h2 {
                    font-size: 12px;
                }

                .select {
                    max-width: 103px;
                }


            }



            .remove-account a {
                font-size: 12px;

                img {
                    margin-right: 10px;

                }
            }
        }

        .copyright {
            display: block;
            margin-top: 0;
            padding-bottom: 32px;
        }
    }
}


@media(max-width: 320px) {
    .configuration {



        .options .option {
            padding: 10px 0;

            h2 {
                padding-right: 0;
            }


            span.state {
                display: block;
                margin-top: 10px;
                margin-left: 0;
            }


        }
    }
}
</style>