<template>
    <div :style="{borderBottom: `6px solid ${this.color}`}" class="data-box" :class="getDataBoxClass(status)">
        <div class="qty-time">
            <h2>{{ title }}</h2>
            <p><span>{{ sign }}{{ periodNumber }}</span> {{ period }}</p>
        </div>

        <div class="qty-total">
            <h2>Total</h2>
            <p :style="{color}">{{ total }}</p>
        </div>
    </div>


</template>

<script>


export default {
    name: 'DataBoxComponent',
    data() {
        return {

        }
    },

    methods: {
        getDataBoxClass(status) {
            if (status === 'inProgress') {
                return 'in-progress';
            } else if (status === 'Closed') {
                return 'closed';

            } else if (status == 'clear') {
                return 'clear';
            }
            else {
                return 'new';
            }
        },
    },

    props: {
        title: String,
        periodNumber: Number,
        period: String,
        total: Number,
        sign: String,
        status: String,
        color: String
    },
}
</script>

<style scoped lang="scss">


.data-box {
    width: 100%;
    background: #FFFFFF;
    padding: 24px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;



    .qty-time {

        text-align: left;

        h2 {
            font-size: 16px;
            font-family: 'InterSemiBold', sans-serif;
            color: #B3BBBC;
            font-weight: 600;
            margin: 0 0 15px 0;
        }

        p {
            span {
                font-family: 'InterMedium', sans-serif;
                color: #163F49;
            }

            font-size: 14px;
            color: #A2ABAD;
            font-family: 'InterRegular',
            sans-serif;

        }

    }

    .qty-total {

        text-align: right;


        h2 {
            font-size: 14px;
            font-family: 'InterRegular', sans-serif;
            font-weight: 400;
            color: #B3BBBC;
            margin: 0 0 5px 0;
        }

        p {
            font-size: 36px;
            color: #163F49;
            font-family: 'InterSemiBold', sans-serif;
        }
    }


}


/*Media queries*/

@media(max-width: 576px) {

    .data-box.new,
    .data-box.in-progress,
    .data-box.closed {
        border-bottom-width: 4px;
    }

    .data-box {
        border-radius: 4px;
        padding: 16px 8px;

        .qty-time {
            h2 {
                font-size: 14px;
                padding-right: 5px;
            }

            p {

                font-size: 12px;

                span {
                    font-size: 14px;
                }
            }
        }


        .qty-total {




            h2 {
                font-size: 12px;

            }

            p {
                font-size: 24px;

            }
        }
    }
}
</style>