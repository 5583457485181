<template>
    <div :style="styleDiv"> 
    <p :class="[
        { 'active': isActive },
        { 'notActive': !isActive }, 
        {'create-edit': createEdit}
    ]" :style="{ color: color, borderColor: color }"> <img v-if="icon" :src="icono" alt="icon"> {{ text }}</p>
    </div>
</template>


<script>
export default {
    name: 'InfoMessage',
    props: {
        text: {
            type: String,
            required: true,
            default: ""
        },
        isActive: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: "#55737A"
        },
        icon: {
            type: String,
        },
        createEdit: {
            type: Boolean,
            default: false
        },
        styleDiv: Object
    },
    data() {
        return { icono: "" }
    },
    mounted() {
        if (this.icon === 'info') {
            this.icono = require('@/assets/icons/infoMessage.svg');
        }
        else if (this.icon === 'error') {
            this.icono = require('../../assets/icons/errorMessage.svg');
        }
    },
    watch: {
        isActive() {
            // Lógica para actualizar el icono basado en message.icon
            if (this.icon === 'info') {
                this.icono = require('@/assets/icons/infoMessage.svg');
            } else if (this.icon === 'error') {
                this.icono = require('../../assets/icons/errorMessage.svg');
            }
        },
        icon(){
              // Lógica para actualizar el icono basado en message.icon
              if (this.icon === 'info') {
                this.icono = require('@/assets/icons/infoMessage.svg');
            } else if (this.icon === 'error') {
                this.icono = require('../../assets/icons/errorMessage.svg');
            }
        }
    },




};



</script>

<style scoped lang="scss">
.active {
    display: flex;
}

.notActive {
    display: none;
}

div{
    margin-bottom: 20px;
}

p {
    padding: 8px;
    margin: 0;
    font-family: 'InterMedium', sans-serif;
    font-size: 12px;
    line-height: 14.52px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    width: 100%;
}

.create-edit {
    max-width: calc(893px - 48px)
}

img {
    height: 13px;
    width: 13px;
    font-size: 13px;
}
</style>