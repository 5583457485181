<template>

    <LayaoutTableDinamic :tableInfoMessage="tableInfoMessage" :deleteModalText="deleteModalText"
        :buttonTitle="buttonTitle" :search="false" :secondaryButton="false" title="Instructores"  :pendingRegistration="true"
        :breadcrumbs="this.breadcrumbsList" path="/user_instructors" :tableColumnsData="tableColumnsData" />
</template>


<script>

import LayaoutTableDinamic from '../users/LayaoutTableDinamic.vue';
export default {
    name: 'InstructorsComponent',
    components: {
        LayaoutTableDinamic
    },
    data() {
        return {
            breadcrumbsList: [
                { label: 'Usuarios', to: '/users' },
                { label: 'Gestionar Instructores', to: '/users/manage-instructors' },
            ],
            buttonTitle: {
                create: 'Crear nuevo instructor',
                linkToCreate: '/users/create-user/instructor',
                delete: 'Eliminar usuarios'
            },
            deleteModalText: {
                title: '¿Desea eliminar los instructores seleccionados?',
                namePlural: 'usuario',
                textSingular: 'Se eliminará el instructor seleccionado',
                errorSingular: 'No se puede eliminar el instructor seleccionado porque se está usando',
                errorPlural: 'No se han podido eliminar algunos de los instructores seleccionados porque están usandose'
            },
            tableInfoMessage: {
                notCreate: 'No hay instructores creados',
                notSearch: 'No se encontraron instructores'
            },
            tableColumnsData: {
                desk: {
                    columnTitle: [{ title: 'Nombre', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }, { title: 'Email', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }, { title: 'Departamento', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }, { title: 'Categoría', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }],
                    mainRowName: ['name', 'email', 'department', 'category'],
                    titleDetailsRowName: [],
                    //Tiene que hacer match con los datos
                    detailsRowName: [],
                },
                mobile: {
                    columnTitle: [{ title: 'Nombre', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha' },],
                    mainRowName: ['name'],
                    titleDetailsRowName: ['Email', 'Departamento', 'Categoría'],
                    //Tiene que hacer match con los datos
                    detailsRowName: ['email', 'department', 'category'],
                }
            }
        }
    },

}
</script>