<template>
    <div ref="caseFileCreateEditLayaout" class="template">
        <div class="title">
            <generalTitle :title="header.title" :breadcrumbs="header.breadcrumbs" :root="false" @goBack="goBack()" />
        </div>

        <div class="center" v-if="isLoading">
            <Loader />
        </div>
        <div class="get-data-error" v-else-if="isGetDataError">
            <InfoMessage :isActive="true" color="#C74D4D"
                text="Se ha producido un error. Consulte con el servicio técnico." icon="error" />
        </div>

        <div class="modal" v-else-if="!isLoading && isModalActive">
            <div>
                <h1>Para poder acceder, debe añadir a la empresa al menos un elemento de</h1>
                <ul>
                    <li v-for="(elemnt, index) in elemntNeedToContinue" :key="elemnt + index">- {{ elemnt }}</li>
                </ul>
            </div>
        </div>

        <div v-else-if="!isLoading && !isModalActive">

            <div :class="{ 'info-message': true, 'active': infoMessage.isActive, 'notActive': !infoMessage.isActive }">
                <InfoMessage :isActive="infoMessage.isActive" :color="infoMessage.icon === 'info' ? null : '#C74D4D'"
                    :text="infoMessage.text" :icon="infoMessage.icon" />
            </div>
            <form @submit.prevent="isEdit ? editCaseFile() : addCaseFile()" class="grid">


                <label for="asunto-expediente" class="colum1">
                    <p>Asunto del Expediente</p>
                    <SelectComponent v-model:selectedValue.trim="fileData.subjectFile.value" :isInput="true"
                        labelIndentify="asunto-expediente" @cleanFileDataError="cleanFileDataError"
                        :error="fileData.subjectFile.error" fileDataName="subjectFile" />
                </label>

                <label for="departamento" class="colum2">
                    <p>Departamento</p>
                    <SelectSearchBox v-model:selectedValue="fileData.department.value"
                        :optionArray="fetchData.department" labelIndentify="departamento"
                        placeholderText="Seleccione un departamento" :error="fileData.department.error"
                        fileDataName="department" @cleanFileDataError="cleanFileDataError" />
                </label>

                <label for="categorias" class="colum1">
                    <p>Categoría</p>
                    
                        <SelectSearchBox v-model:selectedValue="fileData.categories.value"
                            :optionArray="fetchData.categories" labelIndentify="categories"
                            placeholderText="Seleccione un estado" :error="fileData.categories.error"
                            fileDataName="categories" @cleanFileDataError="cleanFileDataError" />
                    
                </label>

                <label for="intructor" class="colum2">
                    <p>Asignar instructor</p>
                    <SelectSearchBox v-model:selectedValue="fileData.instructor.value"
                            :optionArray="fetchData.instructor" labelIndentify="instructores"
                            placeholderText="Seleccione un instructor" :error="fileData.instructor.error"
                            fileDataName="instructores" @cleanFileDataError="cleanFileDataError" />
                           
                </label>

                

                <div class="colum1 estado-prioridad">
                    <label for="estado">
                        <p>Estado</p>
                        <SelectSearchBox v-model:selectedValue="fileData.state.value" :optionArray="fetchData.states"
                            labelIndentify="estado" placeholderText="Seleccione un estado" :error="fileData.state.error"
                            fileDataName="state" @cleanFileDataError="cleanFileDataError" />

                    </label>
                </div>
                <div class="colum2 priority">
                    <label for="prioridad">
                        <p>Prioridad</p>
                        <SelectSearchBox v-model:selectedValue="fileData.priority.value"
                            :optionArray="fetchData.priority" labelIndentify="prioridad" placeholderText="Alta"
                            :error="fileData.priority.error" fileDataName="priority"
                            @cleanFileDataError="cleanFileDataError" />
                    </label>

                </div>


                <label for="denuncia-textArea" class="colum3">
                    <p>Denuncia</p>
                    <div class="textarea-container"
                        :class="{ 'error': fileData.report.error && fileData.report.value === '' }">
                        <textarea v-model.trim="fileData.report.value" name="denuncia-textArea" id="denuncia-textArea"
                            placeholder="Escriba aquí la denuncia"></textarea>
                        <div class="files" v-if="files.length > 0">
                            <FileComponent v-for="file in files" :key="file.name" :file="file"
                                @eliminar-archivo="eliminarArcivo" />
                        </div>
                    </div>
                    <p class="error-message" v-if="fileData.report.error && fileData.report.value === ''">El campo debe
                        rellenarse</p>
                </label>

                <div class="colum4">
                    <div class="button-container">
                        <div class="file">
                            <label class="pointer" for="fileInput"><img src="@/assets/icons/clip.svg"> Adjuntar
                                archivos</label>
                            <input class="fileTextHiden  pointer" ref="fileInput" type="file" id="fileInput" multiple
                                @change="handleFileUpload">
                        </div>
                        <div class="file">
                            <label class="pointer" for="fileInputAudio"><img src="@/assets/icons/microphone.svg"> Nota
                                de
                                voz</label>
                            <input class="fileTextHiden  pointer" ref="fileInput" type="file" accept="audio/*"
                                id="fileInputAudio" multiple @change="handleFileUpload">
                        </div>

                    </div>
                    <div class="sumbit-button-container">
                        <button type="submit">
                            {{ submitButtonTitle }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>


<script>

import generalTitle from '../general/generalTitle.vue';
import SelectComponent from '@/components/general/SelectComponent.vue'
import FileComponent from '@/components/complaint-process/FileComponent.vue';
import InfoMessage from '../general/InfoMessage.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import Loader from './LoaderComponent.vue';
import SelectSearchBox from '../forms/SelectSearchBox.vue';

export default {
    name: 'CaseFileCreateEditLayout',
    components: {
        generalTitle,
        SelectComponent,
        FileComponent,
        InfoMessage,
        Loader,
        SelectSearchBox
    },
    props: {
        header: {
            type: Object,
            required: true
        },
        submitButtonTitle: {
            type: String,
            required: true
        },
        isEdit: {
            type: Boolean,
            required: false,
            default: false
        },
        parentIdentify: {
            type: String,
            required: false
        },
        isSubCase: {
            type: Boolean,
            required: false
        }
    },
    data() {

        return {
            identifier: '',
            isGetDataError: false,
            files: [],
            isModalActive: false,
            isLoading: true,
            fetchData: {
                categories: [],
                instructor: [],
                states: [],
                department: [],
                priority: [{ name: 'Alta' }, { name: 'Media' }, { name: 'Baja' }]
            },
            fileData: {
                subjectFile: { error: false, value: '' },
                department: { error: false, value: '' },
                categories: { error: false, value: '' },
                instructor: { error: false, value: '' },
                state: { error: false, value: '' },
                priority: { error: false, value: '' },
                report: { error: false, value: '' },
            },
            elemntNeedToContinue: [],
            infoMessage: {
                isActive: false,
                icon: 'info',
                text: 'Se ha enviado correctamente',
                count: 0
            }
        };
    },
    computed: {
        ...mapGetters(['getUserCompany']),
        userCompany() {
            return this.getUserCompany;
        }
    },
    watch: {
        userCompany() {
            this.clearData()
            this.getData()
        },
        fileData: {
            handler() {
                if (this.infoMessage.isActive) {
                    this.infoMessage.count++
                    if (this.infoMessage.count === 2) {
                        this.infoMessage.isActive = false
                        this.infoMessage.count === 0
                    }

                }
            },
            deep: true
        },
        'fileData.report.value'(newValue){
           if(!this.fileData.report.error) return
           if(newValue !== '') {
            this.fileData.report.error = false
           }
        }

    },
    methods: {
        scrollToTop(){
            const element = this.$refs.caseFileCreateEditLayaout;
            element.scrollIntoView({ behavior: "smooth" });
        },
        goBack() {
            window.history.back();
        },
        cleanFileDataError(fileDataName) {
            this.fileData[fileDataName].error = false
        },
        clearData() {
            this.fileData = {
                subjectFile: { error: false, value: '' },
                department: { error: false, value: '' },
                categories: { error: false, value: '' },
                instructor: { error: false, value: '' },
                state: { error: false, value: '' },
                priority: { error: false, value: '' },
                report: { error: false, value: '' },
            }
        },
        addElemntNeedToContinue(array, name) {
            if (array.length === 0) {
                this.elemntNeedToContinue.push(name)
            }
        }
        ,
        handleFileUpload(event) {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                this.files.push(files[i]);
            }
        },
        openFileInput() {
            this.$refs.fileInput.click();
        },
        eliminarArcivo(file) {
            this.files = this.files.filter(f => f !== file);
        },
        convertPriorityNameToNumber(name) {
            let number
            switch (name) {
                case 'Baja':
                    number = 1
                    break;
                case 'Media':
                    number = 2
                    break;
                case 'Alta':
                    number = 3
                    break;
            }
            return number
        },
        showErrorMessage() {
            this.infoMessage.count = 1
            this.infoMessage.isActive = true
            this.infoMessage.icon = 'error'
            this.infoMessage.text = 'Se ha producido un error. Consulte con el servicio técnico.'
        },
        showInfoMessage(){
            this.infoMessage.count = 1
            this.infoMessage.isActive = true
            this.infoMessage.icon = 'info'
            this.infoMessage.text = 'Se ha editado correctamente.'
        },
        changePriorityNumberForText(number) {
            if (number === 1) {
                return 'Baja'
            } else if (number === 2) {
                return 'Media'
            } else {
                return 'Alta'
            }
        },
        returnIdFromCompareArray(arrayToSearch, stringToCompare) {
            let value
            arrayToSearch.forEach(element => {
                if (element.name === stringToCompare) {
                    value = element['@id']
                }
            });
            return value
        }
        ,
        async getData() {
            this.isModalActive = false;
            this.elemntNeedToContinue = [];

            try {
                const request1 = axios.get(`/categories/?company.id=${this.userCompany}&itemsPerPage=200`);
                const request2 = axios.get(`/states/?company=${this.userCompany}&itemsPerPage=200`);
                const request3 = axios.get(`/departments/?company.id=${this.userCompany}&itemsPerPage=200`);
                const request4 = axios.get(`/user_instructors?company=${this.userCompany}&itemsPerPage=200`);
                

                const responses = await Promise.all([request1, request2, request3, request4]);

                this.fetchData.categories = responses[0].data['hydra:member'];
                this.fetchData.states = responses[1].data['hydra:member'];
                this.fetchData.department = responses[2].data['hydra:member'];
                this.fetchData.instructor = responses[3].data['hydra:member'];

                if (this.fetchData.categories.length === 0 ||
                    this.fetchData.states.length === 0 ||
                    this.fetchData.department.length === 0
                ||  this.fetchData.instructor.length === 0) {

                    this.addElemntNeedToContinue(this.fetchData.categories, 'categorías');
                    this.addElemntNeedToContinue(this.fetchData.states, 'estados');
                    this.addElemntNeedToContinue(this.fetchData.department, 'departamentos');
                    this.addElemntNeedToContinue(this.fetchData.instructor, 'instructores');
                    this.isModalActive = true;
                }
            } catch (error) {
                console.error('Error during GET requests:', error);
                this.isGetDataError = true;
            }
            finally {
                if (!this.isEdit) {
                    this.isLoading = false;
                }
            }
        },
        async updateFileData() {
            try {
                let data = {}

                if (this.isSubCase) {
                    const { childId } = this.$route.params;
                    this.identifier = childId
                    const response = await axios.get(`sub_case_files/${childId}`);
                    data = response.data


                } else {
                    const { id } = this.$route.params;
                    this.identifier = id
                    const response = await axios.get(`case_files/${id}`);
                    data = response.data
                }

                this.fileData.subjectFile.value = data.subject;
                this.fileData.instructor.value = {name: data?.instructor?.name}
                this.fileData.categories.value = { name: data.category.name };
                this.fileData.state.value = { name: data.state.name }
                this.fileData.department.value = { name: data.department.name }
                this.fileData.priority.value = { name: this.changePriorityNumberForText(data.priority) }
                this.fileData.report.value = data.description




            } catch (error) {
                this.isGetDataError = true;
                console.error('Error:', error.response ? error.response.data : error.message);
            }
        },
        async editCaseFile() {
            if (this.checkAllFieldsFilled()) return
            let url = ''
            if (this.isSubCase) {
                url = `/sub_case_files/${this.identifier}`
            } else {
                url = `case_files/${this.identifier}`

            }
            const dataPatch = this.formatedData()
            try {
                await axios.patch(url, dataPatch, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                this.showInfoMessage()

            } catch (error) {
                this.showErrorMessage()
                console.error('Error:', error.response ? error.response.data : error.message);
            } 
            finally {
                this.scrollToTop()
            }
        },
        async loadData() {
            this.isLoading = true;
            try {
                await this.getData();
                if (this.isEdit) {
                    await this.updateFileData();
                }
            }
            finally {
                this.isLoading = false;
            }
        },
        formatedData() {
            const dataPost = {}

            dataPost.subject = this.fileData.subjectFile.value
            dataPost.category = this.fileData.categories.value['@id']
            dataPost.priority = this.convertPriorityNameToNumber(this.fileData.priority.value.name)
            dataPost.state = this.fileData.state.value['@id']
            dataPost.department = this.fileData.department.value['@id']
            dataPost.instructor = this.fileData.instructor.value['@id']
            dataPost.description = this.fileData.report.value
            dataPost.company = `/api/v1/companies/${this.userCompany}`


            if (this.isSubCase) {
                dataPost.parentCaseFile = `/api/v1/case_files/${this.parentIdentify}`
            }

            //TO DO hay que hacer algo con el array de files

            return dataPost

        },
        checkAllFieldsFilled() {
            let isBlanck = false
            //Comprobamos que todos los campos están rellenados
            for (let key in this.fileData) {
                if (this.fileData[key].value === '') {
                    isBlanck = true
                    this.fileData[key].error = true
                }
            }
            return isBlanck
        },
        async addCaseFile() {
            this.infoMessage.count = 0
            this.infoMessage.isActive = false

            if (this.checkAllFieldsFilled()) return

            const dataPost = this.formatedData()


            try {
                if (this.isSubCase) {
                    await axios.post(`/sub_case_files`, dataPost);
                } else {
                    await axios.post(`/case_files`, dataPost);
                }
                this.clearData()
                this.infoMessage.isActive = true
            } catch (error) {
                this.showErrorMessage()
                console.log(error)
            }
            finally {
                this.scrollToTop()
            }
        }

    },
    created() {
        this.loadData();
    },
};
</script>



<style scoped lang="scss">
.error {
    border-color: #C74D4D !important;
}

.info-message {
    margin-top: 20px;
}

.active {
    opacity: 1;
}

.notActive {
    opacity: 0;
}






.error-message {
    text-align: left;
    color: #C74D4D;
    padding-top: 6px;
    font-size: 14px;
    font-family: 'InterRegular';
}

.modal {

    margin-top: 20px;
    display: flex;
    justify-content: center;
    height: 100vh;

    width: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
   


}

.modal div {
    height: fit-content;
    max-width: 497px;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
        0 6px 20px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    border-radius: 8px;
    border: none;
    padding: 24px;

    h1 {
        color: #0E0E0F;
        font-size: 18px;
        line-height: 24.2px;
        text-align: left;

    }

    li {
        color: #C74D4D;
        list-style: none;
        text-align: left;
        font-size: 16px;
        font-family: 'InterRegular', sans-serif;
    }

    @media (max-width: 400px) {
        h1 {
            font-size: 16px;
        }

        li {
            font-size: 14px;
        }
    }


}

.get-data-error {
    margin-top: 20px;
    height: 100vh;

    @media (max-width: 768px) {
        padding: 24px;
    }

}

.center {
    width: 100%;
    display: flex;
    justify-content: center;
}




.files {
    position: relative;
    width: calc(100% - 24px);
    margin: 0 10px 0 16px;
    border-top: none;
    border-radius: 0 0 7.03px 7.03px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    overflow-y: scroll;
    max-height: 75px;

}


.template {
    position: relative;
}




.grid {
    background-color: #fff;
    margin-top: 20px;
    border-radius: 8px;
    padding: 32px 32px 141px 24px;
    display: grid;
    grid-template-columns: minmax(200px, 30%) minmax(200px, 30%) minmax(300px, 39%);
    grid-template-rows: 90px;
    grid-auto-rows: 90px;
    gap: 24px 0;
   
}

.grid  > * {
      grid-row: auto;
    }


.colum1 {
    grid-column: 1 / 2;
    margin-right: 31px;
}

.colum2 {
    grid-column: 2 / 3;
    margin-right: 48px;
}

.estado-prioridad {
    display: flex;

    label:first-child {
        width: 100%;
    }
}

.priority {
    width: 130px;
}

input,
button,
.pointer {
    cursor: pointer;
}

p {
    text-align: left;
    margin-bottom: 8px;
    color: #707C7E;
    font-size: 14px;
    font-family: 'InterMedium';

}



.colum3 {
    grid-column: 3 / 4;
    grid-row: 1 / 4;
    display: flex;
    flex-direction: column;


    .textarea-container {
        flex-grow: 1;
        width: 100%;
        border: 1.25px solid #B3BBBC;
        border-radius: 8px;
        padding: 14px 8px;
        display: flex;
        flex-direction: column;
    justify-content: space-between;    }

    .textarea-container:focus-within {
        border: 2px solid #0E0E0F;
    }

    textarea {
        width: 100%;
        padding: 4px 15px;
        color: #0E0E0F;
        font-size: 16px;
        font-family: 'InterRegular';
        line-height: 20.8px;
        border: none;
        border-radius: 8px;
        resize: none;
        outline: none;
        height: 80%;
    }
}

.colum4 {
    grid-column: 3 / 4;
    grid-row: 4 / 5;
    padding-top: 24.100px;

    button {
        height: 48px;
        font-family: 'InterMedium';
        font-weight: 500;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        gap: 5px;

        button,
        .file {
            border: none;
            background-color: #CBDEE2;
            border-radius: 8px;
            color: #163F49;
            line-height: 19.36px;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;

            label {
                padding: 14px;
            }
        }

        button {
            padding: 14px;
        }
    }

    .file,
    .file>label {
        display: flex;
        align-items: center;
    }

    .file>label {
        gap: 8px;
        color: #163F49;
        line-height: 19.36px;
        font-size: 16px;
        font-family: 'InterMedium';
        font-weight: 500;
    }

    .fileTextHiden {

        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        border: 0;
    }

    .sumbit-button-container {
        margin-top: 38px;
        display: flex;
        justify-content: flex-end;

        button {
            background-color: #163F49;
            border-radius: 4px;
            line-height: 21.78px;
            text-align: center;
            width: 305px;
            border: none;
            color: #F9FBFC
        }
    }


}



@media (max-width: 1285px) {

    .grid {
        display: grid;
        grid-template-columns: minmax(200px, 50%) minmax(200px, 50%);
        grid-template-rows: auto;
        grid-auto-rows: auto;
    }


    .colum1 {
        grid-column: 1 / 2;
        margin-right: 31px;
    }



    .colum2 {
        grid-column: 2 / 3;
        margin-right: 0px;
    }


    .colum3 {
        grid-column: 1 / -1;
        grid-row: 5 / 6;
        display: flex;
        flex-direction: column;

        .textarea-container {
        flex-grow: 1;
        width: 100%;
        border: 1.25px solid #B3BBBC;
        border-radius: 8px;
        padding: 14px 8px;
        display: flex;
        flex-direction: column;
    justify-content: space-between;    }
    height: 275px;

    .textarea-container:focus-within {
        border: 2px solid #0E0E0F;
    }

    textarea {
        width: 100%;
        padding: 4px 15px;
        color: #0E0E0F;
        font-size: 16px;
        font-family: 'InterRegular';
        line-height: 20.8px;
        border: none;
        border-radius: 8px;
        resize: none;
        outline: none;
        height: 80%;
    }
       

    }

    .colum4 {
        grid-column: 1 / -1;
        grid-row: 6 / 7;
        padding-top: 24.100px;
    }


}


@media (max-width: 1200px) {
 

    .title {

        margin-top: 25.49px;
    }

    .grid {
        margin-top: 32px;
    }
}

@media (max-width: 800px) {

    .grid {
        display: flex;
        flex-direction: column;
        padding: 44px 24px 64px 24px;
        margin-top: 16px;


    }

    .colum1,
    .colum2,
    .colum3,
    .colum4 {
        margin-right: 0;
    }

    .estado-prioridad {
        display: flex;
        flex-direction: column;
        gap: 24px;

       
    }

    .colum4 {
        .sumbit-button-container {
            margin-top: 38px;
            display: flex;
            justify-content: flex-end;

            button {
                width: 100%;

            }
        }
    }
}



@media (max-width: 768px) {
    .title {
        padding-left: 24px;
    }

    .grid {
        margin-left: 12px;
    }

    .info-message {
        margin-top: 20px;
        margin-left: 12px;
    }

}


@media (max-width: 450px) {
    .colum4 {
        .button-container {

            gap: 8px;
            flex-direction: column;
            justify-content: center;



            input,
            button,
            label {
                font-size: 12px !important;

            }

            .file {
                label {
                    margin-inline: auto;
                }
            }
        }
    }

}
</style>