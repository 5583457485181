<template>

    <CreateAndEditLayaout :name="name" :path="path" :isEdit="true" :breadcrumbs="breadcrumbs" />

</template>


<script>
import CreateAndEditLayaout from '@/components/general/CreateAndEditLayaout.vue'
import { useRoute } from 'vue-router';
import { mapMutations } from 'vuex';

export default {
    name: 'EditCategory',
    components: { CreateAndEditLayaout },
    data() {
        return {
            name: {
                button: 'Editar Categoría',
                title: 'Editar Categoría'
            },
            path: '/categories',
            breadcrumbs: [{ label: 'Categorías', to: '/categorias' }, { label: 'Editar Categoría', to: `` }],
        }
    },
    methods: {
        ...mapMutations(['toggleUserCompanyDisable']),
    },
    mounted() {
        this.toggleUserCompanyDisable()
        const router = useRoute();
        const { id } = router.params;
        this.identifier = id
        this.breadcrumbs[1].to = `/categorias/editar-categoria/${id}`
    },
    beforeUnmount() {
        this.toggleUserCompanyDisable()
    },

}
</script>
