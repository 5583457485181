<template>

    <LayaoutTableDinamic :tableInfoMessage="tableInfoMessage" :deleteModalText="deleteModalText"
        :buttonTitle="buttonTitle" title="Usuarios" :breadcrumbs="this.breadcrumbsList" path="/users"
        :tableColumnsData="tableColumnsData" :pendingRegistration="true" :secondaryButtonData="secondaryButtonData" :secondaryButton="true" editPath="/users/edit-user" />

</template>


<script>
import LayaoutTableDinamic from '../users/LayaoutTableDinamic.vue';



export default {
    name: 'UsersComponent',
    components: {
        LayaoutTableDinamic
    },

    data() {
        return {
            breadcrumbsList: [
                { label: 'Usuarios', to: '/users' },
            ],
            secondaryButtonData: {
                link: '/users/manage-instructors',
                title: 'Gestionar Instructores'
            },
            buttonTitle: {
                create: 'Crear nuevo usuario',
                linkToCreate: '/users/create-user/usuario',
                delete: 'Eliminar usuarios'
            },
            deleteModalText: {
                title: '¿Desea eliminar los usuarios seleccionados?',
                namePlural: 'usuario',
                textSingular: 'Se eliminará el usuario seleccionado',
                errorSingular: 'No se puede eliminar el usuario seleccionado porque se está usando',
                errorPlural: 'No se han podido eliminar algunos de los usuarios seleccionados porque están usandose'
            },
            tableInfoMessage: {
                notCreate: 'No hay usuarios creados',
                notSearch: 'No se encontraron usuarios'
            },
            tableColumnsData: {
                desk: {
                    columnTitle: [{ title: 'Nombre', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }, { title: 'Email', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }, { title: 'Rol', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['name', 'email', 'userType'],
                    titleDetailsRowName: [],
                    //Tiene que hacer match con los datos
                    detailsRowName: [],
                },
                mobile: {
                    columnTitle: [{ title: 'Nombre', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha' },],
                    //Tiene que hacer match con los datos
                    mainRowName: ['name'],
                    titleDetailsRowName: ['Email', 'Rol'],
                    //Tiene que hacer match con los datos
                    detailsRowName: ['email', 'userType'],
                }
            }
        }
    },
}
</script>
