import { createRouter, createWebHistory } from 'vue-router'
import AdminDashboard from '@/components/panel/AdminDashboard.vue'
import InformantDashboard from '@/components/panel/InformantDashboard.vue'
import CaseFiles from '@/components/panel/CaseFiles.vue'
import CaseFilesEdit from '@/components/panel/CaseFilesEdit.vue'
import CaseFileDetails from '@/components/panel/CaseFileDetails.vue'
import SubCaseFile from '@/components/panel/SubCaseFile.vue'
import SubCaseFileNew from '@/components/general/SubCaseFileNew.vue'
import SubCaseFileEdit from '@/components/general/SubCaseFileEdit.vue'
import Companies from '@/components/panel/Companies.vue'
import NewCompany from '@/components/panel/NewCompany.vue'
import ComplaintForm from '@/components/panel/ComplaintForm.vue'
import Estadisticas from '@/components/panel/Estadisticas.vue'
import Configuration from '@/components/panel/Configuration.vue'
import Notificaciones from '@/components/panel/Notificaciones.vue'
import Categorias from '@/components/panel/Categories.vue'
import NewComplaintForm from '@/components/panel/NewComplaintForm.vue'
import NewCategory from '@/components/panel/NewCategory.vue'
import Users from '@/components/panel/Users.vue'
import NewUser from '@/components/panel/NewUser.vue'
import EditProfile from '@/components/users/EditProfile.vue'
import Instructors from '@/components/panel/Instructors.vue'
import NewInstructor from '@/components/panel/NewInstructor.vue'
import DocModels from '@/components/panel/DocModels.vue'
import NewDocModel from '@/components/panel/NewDocModel.vue'
//import SuperAdmin from '@/components/panel/SuperAdmin.vue'
import NewProceeding from '@/components/panel/NewProceeding.vue'
import store from '@/store'
import Logs from '@/components/panel/Logs.vue'
import State from '@/components/panel/State.vue'
import NewState from '@/components/panel/NewState.vue'
import EditState from '@/components/panel/EditState.vue'
import EditCategory from '@/components/panel/EditCategory.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/HomeView.vue'),
    children: [
      {
        path: "/",
        redirect: "/register",
      },
    ],
  },
  {
    path: "/",
    name: "register",
    component: () => import("../views/home/HomeView.vue"),
    children: [
      {
        path: "/register",
        name: "register",
        component: () => import("../components/home/RegisterContent.vue"),
        meta: { title: "Registro - DEDAVARA" },
      }
    ]
  },
  {
    path: '/',
    name: 'auth',
    component: () => import('../views/auth/AuthView.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../components/forms/LoginForm.vue'),
        meta: { title: 'Login - DEDAVARA' },
      },
      {
        path: "register/informant",
        name: "register-informant",
        component: () =>
          import("../components/forms/InformantRegisterForm.vue"),
        meta: { title: "Registro - DEDAVARA" },
      },
      {
        path: 'register/business',
        name: 'register-business',
        component: () => import('../components/forms/BusinessRegisterForm.vue'),
        meta: { title: 'Registro - DEDAVARA' },
      },
      {
        path: 'password/change-request',
        name: 'request-password-change',
        component: () => import('../components/forms/RecoverPasswordForm.vue'),
        meta: { title: 'Solicitud cambio de contraseña - DEDAVARA' },
      },
      {
        path: 'password/change',
        name: 'change-password',
        component: () => import('../components/forms/ChangePasswordForm.vue'),
        meta: { title: 'Cambio de contraseña - DEDAVARA' },
      },
      {
        path: 'register/confirm',
        name: 'confirm-register',
        component: () => import('../components/forms/ConfirmRegisterForm.vue'),
        meta: { title: 'Confirmación de registro - DEDAVARA' },
      },
      {
        path: 'mfa/confirm',
        name: 'confirm-mfa',
        component: () => import('../components/forms/MFAAuth.vue'),
        meta: { title: 'Doble verificación - DEDAVARA' },
      },
      {
        path: "register/complete",
        name: "complete-register",
        component: () => import("@/components/forms/CompleteRegister.vue"),
        meta: { title: "Completa el registro - DEDAVARA" },
      }
    ],
  },
  {
    path: '/',
    name: 'generalPanel',
    component: () => import('../views/panel/MainView.vue'),
    meta: { title: 'Dashboard - DEDAVARA', breadcrumb: 'Panel' },
    children: [
      {
        path: 'dashboard',
        component: AdminDashboard,
        beforeEnter: (to, from, next) => {
          if (store.getters.getRoles.includes('ROLE_INFORMANT')) {
            next({ path: '/informant-dashboard' });
          } else {
            next();
          }
        },
        meta: { title: 'Dashboard - DEDAVARA', breadcrumb: 'Dashboard' },
      },
      {
        path: 'informant-dashboard',
        component: InformantDashboard,
        meta: { title: 'Dashboard - DEDAVARA', breadcrumb: 'Dashboard',requiresAuth:true, rol: 'ROLE_INFORMANT' },
      },
      {
        path: 'expedientes',
        component: CaseFiles,
        meta: { title: 'Expedientes - DEDAVARA', breadcrumb: 'Expedientes' },
      },
      {
        path: '/expedientes/:id',
        name: 'case-file-details',
        component: CaseFileDetails,
        meta: { title: 'Detalles del Expediente - DEDAVARA',  breadcrumb: 'Expedientes' },
      },
      {
        path: 'expedientes/create-proceeding',
        component: NewProceeding,
        meta: { title: 'Crear expediente - DEDAVARA' },
      },
      {
        path: 'expedientes/editar-expediente/:id',
        component: CaseFilesEdit,
        meta: { title: 'Editar expediente - DEDAVARA' },
      },
      {
        path: 'expedientes/estados',
        component: State,
        meta: {title: 'Estados de expedientes - DEDAVARA'}
      },
      {
        path: 'expedientes/estados/crear-estado',
        component: NewState,
        meta: {title: 'Crear Estados de expedientes - DEDAVARA'}
      },
      {
        path: 'expedientes/estados/editar-estados/:id',
        component: EditState,
        meta: {title: 'Editar Estados de expedientes - DEDAVARA'}
      },
      {
        path: 'expedientes/sub-expediente/:id',
        component: SubCaseFile,
        meta: {title: 'SubExpedientes - DEDAVARA'}
      },
      {
        path: 'expedientes/sub-expediente/:id/crear-subexpediente',
        component: SubCaseFileNew,
        meta: {title: 'Crear SubExpedientes - DEDAVARA'}
      },
      {
        path: 'expedientes/sub-expediente/:parentID/editar-subexpediente/:childId',
        component: SubCaseFileEdit,
        meta: {title: 'Editar SubExpedientes - DEDAVARA'}
      },
      {
        path: 'companies',
        name: 'companies',
        component: Companies,
        meta: { title: 'Empresas - DEDAVARA' },
      },
      {
        path: 'companies/create-company',
        component: NewCompany,
        name: 'newCompany',
        meta: { title: 'Crear Empresa - DEDAVARA' },
      },
      {
        path: 'companies/edit-company/:company',
        name: 'editCompany',
        component: NewCompany,
        meta: { title: 'Editar Empresa - DEDAVARA' },
      },
      {
        path: 'account/notifications',
        component: Notificaciones,
        meta: { title: 'Notificaciones - DEDAVARA' },
      },
      {
        path: 'categorias',
        component: Categorias,
        meta: { title: 'Categorías - DEDAVARA' },
      },
      {
        path: 'categorias/crear-categoria',
        component: NewCategory,
        meta: { title: 'Crear categoría - DEDAVARA' },
      },
      {
        path: 'categorias/editar-categoria/:id',
        component: EditCategory,
        meta: { title: 'Editar categoría - DEDAVARA' },
      },
      {
        path: 'stadistics',
        component: Estadisticas,
        meta: { title: 'Estadísticas - DEDAVARA' },
      },
      {
        path: 'complaint-forms',
        component: ComplaintForm,
        meta: { title: 'Formulario de denuncias - DEDAVARA' },
      },
      {
        path: 'complaint-forms/create-form',
        component: NewComplaintForm,
        meta: { title: 'Nuevo formulario de denuncias - DEDAVARA' },
      },
      {
        path: '/complaint-forms/edit-form/:id',
        component: NewComplaintForm,
        meta: { title: 'Editar formulario de denuncias - DEDAVARA' },
      },
      {
        path: 'users',
        component: Users,
        meta: { title: 'Usuarios - DEDAVARA' },
      },
      {
        path: 'users/create-user/:instructor',
        component: NewUser,
        meta: { title: 'Nuevo Usuario - DEDAVARA' },
      },
      {
        path: 'users/edit-user/:user',
        component: NewUser,
        meta: { title: 'Editar Usuario Usuario - DEDAVARA' },
      },
      {
        path: 'editar-perfil/:user/:profile',
        component: EditProfile,
        meta: { title: 'Editar perfil - DEDAVARA' },
      },
      {
        path: 'users/manage-instructors',
        component: Instructors,
        meta: { title: 'Instructores - DEDAVARA' },
      },
      {
        path: 'users/manage-instructors/create-instructor',
        component: NewInstructor,
        meta: { title: 'Nuevo Instructor - DEDAVARA' },
      },
      {
        path: 'users/manage-instructors/edit-instructor/:instructor',
        component: NewInstructor,
        meta: { title: 'Editar Instructor - DEDAVARA' },
      },
      {
        path: 'config',
        component: Configuration,
        meta: { title: 'Configuración - DEDAVARA' },
      },
      {
        path: 'doc-models',
        component: DocModels,
        meta: { title: 'Modelos Documentales - DEDAVARA' },
      },
      {
        path: 'doc-models/new-docmodel',
        component: NewDocModel,
        meta: { title: 'Nuevo Modelo Documental - DEDAVARA' },
      },
      {
        path: 'logs',
        component: Logs,
        meta: { title: 'Logs - DEDAVARA' },
      },
      /*
      {
        path: 'superadmin',
        component: SuperAdmin,
        meta: { title: 'Super Admin - DEDAVARA' },
      },
      */
    ]
  },
  {
    path: '/complaint-process',
    name: 'complaint-process',
    component: () => import('../views/complaint-process/ComplaintProcess.vue'),
    children: [
      {
        path: '',
        name: 'complaint-process',
        component: () => import('../components/complaint-process/ComplaintProcess0.vue'),
        meta: { title: 'Realización de denuncia - DEDAVARA' },
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (to.path !== from.path) {
      //TO DO: Cambiar esto por una mejor practica ver como limpiarlo
    document.documentElement.scrollTop = document.body.scrollTop =  0;
    }
  }
});

router.beforeEach(async(to, from, next) => {
  document.title = to.meta.title || "DEDAVARA";
  
  try {
    // Iterar sobre las rutas coincidentes
    //* Esto es para cargar las traducciones de las interfaces que se visitan y para cargar las traducciones generales
    for (const record of to.matched) {
      if (!store.getters.isThisInterfaceVisited(record.name)) {
        await store.dispatch('fetchTranslations', { view: record.name });
        store.dispatch('setInterfaceVisited', record.name);
      }
    }
  } catch (error) {
    console.error('Error fetching translations:', error);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthorized) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      if (to.meta.rol) {
        if (store.getters.getRoles.includes(to.meta.rol)) {
          next();
        } else {
          next({ path: '/dashboard' });
        }
      } else {
        next();
      }
    }
  } else {
    if ((to.path === '/login' || to.path === '/register') && store.getters.isAuthorized) {
      next({ path: '/dashboard' });
    } else {
      next();
    }
  }
});

export default router;
