<script>
import DataBox from '@/components/dashboard/DataBox.vue';
import FileTable from '@/components/dashboard/FileTableProgress.vue';
import Breadcrumb from '@/components/general/Breadcrumb.vue';

export default {
    name: 'InformantDashboardComponent',
    components: {
        DataBox,
        FileTable,
        Breadcrumb,
    },

    watch: {
        $route(to) {
            this.currentRoute = to.name;
        }
    },

    data() {
        return {
            currentRoute: '',
            tableColumns: {
                code: 'Código',
                subject: 'Asunto',
                category: 'Categoría',
                createdAt: 'Fecha de creación',
                state: 'Estado'
            },

            caseFiles: [
                {
                    "code": "EX000001",
                    "subject": "Denuncia contra el vicepresidente por presunta corrupción en la adjudicación de contratos públicos.",
                    "category": "Lucha Contra El Blanqueo De Capital y Financiamiento Del Terrorismo en Entidades Financieras.",
                    "createdAt": "02/04/2024",
                    "state": "En curso",
                    "progress": 50
                },
                {
                    "code": "EX000002",
                    "subject": "Investigación sobre evasión fiscal en una empresa multinacional.",
                    "category": "Fraude Fiscal y Delitos Tributarios.",
                    "createdAt": "03/04/2024",
                    "state": "Nuevo",
                    "progress": 70
                },
                {
                    "code": "EX000003",
                    "subject": "Caso de trata de personas en una red internacional.",
                    "category": "Trata de Personas y Tráfico de Migrantes.",
                    "createdAt": "04/04/2024",
                    "state": "En curso",
                    "progress": 20
                },
                {
                    "code": "EX000004",
                    "subject": "Fraude en la comercialización de productos farmacéuticos.",
                    "category": "Delitos contra la Salud Pública.",
                    "createdAt": "05/04/2024",
                    "state": "Cerrado",
                    "progress": 30
                },


            ],
        }
    },

}
</script>


<template>
    <div class="dashboard">


        <!--Breadcrumb-->
        <Breadcrumb />

        <h1>Expedientes</h1>

        <!--Data Boxes-->
        <div class="data-boxes">

            <DataBox title="Expedientes en curso" sign="-" :periodNumber="12" period="Esta semana" :total="18"
                status="inProgress" />

            <DataBox title="Expedientes Cerrados" sign="+" :periodNumber="32" period="Esta semana" :total="120"
                status="Closed" />
        </div>

        <div class="wrapper">
            <!--File table-->
            <div class="table-data">
                <div class="file-table">
                    <FileTable :columnConfig="tableColumns" :caseFiles="caseFiles" />
                </div>

            </div>
        </div>

    </div>
</template>



<style scoped lang="scss">
.dashboard {
    width: 100%;

    padding: 0 44px 0 78px;



    .breadcrumb {
        margin-top: 36px;
    }

    h1 {
        text-align: left;
        margin: 25px 0 24px 0;
        font-size: 30px;
    }

    .data-boxes {
        width: 100%;
        display: inline-flex;
        margin-bottom: 24px;

        .data-box {
            width: 342px;
            margin-right: 48px;
        }

        .data-box:last-child {
            margin-right: 0;
        }

    }

    .table-data {
        width: 100%;

        display: inline-flex;




        .file-table {
            width: 100%;
            margin-right: 32px;
            border-radius: 10px;



            h2 {
                text-align: left;
                font-size: 16px;
                font-family: 'interSemiBold', sans-serif;
                color: #B3BBBC;
            }



        }


    }


    .router-link {
        color: #55737A;
        font-family: 'InterMedium', sans-serif;
        font-size: 12px;
        margin-top: 18px;
        display: block;
        text-align: left;

    }
}


/*Media queries*/
@media(max-width: 1720px) {
    .dashboard {
        padding: 0 44px 0 44px;

        .data-boxes .data-box {
            width: 33.33%;
        }

        .table-data {
            flex-wrap: wrap;
            max-height: calc(100% - 150px);
            overflow-y: auto;
            /* Agrega desplazamiento vertical solo si es necesario */


            .file-table {
                width: 100%;
                margin: 0;

                table {
                    max-height: 250px;
                    width: 100%;
                }

            }

            .widgets {
                width: 100%;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                margin-top: 25px;

                .widget {
                    width: 33.33%;
                    margin: 0;
                    margin-right: 32px;
                }


                .widget:last-child {
                    margin-right: 0;
                }
            }

        }

        .charts .chart {
            width: 50%;

        }

        .charts .chart:last-child {
            margin-right: 0;
        }
    }

}



@media(max-width: 1400px) {
    .dashboard {
        padding: 0 0 0 25px;
    }
}


@media(max-width: 1200px) {
    .dashboard {
        padding: 0;
    }
}



@media(max-width: 768px) {
    .dashboard {


        .breadcrumb {
            display: none;
        }

        h1 {
            padding: 0 24px;
            font-size: 24px;
            margin: 0 0 43px 0;
        }

        .data-boxes {
            flex-wrap: wrap;
            padding: 0 24px;
            margin-bottom: 0;

            .data-box {
                width: 47%;
                margin-right: 12px;
                margin-bottom: 24px;


            }

            .widget.assigned {
                padding: 0;
            }

            .widget {
                background: #FFFFFF;
                width: 47%;
                border-radius: 8px;
                flex-direction: inline-flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 16px;

                h2 {
                    font-size: 14px;
                    text-align: left;
                    font-family: 'interSemiBold', sans-serif;
                    color: #B3BBBC;

                    width: 100%;

                }

                p {
                    font-size: 14px;
                    display: inline;
                    font-family: 'interSemiBold', sans-serif;
                    text-align: left;
                }

                span.number {
                    display: inline;
                    font-size: 24px;
                    font-family: 'interSemiBold', sans-serif;
                    margin-left: 5px;


                }

            }

            .widget {
                display: flex;
                width: 47%;
                margin-right: 12px;
                margin-bottom: 12px;

                .data-box {
                    width: 100%;
                }
            }

        }

        .wrapper {
            display: flex;
            flex-direction: column-reverse;
        }

        .table-data {
            padding: 0 24px;


            .widgets {
                display: none;
            }

            .table {
                min-height: 600px;
            }
        }


        .charts {
            width: 100%;
            padding: 0 16px;
            margin-top: 24px;
            flex-wrap: wrap;

            .chart {
                max-width: 100%;
                width: 100%;
                margin-right: 0;
            }

            .chart:first-child {
                margin-bottom: 13px;
            }

        }

        .router-link {
            text-align: center;
        }
    }
}


@media(max-width:576px) {

    .dashboard {
        .data-boxes {
            justify-content: space-between;

            .data-box,
            .widget {
                width: 45%;
            }
        }


    }
}

@media(max-width:400px) {

    .dashboard {
        .data-boxes {


            .data-box,
            .widget {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}
</style>