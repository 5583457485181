<template>

    <CreateAndEditLayaout :name="name" :path="path" :isEdit="true" :breadcrumbs="breadcrumbs" :isState="true" />

</template>


<script>
import CreateAndEditLayaout from '@/components/general/CreateAndEditLayaout.vue'
import { useRoute } from 'vue-router';
import { mapMutations } from 'vuex';

export default {
    name: 'EditState',
    components: { CreateAndEditLayaout },
    data() {
        return {
            name: {
                button: 'Editar Estado',
                title: 'Editar Estado'
            },
            path: '/states',
            breadcrumbs: [
                { label: 'Expedientes', to: '/expedientes' },
                { label: 'Estados', to: '/expedientes/estados' },
                { label: 'Editar Estado', to: `` }],
        }
    },
    methods: {
        ...mapMutations(['toggleUserCompanyDisable']),
    },
    mounted() {
        this.toggleUserCompanyDisable()
        const router = useRoute();
        const { id } = router.params;
        this.identifier = id
        this.breadcrumbs[2].to = `/expedientes/estados/editar-estados/${id}`
    },
    beforeUnmount() {
        this.toggleUserCompanyDisable()
    },

}
</script>
