<template>
    <CaseFileCreateEditLayout :header="header" :submitButtonTitle="submitButtonTitle" :parentIdentify="indentify" :isSubCase="true"  />
</template>

<script>
import CaseFileCreateEditLayout from './CaseFileCreateEditLayout.vue';
import { useRoute } from 'vue-router';
import { mapMutations } from 'vuex';

export default {
    name: 'SubCaseFileNew',
    components: {
        CaseFileCreateEditLayout
    },
    data() {
        return {
            header: {
                title: 'Nuevo SubExpediente',
                breadcrumbs: [{ label: 'Expedientes', to: '/expedientes' }, { label: 'SubExpedientes', to: `` }, {label: 'Nuevo Subexpediente',  to: ``}],
            },
            submitButtonTitle: 'Guardar Subexpediente',
            indentify: ''
        }
    },
    methods: {
        ...mapMutations(['toggleUserCompanyDisable']),
    },
    mounted() {
        this.toggleUserCompanyDisable()
        const router = useRoute();
        const { id } = router.params;
        this.indentify = id
        this.header.breadcrumbs[1].to = `/expedientes/sub-expediente/${id}`
        this.header.breadcrumbs[2].to = `/expedientes/sub-expediente/${id}/crear-subexpediente`

    },
    beforeUnmount() {
        this.toggleUserCompanyDisable()
    },
}

</script>
