<template>
  <CaseFileCreateEditLayout :header="header" :submitButtonTitle="submitButtonTitle"   />
</template>


<script>

import CaseFileCreateEditLayout from '../general/CaseFileCreateEditLayout.vue';

export default {
    name: 'NewProceedingComponent',
    components: {
        CaseFileCreateEditLayout 
    },
    data() {
        return {
            header: {
                title: 'Nuevo Expediente',
                breadcrumbs: [{ label: 'Expedientes', to: '/expedientes' }, { label: 'Nuevo Expediente', to: '/expedientes/create-proceeding' }],
            },
            submitButtonTitle: 'Guardar Expediente',
        }
    }
}
    
</script>


<style scoped lang="scss">


</style>