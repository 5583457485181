<template>
    <div :style="estilos" class="file">
        <p>{{ file.name }}</p>
        <span>({{ formatSize(file.size) }})</span>

        <div class="svg-container">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
                @click="eliminarArchivo" v-if="remove">
                <g id="Group 39">
                    <path id="Line 37" d="M29.748 9.95288L9.94906 29.7519" stroke="#E0EBEE" stroke-linecap="round" />
                    <path id="Line 38" d="M29.041 30.3517L9.24203 10.5528" stroke="#E0EBEE" stroke-linecap="round" />
                </g>
            </svg>
        </div>



    </div>
</template>

<script>
export default {
    name: 'FileComponent',
    props: {
        file: {
            type: Object,
            required: true,
            validator(value) {
                return value.name && typeof value.size === 'number';
            }
        },

        remove: {
            type: Boolean,
            default: true
        },
        estilos: {
            type: Object
        }

    },
    methods: {
        formatSize(size) {
            const units = ['B', 'KB', 'MB', 'GB', 'TB'];
            let unitIndex = 0;
            while (size > 1024 && unitIndex < units.length - 1) {
                size /= 1024;
                unitIndex++;
            }
            return `${size.toFixed(1)} ${units[unitIndex]}`;
        },
        eliminarArchivo() {
            this.$emit('eliminar-archivo', this.file);
        }
    }
}
</script>

<style scoped lang="scss">

.svg-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
}

.file {
    box-sizing: border-box;
    background-color: #E0EBEE;
    min-width: 170px;
    height: 32px;
    border-radius: 2px;
    display: flex;
    margin: 8px 8px 0 0;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;
        text-align: left;
        color: #163F49;
        margin: 6px 0 6px 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 80px;
        font-family: "Inter", sans-serif;
    }

    span {
        font-size: 14px;
        color: #A2ABAD;
        display: inline-block;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 80px;
        overflow: hidden;
    }

    svg {
        margin: 0px 9px 0px 0px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #163F49;
        cursor: pointer;
    }
}
</style>
