<template>
    <CaseFileCreateEditLayout :header="header" :submitButtonTitle="submitButtonTitle" :isEdit="true"  />
  </template>
  
  
  <script>
  
  import CaseFileCreateEditLayout from '../general/CaseFileCreateEditLayout.vue';
  import { useRoute } from 'vue-router';
  import { mapMutations } from 'vuex';
  export default {
      name: 'CaseFilesEdit',
      components: {
          CaseFileCreateEditLayout 
      },
      data() {
          return {
              header: {
                  title: 'Editar Expediente',
                  breadcrumbs: [{ label: 'Expedientes', to: '/expedientes' }, { label: 'Editar Expediente', to: '' }],
              },
              submitButtonTitle: 'Editar Expediente',
              isEdit: true
          }
      } ,
      methods: {
        ...mapMutations(['toggleUserCompanyDisable']),
    },
    mounted() {
        this.toggleUserCompanyDisable()
        const router = useRoute();
        const { id } = router.params;
        this.identifier = id
        this.header.breadcrumbs[1].to = `/expedientes/editar-expediente/${id}`

    },
    beforeUnmount() {
        this.toggleUserCompanyDisable()
    },
  }
      
  </script>
  
  
  <style scoped lang="scss">
  
  
  </style>