<template>
    <div  class="input-search" :class="!maxWidthNone && 'maxWidth'">
        <input type="search" :placeholder="this.placeholder" :value="this.search"
            @input="debounce(() => { this.search = $event.target.value })">
        <img src="../../assets/icons/search.svg">
    </div>
</template>

<script>
export default {
    name: 'InputSearch',
    props:{
        placeholder: {
            type: String,
            default: 'Buscar...'
        },
        maxWidthNone: {
            type: Boolean,
            default: false
        }
        
    },
    data() {
        return {
            search: '',
        };
    },
    methods: {
    },
    watch: {
        search: function () {
            if (typeof this.search === 'string') {
                this.$emit('search', this.search);
            }
        },
    },
    setup() {
        // Funcion de delay para el input
        function createDebounce() {
            let timeout = null;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }
        return {
            debounce: createDebounce()
        }
    }

}
</script>



<style scoped lang="scss">

.maxWidth{
    max-width: 408px;
}

.input-search {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    img {
        position: absolute;
        left: 14px;
    }
}

input[type="search"] {
    height: 38px;
    background: #FFFFFF;
    border: none;
    border-radius: 8px;
    width: 100%;
    padding: 10px 41px;
    border: 1px solid #E7F0F2;
    font-family: 'InterRegular', sans-serif;


}


input[type="search"]::placeholder {
    color: #A2ABAD;
}
</style>