<template>
  <CaseFileLayaout :titleName="titleName" :createbutton="createbutton" :pathData="pathData" :breadcrumbs="breadcrumbs"
    :dropdownData="dropdownData" :deleteModalInfo="deleteModalInfo" :isSubCase="isSubCase" :root="false" />

</template>


<script>
import CaseFileLayaout from '../general/CaseFileLayaout.vue';
import { useRoute } from 'vue-router';
import { mapMutations } from 'vuex';

export default {
  name: "SubCaseFilesComponent",
  components: {
    CaseFileLayaout
  },
  data() {
    return {
      titleName: 'Subexpedientes',
      createbutton: {
        to: ``,
        text: 'Nuevo Subexpediente'
      },
      isSubCase: true,
      pathData: {
        getData: ``,
        pathKey: ''
      },
      breadcrumbs: [{ label: 'Expedientes', to: '/expedientes' }, { label: `Subexpedientes`, to: `` }],
      deleteModalInfo: {
        deleteModalText: {
          title: '¿Desea eliminar el subexpediente seleccionado?',
          namePlural: 'expediente',
          textSingular: 'Se eliminará el subexpediente seleccionado',
          errorSingular: 'No se puede eliminar por alguna de las siguientes razones: tiene subexpedientes asociados o tiene algun nota en sus detalles'
        },
        path: "/case_files"
      }
    }
  },
  methods: {
    ...mapMutations(['toggleUserCompanyDisable']),
    dropdownData(caseID) {
      return [
        { text: 'Visualizar', href: '/expedientes/' + caseID },
        { text: 'Editar', href: '/expedientes/sub-expediente/' + this.identifier + '/editar-subexpediente/' + caseID },
        { text: 'Eliminar', data: { action: 'toggleModalSimple', id: caseID }, estilos: {color: '#C74D4D'} },
        { text: 'Subexpedientes', href: '/expedientes/sub-expediente/' + caseID }

      ]

    }
  },
  watch: {
    $route() {
      const id = this.$route.params.id
      this.identifier = id
      this.breadcrumbs[1].to = `/expedientes/sub-expediente/${id}`
      this.createbutton.to = `/expedientes/sub-expediente/${id}/crear-subexpediente`
      this.pathData.getData = `/case_files/${id}`
      this.pathData.pathKey = `subCaseFile`
    }
  },

  mounted() {
    this.toggleUserCompanyDisable()
    const router = useRoute();
    const { id } = router.params;
    this.identifier = id
    this.breadcrumbs[1].to = `/expedientes/sub-expediente/${id}`
    this.createbutton.to = `/expedientes/sub-expediente/${id}/crear-subexpediente`
    this.pathData.getData = `/case_files/${id}`
    this.pathData.pathKey = `subCaseFile`
  },
  beforeUnmount() {
    this.toggleUserCompanyDisable()
  },

}


</script>

<style scoped lang="scss"></style>