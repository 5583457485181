<template>

    <div v-if="isOpen" class="dropdown-menu" :style="estilos && estilos">
        <ul>
            <li v-for="link in links" :key="link.href">
                <router-link @click="this.$emit('link-action')" v-if="link.href !== undefined" :to="link.href" :style="link.estilos" >{{ link.text }} </router-link>
                <a :style="link.estilos" href="#" v-else @click="handleClick(link.action, link.data, link.text)">{{ link.text }} <img v-if="link.icon" :src="link.image.src" :alt="link.image.alt"></a>
                <hr  />
            </li>
        </ul>
    </div>

</template>

<script>
export default {
    name: "DropdownMenu",
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        estilos: {
            type: Object
        },
        links: {
            type: Array,
            required: true,
        },
        isDropdownMenuOnly: {
          type: Boolean,
          required: false,
          default: false
        },
        notHref: {
          type: Boolean,
          required: false,
          default: false
        },
        
    },
    methods: {
        handleClick(link, data, text) {
            this.$emit('clickedAction', {data: data})

            //Si pones esta props se ejecutara el el emit que concuede con action

            if(this.isDropdownMenuOnly) {
              if(data.action === 'giveMeText') {
                this.$emit(`${data.action}`, text)
              } else{
                this.$emit(`${data.action}`)
              }
              
            }
        }
    },
}

</script>

<style lang="scss" scoped>

.dropdown-menu {
  min-width: 116px;
  // min-height: 107px;
  position: absolute;
  border-radius: 8px;
  
  //bottom: -80px;
  //top: 0;
 // right: 0;
  background: #f9fbfc;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 6px 0px #163f494d;
  padding: 12px;
  border-radius: 8px;
  z-index: 900;
  border: none;

  ul {
    list-style: none;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    li:last-child hr {
      display: none;
    }

    
    li:last-child a {
      margin-bottom: 0;
    }

    li:first-child a {
      margin-top: 0;
    }

    

    li {
  

      hr {
        border: none;
        border-top: 1px solid #e0ebee;
      }

      a {

        color: #a2abad;
        font-size: 14px;
        margin: 8px 0;
        font-family: "InterRegular", sans-serif;
        text-align: left;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
      }

 

      a:hover {
        color: #707c7e !important;
      }
    }
  }
}



</style>