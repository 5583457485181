<template>
        <Dropdown ref="dropdown" @activeDropdownOpen="this.$emit('activeDropdownOpen')" :isEditPosition="true" @impersonal="console.log(this.identifier)" :estilos="{top: `${DropdownPositionY}px`, left: `${DropdownPositionX}px`}" :links="this.optionsCategory" @toggleModalSimple="toggleModalSimple(this.identifier)" :table="true" />
</template>


<script>
import Dropdown from '../general/Dropdown.vue';

export default {
    name: 'TdDropdown',
    data() {
        return {
            optionsCategory: [
                { text: 'Editar', href: `${this.editPath}/${this.identifier}` },
                { text: 'Eliminar', data: { action: 'toggleModalSimple', id: this.identifier }, estilos: {color: '#C74D4D'}  }
            ],
        }
    },
    components: {
        Dropdown
    },
    mounted() {
        if(this.isImpersonal) {
            this.optionsCategory = [{ text: 'Impersonal', data: { action: 'impersonal', id: this.identifier }  }, ...this.optionsCategory]
        }
    },

    props: {
        identifier: {
            type: String,
            required: true
        },
        toggleModalSimple: {
            type: Function,
        },
        editPath: {
            type: String,
            required: true
        },
        isImpersonal: {
            type: Boolean,
            required: false
        },
        DropdownPositionX: Number,
        DropdownPositionY: Number

    },
    emits: ['activeDropdownOpen'],
    methods: {
        closeDropdown(){
            this.$refs.dropdown.closeDropdown()
        }
    },
}


</script>

<style scoped>


   


.ellipses-td {
    width: 60px;
}

@media (max-width: 350px) {
    .ellipses-td {
        width: 0 !important;
    }
}
</style>