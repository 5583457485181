    <template>
        <div ref="newUser" class="new-user">

            <GeneralTitle v-if="!isMyProfile && !this.isloading"
                :title="this.editMode ? 'Editar Usuario' : 'Nuevo usuario'" :breadcrumbs="this.breadcrumbsList"
                @goBack="this.goBack()" />
            <GeneralTitle v-else title="Editar Perfil" :noBreadcrumbs="true" />

            <InfoMessage v-if="this.editMessage.active && !this.isloading" :text="editMessage.message"
                :icon="editMessage.icon" :isActive="true" :color="editMessage.color"
                :styleDiv="{ marginBottom: 0, width: '100%' }" />

            <form action="#" class="new-user__form"
                @submit.prevent="this.editMode ? this.editUser() : this.sendNewUserRequest()">

                <InfoMessage v-if="this.isErrorGet && !this.isloading"
                    text="Se ha producido un error. Consulte con el servicio técnico." icon="error" :isActive="true"
                    color="#C74D4D" :styleDiv="{ marginBottom: 0, width: '100%' }" />

                <div v-if="isloading" class="loader">
                    <LoaderComponent />
                </div>


                <div v-if="!this.isErrorGet && !isloading">
                    <div class="form__image"
                        v-if="this.userType.value === 'ROLE_SUPERVISOR' || this.userType.value === 'ROLE_INSTRUCTOR'">
                        <input ref="imageRef" type="file" class="file" @change="previewImage">
                        <img :src="this.imagePreview === null ? require('@/assets/icons/inputFile.svg') : this.imagePreview"
                            alt="Preview" class="file-preview" @click="this.$refs.imageRef.click()">
                    </div>
                </div>
                <div v-if="!this.isErrorGet && !isloading" class="form__body">
                    <div class="body__inputs">
                        <div class="inputs__select-user-type">
                            <label for="userType" class="select-user-type__label">Tipo de usuario</label>
                            <GeneralSelect :name="'userType'" :id="'userType'" :options="this.roleOptions"
                                class="inputs__user-type" @optionChanged="this.userType.value = $event"
                                :placeholder="'Selecciona el tipo de usuario'" :error="this.userType.error"
                                :errorMessage="this.userType.errorMessage" :optionSelected="this.userType.value"
                                :disabled="this.editMode" />
                        </div>
                        <div class="inputs__line">
                            <GeneralInput @update-value="this.name.value = $event" labelName="Nombre" labelFor="name"
                                inputType="text" :error="this.name.error" :errorMessage="this.name.errorMessage"
                                :vModel="this.name.value" />
                            <GeneralInput v-if="this.userType.value !== 'ROLE_COMPANY_ADMIN'"
                                @update-value="this.surnames.value = $event" labelName="Apellidos" labelFor="surnames"
                                inputType="text" :error="this.surnames.error" :errorMessage="this.surnames.errorMessage"
                                :vModel="this.surnames.value" />
                        </div>
                        <GeneralInput @update-value="this.email.value = $event" labelName="Email" labelFor="email"
                            inputType="text" :error="this.email.error" :errorMessage="this.email.errorMessage"
                            :vModel="this.email.value" :disabled="this.editMode" />
                        <GeneralInput v-if="this.userType.value === 'ROLE_AUDITOR'"
                            @update-value="this.externalCompany.value = $event"
                            labelName="Nombre de la empresa (opcional)" labelFor="externalCompany" inputType="text"
                            :error="this.externalCompany.error" :errorMessage="this.externalCompany.errorMessage"
                            :vModel="this.externalCompany.value" />

                        <GeneralInput
                            v-if="this.userType.value === 'ROLE_AUDITOR' || this.userType.value === 'ROLE_INFORMANT'"
                            @update-value="this.tlf.value = $event" labelName="Teléfono (opcional)" labelFor="tlf"
                            inputType="text" :error="this.tlf.error" :errorMessage="this.tlf.errorMessage"
                            :vModel="this.tlf.value" />
                        <div class="inputs__selects"
                            v-if="this.userType.value === 'ROLE_SUPERVISOR' || this.userType.value === 'ROLE_INSTRUCTOR'">
                            <div class="selects__input">
                                <label for="category" class="label">Departamento</label>


                                <GeneralSelect :name="'department'" :id="'department'" :options="this.departments"
                                    :error="this.department.error" :errorMessage="this.department.errorMessage"
                                    :placeholder="'Selecciona un departamento'"
                                    @optionChanged="this.department.value = $event"
                                    :optionSelected="this.department.value" />
                            </div>
                            <div class="selects__input">
                                <label for="category" class="label">Categoria</label>
                                <GeneralSelect :name="'category'" :id="'category'" :options="this.mergedCategories"
                                    :error="this.category.error" :errorMessage="this.category.errorMessage"
                                    :placeholder="'Selecciona una categoría'"
                                    @optionChanged="this.category.value = $event"
                                    :optionSelected="this.category.value" />
                            </div>
                        </div>
                        <div v-if="this.userType.value === 'ROLE_COMPANY_ADMIN' && !isMyProfile"
                            class="select-companies-admin" :class="{ 'error-companies': this.companiesToAdmin.error }">
                            <label for="" class="label">Empresas</label>
                            <VueMultiselect v-model="this.companiesToAdmin.value" :options="this.companies"
                                :multiple="true" :show-labels="true" :close-on-select="false"
                                placeholder="Elige las empresas" label="label" track-by="value"
                                :no-results-text="'No se encontraron opciones'" @search-change="this.getCompanies"
                                selectLabel="" :clear-on-select="false" :open-direction="'bottom'"
                                :deselectLabel="'Presione enter para deseleccionar'">
                                <template v-slot:noOptions>
                                    No se encontraron empresas
                                </template>
                                <template v-slot:noResult>
                                    No se encontraron empresas
                                </template>
                            </VueMultiselect>
                            <span v-if="this.companiesToAdmin.error" class="error">{{ this.companiesToAdmin.errorMessage
                                }}</span>
                        </div>

                        <!-- cambio de contraseña para perfil propio-->
                        <!-- TO DO: poner errores y de más cuando podamos ver como comprobar la anterior-->
                        <div v-if="isMyProfile" class="password-container">
                            <GeneralInput v-if="isMyProfile" @update-value="this.password.value = $event"
                                labelName="Contraseña actual" labelFor="password" inputType="password"
                                :error="this.password.error" :errorMessage="this.password.errorMessage"
                                :vModel="this.password.value" />
                            <GeneralInput v-if="isMyProfile" @update-value="this.newPassword.value = $event"
                                labelName="Nueva constraseña" labelFor="newPassword" inputType="password"
                                :error="this.newPassword.error" :errorMessage="this.newPassword.errorMessage"
                                :vModel="this.newPassword.value" />
                            <div class="repeatPassword">
                                <GeneralInput v-if="isMyProfile" @update-value="this.repeatPassword.value = $event"
                                    labelName="Nueva constraseña" labelFor="repeatPassword" inputType="password"
                                    :error="this.repeatPassword.error" :errorMessage="this.repeatPassword.errorMessage"
                                    :vModel="this.repeatPassword.value" />
                            </div>
                            <div class="forgotten-password">
                                <router-link to="/password/change-request" class="router-recover-password">Pincha aquí
                                    si has olvidado tu
                                    contraseña y quieres crear una nueva</router-link>
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                    <div class="body__buttons">
                        <button v-if="!this.loading" type="submit" class="submit-button">{{ this.editMode ?
                            'Editar' : 'Enviar solicitud'
                            }}</button>
                        <button v-else type="button" class="submit-button">
                            <VueSpinner size="24" color="#FFFFFF"></VueSpinner>
                        </button>
                    </div>
                </div>
            </form>
            <div class="sent-request" v-if="this.success.value && !this.isErrorGet && !isloading" ref="response">
                <h2>{{ this.success.title }}</h2>
                <p>{{ this.success.message }}</p>
                <img class="background-logo" src="@/assets/logos/Isotipo - Color.svg">
            </div>
            <div class="sent-request" v-if="this.errorRequest.value && !this.isErrorGet && !isloading" ref="response">
                <h2>{{ this.errorRequest.title }}</h2>
                <p>{{ this.errorRequest.message }}</p>
                <img class="background-logo" src="@/assets/logos/Isotipo - Color.svg">
            </div>

        </div>

    </template>

<script>
import axios from 'axios';
import VueMultiselect from 'vue-multiselect';
import GeneralInput from "@/components/forms/Input.vue";
import GeneralSelect from "@/components/forms/Select.vue";
import GeneralTitle from '@/components/general/generalTitle.vue';
import { VueSpinner } from 'vue3-spinners';
import { mapGetters } from 'vuex';
import InfoMessage from '../general/InfoMessage.vue';
import LoaderComponent from '../general/LoaderComponent.vue';

export default {
    name: 'NewUser',
    components: {
        VueMultiselect,
        GeneralInput,
        GeneralSelect,
        GeneralTitle,
        VueSpinner,
        InfoMessage,
        LoaderComponent
    },
    data() {
        return {
            editMessage: {
                active: false,
                message: '',
                icon: 'error',
                color: ''
            },
            isMyProfile: false,
            isloading: false,
            isErrorGet: false,
            success: {
                value: false,
                title: '¡Solicitud enviada!',
                message: 'El usuario recibirá un correo con un enlace para poder registrarse en Dedavara'
            },
            errorRequest: {
                value: false,
                title: '¡Error al enviar la solicitud!',
                message: 'Ha ocurrido un error al enviar la solicitud, por favor intenta de nuevo'
            },
            roleOptions: [
                { value: 'ROLE_SUPERVISOR', label: 'Supervisor' },
                { value: 'ROLE_INSTRUCTOR', label: 'Instructor' },
                { value: 'ROLE_COMPANY_ADMIN', label: 'Administrador' },
                { value: 'ROLE_AUDITOR', label: 'Auditor' },
               // { value: 'ROLE_INFORMANT', label: 'Informante' }
            ],
            password: {
                value: '',
                error: false,
                erroMessage: ''
            },
            repeatPassword: {
                value: '',
                error: false,
                erroMessage: ''
            },
            newPassword: {
                value: '',
                error: false,
                erroMessage: ''
            },
            role: {
                value: '',
                error: false,
                erroMessage: ''
            },
            userType: {
                value: '',
                error: false,
                errorMessage: ''

            },
            errorOnSubmit: false,
            companies: [
                { value: 1, label: 'Empresa 1' },
            ],
            showCompanySelector: false,
            error: false,
            name: {
                value: '',
                error: false,
                errorMessage: ''
            },
            surnames: {
                value: '',
                error: false,
                errorMessage: ''
            },
            email: {
                value: '',
                error: false,
                errorMessage: ''
            },
            category: {
                value: '',
                error: false,
                errorMessage: ''
            },
            tlf: {
                value: '',
                error: false,
                errorMessage: ''
            },
            address: {
                value: '',
                error: false,
                errorMessage: ''
            },
            fullName: {
                value: '',
                error: false,
                errorMessage: ''
            },
            externalCompany: {
                value: '',
                error: false,
                errorMessage: ''
            },
            nif: {
                value: '',
                error: false,
                errorMessage: ''
            },
            department: {
                value: '',
                error: false,
                errorMessage: ''
            },
            companiesToAdmin: {
                value: [],
                error: false,
                errorMessage: ''
            },
            departments: [],
            imagePreview: null,
            generalCategories: [],
            companyCategories: [],
            mergedCategories: [],
            breadcrumbsList: [
                { label: 'Usuarios', to: '/users' },
                { label: 'Nuevo usuario', to: '/users/create-user/users' }
            ],
            editMode: false,
            userId: null,
            disableChangeRole: false,
            loading: false
        }
    },
    computed: {
        ...mapGetters(['getUserCompany']),

        userCompany() {
            return this.getUserCompany;
        }
    },
    async created() {
        try {
            this.isloading = true
            await this.getCompanies();
            await this.getDepartments()
            await this.getCompanyCategories()

            if (this.$route.params.instructor === 'instructor') {
                this.userType.value = 'ROLE_INSTRUCTOR'
            }

            if (this.$route.params.user !== undefined) {
                this.editMode = true;
                this.userId = this.$route.params.user;
                this.breadcrumbsList = [
                    { label: 'Usuarios', to: '/users' },
                    { label: 'Editar usuario', to: `/users/edit-user/${this.userId}` }
                ];
                await this.getUserInfo();
            }
            if (this.$route.params.profile) {
                this.isMyProfile = true
            }

        }
        catch (err) {
            console.log(err)
        }
        finally {
            this.isloading = false
        }

    },
    methods: {
        previewImage(event) {
            const file = event.target.files[0];
            if (file) {
                this.$refs.imageRef.style.backgroundImage = `none`;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        lastUrl() {
            window.history.back();
        },
        toggleDropdown(toggle) {
            toggle();
        },
        sendNewUserRequest() {

            if (this.checkInputs()) {

                this.loading = true;
                var formData = new FormData();
                var companiesToAdminMap = []
                switch (this.userType.value) {
                    case 'ROLE_SUPERVISOR':
                    case 'ROLE_INSTRUCTOR':
                        formData.append('name', this.name.value);
                        formData.append('surnames', this.surnames.value);
                        formData.append('email', this.email.value);
                        formData.append('company', this.userCompany);
                        formData.append('department', this.department.value);
                        formData.append('category', this.category.value)
                        formData.append('supervisor', this.userType.value === 'ROLE_SUPERVISOR' ? true : false);
                       /* companiesToAdminMap = this.companiesToAdmin.value.map(company => {
                            return company.value;
                            
                        });*/
                        break;
                    case 'ROLE_COMPANY_ADMIN':
                        formData.append('name', this.name.value);
                        formData.append('email', this.email.value);
                        companiesToAdminMap = this.companiesToAdmin.value.map(company => {
                            return company.value;
                        });

                        formData.append('companies', JSON.stringify(companiesToAdminMap));
                        break;
                    case 'ROLE_AUDITOR':
                        formData.append('name', this.name.value);
                        formData.append('surnames', this.surnames.value);
                        formData.append('email', this.email.value);
                        formData.append('externalCompany', this.externalCompany.value);
                        formData.append('tlf', this.tlf.value);
                      /*  companiesToAdminMap = this.companiesToAdmin.value.map(company => {
                            return company.value;
                        }); */
                        break;
                    /*case 'ROLE_INFORMANT':
                        formData.append('name', this.name.value);
                        formData.append('surnames', this.surnames.value);
                        formData.append('email', this.email.value);
                        formData.append('tlf', this.tlf.value);
                        break;
                        */

                    default:
                        console.log('No se ha seleccionado un tipo de usuario');
                        break;
                }
                formData.append('role', this.userType.value);
                axios.post(`/admin/create-user`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(() => {
                        this.success.value = true;
                        this.errorRequest.value = false;
                        setTimeout(() => {
                            this.scrollToBottom();
                            this.loading = false;
                        }, 25);
                        setTimeout(() => {
                            this.clearAllInputs();
                            this.success.value = false;
                        }, 3500);
                    })
                    .catch(() => {
                        this.errorRequest.value = true;
                        this.success.value = false;
                        setTimeout(() => {
                            this.scrollToBottom();
                            this.loading = false;
                        }, 25);
                        setTimeout(() => {
                            this.errorRequest.value = false;
                        }, 3500);
                    });


            }
        },
        scrollToTop() {
            const element = this.$refs.newUser;
            element.scrollIntoView({ behavior: "smooth" });
        },
        scrollToBottom() {
            const element = this.$refs.response;
            element.scrollIntoView({ behavior: "smooth" });
        },
        checkInputs() {
            this.errorOnSubmit = false;



            if (this.name.value === '') {
                this.name.error = true
                this.name.errorMessage = 'El campo nombre es obligatorio'
            } else {
                this.name.error = false
                this.name.errorMessage = ''
            }

            if (this.surnames.value === '') {
                this.surnames.error = true
                this.surnames.errorMessage = 'El campo apellidos es obligatorio'
            } else {
                this.surnames.error = false
                this.surnames.errorMessage = ''
            }

            let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;


            if (this.email.value === '' || this.email.value === undefined) {
                this.email.error = true;
                this.email.errorMessage = 'El email es obligatorio';
            } else if (!emailRegex.test(this.email.value)) {
                this.email.error = true;
                this.email.errorMessage = 'El email no es válido';
            } else {
                this.email.error = false;
                this.email.errorMessage = '';
            }

            // esta comprobación es solo para los usuariso administradores de empresa
            if (this.companiesToAdmin.value.length === 0) {
                this.companiesToAdmin.error = true;
                this.companiesToAdmin.errorMessage = 'Debes seleccionar al menos una empresa.';
            } else {
                this.companiesToAdmin.error = false;
                this.companiesToAdmin.errorMessage = '';
            }
            // esta comprobación es solo para los usuarios supervisor e instructor


            if (this.category.value === '') {
                this.category.error = true;
                this.category.errorMessage = 'Debes seleccionar una categoría';
            } else {
                this.category.error = false;
                this.category.errorMessage = '';
            }

            if (this.department.value === '') {
                this.department.error = true;
                this.department.errorMessage = 'Debes seleccionar un departamento';
            } else {
                this.department.error = false;
                this.department.errorMessage = '';
            }
            // esta comprobación es solo para los usuarios auditores

            if (this.userType.value === '') {
                this.userType.error = true;
                this.userType.errorMessage = 'Debes seleccionar un tipo de usuario';
            } else {
                this.userType.error = false;
                this.userType.errorMessage = '';
            }


            if (this.userType.value === 'ROLE_SUPERVISOR' || this.userType.value === 'ROLE_INSTRUCTOR') {
                this.externalCompany.error = false;
                this.companiesToAdmin.error = false;

            }

            if (this.userType.value === 'ROLE_COMPANY_ADMIN') {
                this.surnames.error = false;
                this.category.error = false;
                this.department.error = false;
                this.externalCompany.error = false;
                if (this.isMyProfile) { this.companiesToAdmin.error = false }
            }
            if (this.userType.value === 'ROLE_AUDITOR') {
                this.category.error = false;
                this.department.error = false;
                this.companiesToAdmin.error = false;

            }
            if (this.userType.value === 'ROLE_INFORMANT') {
                this.category.error = false;
                this.department.error = false;
                this.companiesToAdmin.error = false;
                this.externalCompany.error = false;

            }


            if (this.name.error || this.surnames.error || this.email.error || this.companiesToAdmin.error || this.category.error || this.department.error) {
                return false;
            }
            return true;

        },
        async getCompanies() {
            try {
                const response = await axios.get(`/companies?owner.id=${this.$store.getters.getUser.id}`)
                this.companies = response.data['hydra:member'];
                this.companies = this.companies.map(company => {
                    return {
                        value: company.id,
                        label: company.fullName
                    }
                });
            }
            catch (error) {
                this.isErrorGet = true
                console.log(error);
            }

        },

        async getDepartments() {

            try {
                const response = await axios.get(`/departments?company.id=${this.userCompany}&itemsPerPage=200`);
                this.departments = response.data['hydra:member'].map(department => {
                    return {
                        value: department.id,
                        label: department.name
                    };
                });
            } catch (error) {
                this.isErrorGet = true;
                console.log(error);
            }

        },
        async getCompanyCategories() {
            try {
                const response = await axios.get(`/categories?company.id=${this.userCompany}&itemsPerPage=200`);
                this.companyCategories = response.data['hydra:member'].map(category => {
                    return {
                        value: category.id,
                        label: category.name
                    };
                });
                this.mergedCategories = [...this.generalCategories, ...this.companyCategories];
            } catch (error) {
                this.isErrorGet = true;
                console.log(error);
            }

        },

        goBack() {
            this.$router.push('/users');
        },
        clearAllInputs() {
            this.name.value = '';
            this.surnames.value = '';
            this.email.value = '';
            this.category.value = '';
            this.tlf.value = '';
            this.address.value = '';
            this.fullName.value = '';
            this.externalCompany.value = '';
            this.nif.value = '';
            this.department.value = '';
            this.companiesToAdmin.value = [];
        },
        checkToSetErrorFalse(name, newValue) {
            if (this.editMessage.active && newValue !== '') {
                this.resetEditMessage()
            }
            if (this[name].error && newValue !== '') {
                this[name].error = false
            }
        },
        resetEditMessage() {
            this.editMessage.active = false
        },
        async getUserInfo() {

            try {
                const response = await axios.get(`/users/${this.userId}`);
                this.name.value = response.data.name;
                this.email.value = response.data.email;

                switch (response.data.userType) {
                    case 'Informante':
                        this.userType.value = 'ROLE_INFORMANT';
                        this.surnames.value = response.data.surnames;
                        this.tlf.value = response.data.tlf;
                        this.disableChangeRole = true;
                        break;

                    case 'Auditor':
                        this.userType.value = 'ROLE_AUDITOR';
                        this.surnames.value = response.data.surnames;
                        this.tlf.value = response.data.tlf;
                        this.externalCompany.value = response.data.company;
                        this.disableChangeRole = true;
                        break;

                    case 'Administrador Empresa':
                        this.userType.value = 'ROLE_COMPANY_ADMIN';
                        this.companiesToAdmin.value = response.data.companiesAdministrator.map(company => {
                            return {
                                value: company.id,
                                label: company.fullName
                            };
                        });
                        this.disableChangeRole = true;
                        break;
                    case 'Administrador':
                        this.userType.value = 'ROLE_COMPANY_ADMIN';
                        break;
                    case 'Supervisor':
                    case 'Instructor':
                        this.userType.value = response.data.userType === 'Supervisor' ? 'ROLE_SUPERVISOR' : 'ROLE_INSTRUCTOR';
                        this.surnames.value = response.data.surnames;

                        if (response.data.department) {
                            this.department.value = response.data.department.split('/')[4];
                        }

                        if (response.data.category) {
                            this.category.value = response.data.category.split('/')[4];
                        }

                        this.roleOptions = [
                            { value: 'ROLE_SUPERVISOR', label: 'Supervisor' },
                            { value: 'ROLE_INSTRUCTOR', label: 'Instructor' },
                        ];
                        break;

                    default:
                        break;
                }
            } catch (error) {
                this.isErrorGet = true;
                console.log(error);
            }


        },
        editUser() {
            if (this.checkInputs()) {
                var formData = {};
                var url = `/users/${this.userId}`;
                this.loading = true;
                switch (this.userType.value) {
                    case 'ROLE_SUPERVISOR':
                    case 'ROLE_INSTRUCTOR':
                        url = `/user_instructors/${this.userId}`;
                        formData = {
                            name: this.name.value,
                            surnames: this.surnames.value,
                            company: '/api/v1/companies/' + this.userCompany,
                            department: '/api/v1/departments/' + this.department.value,
                            category: '/api/v1/categories/' + this.category.value,
                            supervisor: this.userType.value === 'ROLE_SUPERVISOR' ? true : false
                        };
                        break;
                    case 'ROLE_COMPANY_ADMIN':
                        formData = {
                            name: this.name.value,
                            companiesAdministrator: this.companiesToAdmin.value.map(company => {
                                return `/api/v1/companies/${company.value}`;
                            })
                        };
                        break;
                    case 'ROLE_AUDITOR':
                        formData = {
                            name: this.name.value,
                            surnames: this.surnames.value,
                            company: this.externalCompany.value,
                            tlf: this.tlf.value
                        };
                        break;
                    case 'ROLE_INFORMANT':
                        formData = {
                            name: this.name.value,
                            surnames: this.surnames.value,
                            tlf: this.tlf.value
                        };
                        break;

                    default:
                        break;
                }
                axios.patch(url, formData)
                    .then(() => {
                     
                        this.editMessage.active = true
                        this.editMessage.message = 'El usuario ha sido editado correctamente'
                        this.editMessage.icon = 'info'
                        this.editMessage.color = ''
                        this.loading = false;
                        this.scrollToTop()

                    }).then(() => {
                        if (this.isMyProfile) {
                            const valueLocalStore = JSON.parse(localStorage.getItem('user'))
                            valueLocalStore.name = this.name.value
                            this.$store.commit('setUserName', this.name.value)
                            localStorage.setItem('user', JSON.stringify(valueLocalStore))
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.editMessage.active = true
                        this.editMessage.message = 'Ha ocurrido un error al editar el usuario, por favor intentalo de nuevo'
                        this.editMessage.icon = 'error'
                        this.editMessage.color = '#C74D4D'
                        this.loading = false;
                        this.scrollToTop()
                    });
            }
        }

    },
    watch: {
        role: {
            handler(newVal) {
                if (newVal.value === 'ROLE_COMPANY_ADMIN') {
                    this.showCompanySelector = true;
                } else {
                    this.showCompanySelector = false;
                }
            },
            deep: true
        },
        userCompany: {
            handler: function () {
                this.getDepartments();
                this.getCompanyCategories();
                this.department.value = ''
                this.category.value = ''
                this.resetEditMessage()
            },
            deep: true
        },
        'name.value'(newVal) {
            this.checkToSetErrorFalse('name', newVal)
        },
        'surnames.value'(newVal) {
            this.checkToSetErrorFalse('surnames', newVal)
        },
        'email.value'(newVal) {
            this.checkToSetErrorFalse('email', newVal)
        },
        'category.value'(newVal) {
            this.checkToSetErrorFalse('category', newVal)
        },
        'tlf.value'(newVal) {
            this.checkToSetErrorFalse('tlf', newVal)
        },
        'address.value'(newVal) {
            this.checkToSetErrorFalse('address', newVal)
        },
        'fullName.value'(newVal) {
            this.checkToSetErrorFalse('fullName', newVal)
        },
        'externalCompany.value'(newVal) {
            this.checkToSetErrorFalse('externalCompany', newVal)
        },
        'nif.value'(newVal) {
            this.checkToSetErrorFalse('nif', newVal)
        },
        'department.value'(newVal) {
            this.checkToSetErrorFalse('department', newVal)
        },
        'role.value'(newVal) {
            this.checkToSetErrorFalse('role', newVal)
        },
        'userType.value'(newVal) {
            this.checkToSetErrorFalse('userType', newVal)
        },
        'companiesToAdmin.value'(newVal) {
            if (this.companiesToAdmin.error && newVal.length > 0) {
                this.companiesToAdmin.error = false
            }
            this.resetEditMessage()
        },

    }
}
</script>

<style src="@/../node_modules/vue-multiselect/dist/vue-multiselect.css"></style>



<style lang="scss" scoped>
.loader {
    margin-inline: auto;
}

.password-container {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;



    .repeatPassword {
        grid-column: 1 / -1
    }

    .forgotten-password {
        justify-self: flex-start;
        grid-column: 1 / -1;

        &>a {
            text-align: left;
            display: block;
            transition: color .3s;

            &:hover {
                color: #163F49
            }

        }
    }

    @media (max-width:450px) {
        grid-template-columns: 1fr;
    }

    .router-recover-password {
        font-size: 14px;
        color: #55737A;
        font-family: 'interRegular';
        justify-self: flex-start;
        width: fit-content
    }


}

.new-user {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    .new-user__title {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 35px 0 0 0;
        gap: 24px;

        .bread-crumb {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            color: #707C7E;
            font-family: 'InterRegular', sans-serif;

            .highlighted {
                text-decoration: none;
                font-style: normal;
                font-family: 'InterMedium', sans-serif;
                color: #163F49;
            }
        }

        .title__text {
            font-size: 30px;
            font-weight: 600;
            line-height: 36.31px;
            color: #163F49;
            font-family: 'InterSemibold', sans-serif;
        }
    }

    .new-user__title-responsive {
        display: none;
    }

    .new-user__form {
        background: #FFFFFF;
        display: flex;
        gap: 32px;
        padding: 24px;
        border-radius: 8px;

        .form__image {
            width: 141px;
            height: 100%;
            position: relative;

            .file {
                display: none;
            }

            .file-preview {
                width: 141px;
                height: 141px;
                object-fit: cover;
                cursor: pointer;

            }
        }

        .form__body {
            flex-grow: 1;

            .body__inputs {
                display: flex;
                flex-direction: column;
                gap: 24px;

                .inputs__select-user-type {
                    display: flex;
                    gap: 8px;
                    flex-direction: column;

                    .select-user-type__label {
                        width: 100%;
                        text-align: left;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16.94px;
                        color: #707C7E;
                        font-family: 'InterMedium', sans-serif;
                    }
                }

                :deep(.general-input) {
                    label {
                        text-align: left;
                    }

                    .input-wrapper {

                        input {
                            height: 48px;
                        }
                    }

                    .error-message {
                        text-align: left;
                        width: 100%;
                        display: inline-block;
                    }

                }

                .inputs__line {
                    display: flex;
                    gap: 24px;

                }

                .inputs__selects {
                    display: flex;
                    gap: 24px;
                    width: 100%;

                    .selects__input {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        .label {
                            width: 100%;
                            text-align: left;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16.94px;
                            color: #707C7E;
                            font-family: 'InterMedium', sans-serif;
                        }

                    }

                    @media (max-width:900px) {
                        flex-direction: column;
                    }



                }

                .select-companies-admin {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .label {
                        width: 100%;
                        text-align: left;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16.94px;
                        color: #707C7E;
                        font-family: 'InterMedium', sans-serif;
                    }

                    .error {
                        color: #C74D4D;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 14.53px;
                        font-family: 'InterRegular', sans-serif;
                        width: 100%;
                        text-align: left;
                    }

                    :deep(.multiselect__tag) {
                        background: #163F49;
                    }

                    :deep(.multiselect__tags) {
                        height: auto;
                        padding: 12px 40px 0 8px;
                    }

                    :deep(.multiselect) {
                        min-height: 48px;

                        .multi-select__arrow {
                            display: inline-flex;
                            position: absolute;
                            right: 8px;
                            top: calc(50% - 5px);
                            width: 20px;
                            height: 10px;
                        }
                    }

                    :deep(.multiselect--active) {
                        .multi-select__arrow {
                            transform: rotate(180deg);
                        }
                    }

                }

                .select-companies-admin.error-companies {
                    :deep(.multiselect__tags) {
                        border-color: #C74D4D;
                    }
                }
            }

            .body__buttons {
                display: flex;
                justify-content: flex-end;
                margin: 24px 0 0 0;

                .submit-button {
                    background: #163F49;
                    border-radius: 8px;
                    padding: 12px 24px;
                    color: #FFFFFF;
                    font-size: 18px;
                    border: none;
                    font-weight: 500;
                    line-height: 16.94px;
                    font-family: 'InterRegular', sans-serif;
                    width: 305px;
                    height: 48px;
                    cursor: pointer;
                }
            }
        }
    }

    .sent-request {
        position: relative;
        width: 100%;
        height: 300px;
        max-height: 476px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        h2 {
            margin: 0;
            font-size: 24px;
            font-weight: 600;
            line-height: 29.05px;
            color: #0D2930;
            font-family: 'InterRegular', sans-serif;
        }

        p {
            margin-top: 19px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            color: #A2ABAD;
            font-family: 'InterRegular', sans-serif;
        }

        .background-logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 20%;
            max-height: 240px;
        }
    }
}





@media (max-width:768px) {

    .new-user {
        


        .new-user__form {
            flex-direction: column;

            .form__body {
                .body__inputs {
                    .inputs__select-user-type {
                        .custom-select__container {
                            :deep(select) {
                                height: 42px;
                                font-size: 14px;
                            }
                        }
                    }

                    .general-input {
                        :deep(.input-wrapper) {
                            input {
                                height: 42px;
                                font-size: 14px;
                            }
                        }
                    }

                    .inputs__line {
                        flex-direction: column;
                        gap: 24px;
                    }

                    .select-companies-admin {
                        :deep(.multiselect__tags) {
                            min-height: 42px;
                            padding: 10px 40px 0 8px;
                        }

                        :deep(.multiselect) {
                            min-height: 42px;
                        }
                    }
                }

                .body__buttons {
                    .submit-button {
                        width: 100%;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>


<style lang="scss">
//mas estilos
.error {
    .multiselect__tags {
        border: 1.25px solid #C74D4D !important
    }
}

.multiselect {
    width: 100%;
    font-size: 14px;
    color: #0E0E0F;
    font-family: 'InterRegular';
    line-height: 16.94px;
    cursor: pointer;
}

.multiselect__tags {
    border: 1.25px solid #B3BBBC !important;
    padding-top: 15px !important;
    padding-bottom: 7px !important;
    border-radius: 8px;

}

.multiselect__select {
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;

    margin-top: 6px;
    margin-right: 5px;
    padding-left: 6px;

}


.multiselect__content-wrapper {
    min-height: 69px;
}

.multiselect__option--highlight {
    background-color: #CBDEE2;
    color: #0E0E0F;
    font-family: 'InterRegular';
    font-size: 14px;
}

.multiselect__option--selected.multiselect__option--highlight {

    color: #fff;
    background: #163F49;
    font-family: 'InterRegular';
    font-size: 14px;
}

.multiselect__option--selected::after {
    content: '';
    color: #fff;
    background: #163F49;

}

.multiselect__option--selected {
    background: #163F49;
    color: #fff;
    font-weight: 400;
    font-family: 'InterRegular';
    font-size: 14px;
}

.multiselect__single {
    max-width: 100%;
    /* Ancho máximo para el select */
    overflow: hidden;
    /* Ocultar el desbordamiento */
    white-space: nowrap;
    /* Evitar que el texto se divida en varias líneas */
    text-overflow: ellipsis;
}


//LA X para eliminar
.multiselect__option--selected.multiselect__option--highlight::after {
    content: "";
    background: transparent;
    color: #fff;
    width: 10%;
    height: 100%;
    background-image: url('../../assets/icons/whiteClose.svg');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 50% 50%;
}

.multiselect__placeholder {

    padding-top: 0 !important;
    color: #B3BBBC;
    padding-left: 6px !important;

}

.multiselect__select::before {
    display: none !important;
}
</style>