import { createI18n } from 'vue-i18n'

let navigatorLanguage = navigator.language.split('-')[0];

 const i18n = createI18n({
    locale: localStorage.getItem('selectedLanguage') || navigatorLanguage || 'es',
    silentTranslationWarn: true,
    fallbackWarn: false,
    
})

export default i18n;