<template>
    <div class="template">
        <div class="title">
            <h3><router-link class="h3" to="/users">Usuarios / </router-link> <router-link class="h3" to="/users/manage-instructors">Gestionar Instructores</router-link> <span>/ Nueva Instructor</span></h3>
            <img src="@/assets/icons/vector.svg" @click="lastUrl">
            <h1>Nuevo Instructor</h1>
        </div>

        <form class="data-box" @submit.prevent="!this.editMode ? this.saveUserData():this.editUserData()">
            <div class="image">
                <input ref="imageRef" type="file" class="file" @change="previewImage">
                <img v-if="imagePreview" :src="imagePreview" alt="Preview" class="file-preview" @click="this.$refs.imageRef.click()">
            </div>

            <GeneralInput :labelName="'Nombre'" labelFor="name" :vModel="this.name.value" inputType="text" @update-value="this.name.value=$event;" class="name" :error="this.name.error" :errorMessage="this.name.errorMessage" required/>
            <GeneralInput :labelName="'Apellidos'" labelFor="lastName" :vModel="this.lastName.value" inputType="text" @update-value="this.lastName.value=$event;" class="last-name" :error="this.lastName.error" :errorMessage="this.lastName.errorMessage" required/>
            <GeneralInput :labelName="'Correo Electrónico'" labelFor="email" :vModel="this.email.value" inputType="email" @update-value="this.email.value=$event;" class="email" :error="this.email.error" :errorMessage="this.email.errorMessage" required     :disabled="this.editMode" />
            <label class="company">
                <p>Empresa</p>
                <select v-model="this.company.value" required>
                    <option value="" disabled selected>Selecciona una empresa</option>
                    <option v-for="(option,index) in this.companies" :key="index" :value="option['id']">{{ option.fullName }}</option>
                </select>
                <span v-if="this.company.error" class="error-message">{{ this.company.value }}</span>
            </label>

            <label class="department">
                <p>Departamento</p>
                <select v-model="this.department.value" required>
                    <option value="" disabled selected>Selecciona un departamento</option>
                    <option v-for="(option,index) in departments" :key="index" :value="option.id">{{ option.name }}</option>
                </select>
                <span v-if="this.department.error" class="error-message">{{ this.department.value }}</span>
            </label>

            <label class="category">
                <p>Categoria</p>
                <select v-model="this.category.value" required>
                    <option value="" disabled selected>Selecciona una categoría</option>
                    <option v-for="(option,index) in generalCategories" :key="index" :value="option.id">{{ option.name }}</option>
                    <option v-for="(option,index) in companyCategories" :key="index" :value="option.id">{{ option.name }}</option>
                </select>
                <span v-if="this.category.error" class="error-message">{{ this.category.value }}</span>
            </label>

            <button type="submit">{{ this.editMode ? 'Editar Usuario':'Enviar solicitud de registro' }}</button>
        </form>

        <div class="solicitud-enviada" v-if="this.success.value">
            <h2>{{ this.success.title }}</h2>
            <p>{{ this.success.message }}</p>
            <img class="background-logo" src="@/assets/logos/Isotipo - Color.svg">
        </div>

        <div class="solicitud-enviada" v-if="this.error.value">
            <h2>{{ this.error.title }}</h2>
            <p>{{ this.error.message }}</p>
            <img class="background-logo" src="@/assets/logos/Isotipo - Color.svg">
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import GeneralInput from '@/components/forms/Input.vue'
export default {
    name: 'NewInstructor',
    components: {
        GeneralInput
    },
    data() {
        return {
            isEmailSent: false,
            email: {
                value: '',
                error: false,
                errorMessage: ''
            },
            name: {
                value: '',
                error: false,
                errorMessage: ''
            },      
            lastName: {
                value: '',
                error: false,
                errorMessage: ''
            },
            company:{
                value: '',
                error: false,
                errorMessage: ''
            },
            department:{
                value: '',
                error: false,
                errorMessage: ''
            },
            category:{
                value: '',
                error: false,
                errorMessage: ''
            },
            imagePreview: null,
            generalCategories: [],
            companyCategories: [],
            companies: [],
            departments: [],
            success:{
                value: false,
                title: '¡Solicitud enviada!',
                message: 'El usuario recibirá un correo con un enlace para poder registrarse en Dedavara'
            },
            error:{
                value: false,
                title: '¡Error al enviar la solicitud!',
                message: 'Ha ocurrido un error al enviar la solicitud, por favor intenta de nuevo.'
            },
            supervisor: false,
            editMode: false,
            instructor:{
                id: '',
            }
        }
    },
    created() {
        this.getCategories();
        this.getCompanies();
        if(this.$route.query.supervisor){
            this.supervisor = true;
        }
        if(this.$route.query.email){
            this.email.value = this.$route.query.email;
        }


        if(this.$route.params.instructor!==undefined){
            this.editMode = true;
            this.instructor.id = this.$route.params.instructor;
            this.getInstructorData();
        }
    },
    methods: {
        previewImage(event) {
            const file = event.target.files[0];
            if (file) {
                this.$refs.imageRef.style.backgroundImage = `none`;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        lastUrl() {
            window.history.back();
        },
        saveUserData() {
            if(this.checkInstructorData()){
                var url = '/user_instructors';
                if(this.supervisor){
                    url = url+'?supervisor=true';
                }
                
                axios.post(url,{
                    email: this.email.value,
                    name: this.name.value,
                    surnames: this.lastName.value,
                    company: `/companies/${this.company.value}`,
                    department: `/departments/${this.department.value}`,
                    category: `/categories/${this.category.value}`,
                    // profileImg: this.$refs.imageRef.files[0].name
                }).then(()=> {
                    this.success.value = true;
                    setTimeout(() => {
                        this.$router.push('/users/manage-instructors');
                    }, 3000);
                }).catch(() => {
                    this.error.value = true;
                    setTimeout(() => {
                        this.error.value = false;
                    }, 5000);
                });
            }


        },
        checkInstructorData(){
            if(this.email.value === ''){
                this.email.error = true;
                this.email.errorMessage = 'El correo electrónico es obligatorio';
            } else {
                this.email.error = false;
                this.email.errorMessage = '';
            }
            if(this.name.value === ''){
                this.name.error = true;
                this.name.errorMessage = 'El nombre es obligatorio';
            } else {
                this.name.error = false;
                this.name.errorMessage = '';
            }
            if(this.lastName.value === ''){
                this.lastName.error = true;
                this.lastName.errorMessage = 'Los apellidos son obligatorios';
            } else {
                this.lastName.error = false;
                this.lastName.errorMessage = '';
            }
            if(this.company.value === ''){
                this.company.error = true;
                this.company.errorMessage = 'La empresa es obligatoria';
            } else {
                this.company.error = false;
                this.company.errorMessage = '';
            }
            if(this.department.value === ''){
                this.department.error = true;
                this.department.errorMessage = 'El departamento es obligatorio';
            } else {
                this.department.error = false;
                this.department.errorMessage = '';
            }
            if(this.category.value === ''){
                this.category.error = true;
                this.category.errorMessage = 'La categoría es obligatoria';
            } else {
                this.category.error = false;
                this.category.errorMessage = '';
            }

            if(this.email.error || this.name.error || this.lastName.error || this.company.error || this.department.error || this.category.error || this.department.error || this.category.error || this.company.error){
                return false;
            } else {
                return true;
            }


        }
        ,
        getCompanies(){
            axios.get(`/companies?owner.id=${this.$store.getters.getUser.id}`)
            .then(response => {
                this.companies = response.data['hydra:member'];

            })
            .catch(error => {
                console.log(error);
            });
        },
        getDepartments(){
            axios.get(`/departments?company=${this.company.value}`)
            .then(response => {
                this.departments = response.data['hydra:member'];
            })
            .catch(error => {
                console.log(error);
            });
        },
        getCompanyCategories(){
            axios.get(`/categories?company.id=${this.company.value}`)
            .then(response => {
                this.companyCategories = response.data['hydra:member'];

            })
            .catch(error => {
                console.log(error);
            });
        },
        getCategories(){
            axios.get(`/categories?exists[company]=false`)
            .then(response => {
                this.generalCategories = response.data['hydra:member'];
            })
            .catch(error => {
                console.log(error);
            });
        },
        getInstructorData(){
            axios.get(`/user_instructors/${this.instructor.id}`)
            .then(response => {
                this.email.value = response.data.email;
                this.name.value = response.data.name;
                this.lastName.value = response.data.surnames;
                if(response.data.company!==undefined){
                    this.company.value = response.data.company.split('/')[4];
                }
                if(response.data.department!==undefined){
                    this.department.value = response.data.department.split('/')[4];
                }
                if(response.data.category!==undefined){
                    this.category.value = response.data.category.split('/')[4];
                }
            })
            .catch(error => {
                console.log(error);
                // this.$router.push('/users/manage-instructors');
            });
        },
        editUserData(){
            console.log('edit');
            if(this.checkInstructorData()){
                axios.patch(`/user_instructors/${this.instructor.id}`,{
                    name: this.name.value,
                    surnames: this.lastName.value,
                    company: `/companies/${this.company.value}`,
                    department: `/departments/${this.department.value}`,
                    category: `/categories/${this.category.value}`,
                    // profileImg: this.$refs.imageRef.files[0].name
                }).then(()=> {
                    this.success.value = true;
                    this.success.title = '¡Usuario editado!';
                    this.success.message = 'El usuario ha sido editado correctamente';
                    setTimeout(() => {
                        this.$router.push('/users/manage-instructors');
                    }, 3000);
                }).catch(() => {
                    this.error.value = true;
                    this.error.title = '¡Error al editar el usuario!';
                    this.error.message = 'Ha ocurrido un error al editar el usuario, por favor intenta de nuevo.';
                    setTimeout(() => {
                        this.error.value = false;
                    }, 5000);
                });
            }
        }
    },
    watch: {
        company:{
            handler: function(){
                this.getDepartments();
                this.getCompanyCategories();
            },
            deep: true
        }
    }
}
</script>

<style scoped lang="scss">
              
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
}

.template {
    .title {
        display: flex;
        margin-top: 25px;

        h3 {
            display: none;
            font-family: 'InterRegular', sans-serif;

            span {
                font-family: 'InterRegular', sans-serif;
            }
        }

        h1 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            text-align: left;
            color: #163F49;
            font-family: 'InterRegular', sans-serif;
        }

        img {
            margin-top: 5px;
            margin-left: 24px;
            margin-right: 26px;
            width: 8px;
            height: 15px;
            cursor: pointer;
        }
    }

    .data-box {
        background-color: #FFFFFF;
        margin-top: 36px;
        padding: 24px ;
        border-radius: 4px;
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .image {
            display: flex;
            justify-content: left;
            // margin-left: 24px;
            // margin-top: 20px;
            position: relative;
            .file {
                width: 141px;
                height: 141px;
                border: none;
                background-image: url('@/assets/icons/inputFile.svg');
                background-size: cover; 
                background-position: center;
                cursor: pointer;
                outline: none;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
            }
            input[type="file"]::-webkit-file-upload-button {
                opacity: 0;
            }

            .file-preview {
                position: absolute;
                left: -24px;
                width: 141px;
                height: 141px;
                margin-left: 24px;
                border: 1.25px dashed var(--Gris--1-Dedavara, #B3BBBC);
                cursor: pointer;
            }
        }


        label {
            display: flex;
            flex-direction: column;
            text-align: left;
            // width: calc(100% - 48px);
            // margin: 24px 24px 0 24px;
            p {
                font-family: 'InterRegular', sans-serif;
            }
            input {
                margin-top: 8px;
                border-radius: 8px;
                border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                padding-left: 13.78px;
                color: #0E0E0F;
                height: 42px;
                font-family: 'InterRegular', sans-serif;
            }

            input::placeholder {
                color: #B3BBBC;
            }

            select {
                margin-top: 8px;
                border-radius: 8px;
                border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                padding-left: 13.78px;
                color: #0E0E0F;
                height: 42px;
                font-family: 'InterRegular', sans-serif;
            }

        }

        .company, .department, .category {
            select {
                width: 50%;
            }
        }

        button {
            width: 100%;
            height: 48px;
            border-radius: 4px;
            border: none;
            background-color: #163F49;
            color: #F9FBFC;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            text-align: center;
            cursor: pointer;
            font-family: 'InterRegular', sans-serif;
        }
    }

    .solicitud-enviada {
        position: relative;
        width: 100%;
        height: 50%;
        max-height: 476px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        top: -100px;
        h2 {
            margin: 0;
            font-size: 24px;
            font-weight: 600;
            line-height: 29.05px;
            color: #0D2930;
            font-family: 'InterRegular', sans-serif;
        }

        p {
            margin-top: 19px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            color: #A2ABAD;
            font-family: 'InterRegular', sans-serif;
        }

        .background-logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 20%;
            max-height: 240px;
        }
    }
}

@media (max-width: 450px) {
    .template {
        .data-box {
            .company, .department, .category{
                select {
                    width: 100%;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .template {
        margin-left: 75px;
        margin-right: 44px;
        .title {
            flex-direction: column;
            margin-top: 32px;

            h3 {
                display: block;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;

                .h3 {
                    color: #A2ABAD;
                }

                margin: 0;

                span {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14.52px;
                    text-align: left;
                    color: #365961;
                }
            }

            h1 {
                margin-top: 25px;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
                text-align: left;
                color: #163F49;
            }

            img {
                display: none;
            }
        }

        .data-box {
            margin: 48px 0 0 0;
            padding: 24px;
            display: grid;
            grid-template-columns: 141px 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: auto auto auto auto;
            gap: 24px;
            grid-template-areas:
                "image name name name last-name last-name last-name"
                "image email email email email email email"
                "none company company department department category category"
                "none button button button button button button";

            .image {
                grid-area: image;
                margin: 26px 0 0 0;
            }

            .name {
                grid-area: name;
                margin: 0;
            }

            .last-name {
                grid-area: last-name;
                margin: 0;
            }

            .email {
                grid-area: email;
            }

            .company {
                grid-area: company;

                select {
                    width: 100%;
                }
            }

            .department {
                grid-area: department;

                select {
                    width: 100%;
                }
            }

            .category {
                grid-area: category;

                select {
                    width: 100%;
                }
            }

            // label {
            //     margin: 0;
            //     // width: 100%;

            //     p {
            //         font-size: 14px;
            //         font-weight: 500;
            //         line-height: 16.94px;
            //         text-align: left;
            //         color: #707C7E;
            //     }

            //     input {
            //         height: 48px;
            //         border-radius: 8px;
            //         font-size: 14px;
            //         font-weight: 400;
            //         line-height: 16.94px;
            //         text-align: left;

            //     }
            // }

            button {
                grid-area: button;
                margin: 0;
                width: 305px;
                height: 48px;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 500;
                line-height: 21.78px;
                text-align: center;
                justify-self: end;
            }

        }

        .solicitud-enviada {
            margin-top: 24px;
            top: 0;
            align-items: center;
            h2 {
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                max-width: 460px;
                line-height: 19.36px;
            }
            
            .background-logo {
                top: 50%;
                max-height: 326px;
            }
        }
    }
}
</style>