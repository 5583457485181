<template>


    <LayaoutTableDinamic :tableInfoMessage="tableInfoMessage" :deleteModalText="deleteModalText"
        :buttonTitle="buttonTitle" title="Formularios" :breadcrumbs="this.breadcrumbsList" path="/forms"
        :tableColumnsData="tableColumnsData" :secondaryButton="false" editPath="/complaint-forms/edit-form" />

</template>


<script>

import LayaoutTableDinamic from '../users/LayaoutTableDinamic.vue';

export default {
    name: 'ComplaintFormComponent',
    components: {
        LayaoutTableDinamic
    },
    data() {
        return {
            breadcrumbsList: [
                { label: 'Formularios', to: '/complaint-forms' },
            ],
            buttonTitle: {
                create: 'Crear nuevo formulario',
                linkToCreate: '/complaint-forms/create-form',
                delete: 'Eliminar formularios'
            },
            deleteModalText: {
                title: '¿Desea eliminar los formularios seleccionados?',
                namePlural: 'formularios',
                textSingular: 'Se eliminará el formulario seleccionado',
                errorSingular: 'No se puede eliminar el formulario seleccionado porque se está usando',
                errorPlural: 'No se han podido eliminar algunos de los formularios seleccionados porque están usandose'
            },
            tableInfoMessage: {
                notCreate: 'No hay formularios creados',
                notSearch: 'No se encontraron formularios'
            },
            tableColumnsData: {
                desk: {
                    columnTitle: [{ title: 'Formulario', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha', isUp: false }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['title'],
                    titleDetailsRowName: [],
                    //Tiene que hacer match con los datos
                    detailsRowName: [],
                },
                mobile: {
                    columnTitle: [{ title: 'Formulario', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha' }],
                    //Tiene que hacer match con los datos
                    mainRowName: ['title'],
                    titleDetailsRowName: [],
                    //Tiene que hacer match con los datos
                    detailsRowName: [],
                }
            }
        }
    },

}
</script>
