<template>
    <TableMenuLayaout :goBack="goBack" :tableOptions="tableOptions" :descriptionName="descriptionName" :dbUrl="dbUrl"
        :searchOption="false" :breadcrumbs="breadcrumbs" :root="false" getPath="state"
        :tableInfoMessage="tableInfoMessage" :deleteModalText="deleteModalText" />
</template>

<script>
import TableMenuLayaout from "@/components/general/TableMenuLayaout.vue"
export default {
    name: 'StateComponent',
    components: { TableMenuLayaout },
    data() {
        return {
            tableOptions: {
                chekbox: true,
                arrow: true,
                columnTitle: [{ title: 'Estados', image: require('@/assets/icons/arrow_filter.svg'), alt: 'flecha',  isUp: false  }],
                options: true,
                //Tiene que hacer match con cateogias
                mainRowName: ['name'],
                titleDetailsRowName: ['Descripción'],
                //Tiene que hacer match con cateogias
                detailsRowName: ['description']
            },
            descriptionName: {
                title: 'Estados',
                buttonAddName: 'Nuevo'
            },
            dbUrl: {
                path: "/states",
                pathToCreate: "/expedientes/estados/crear-estado",
                pathToEdit: "/expedientes/estados/editar-estados"
            },
            breadcrumbs: [{ label: 'Expedientes', to: '/expedientes' }, { label: 'Estados', to: '/expedientes/estados' }],
            tableInfoMessage: {
                notCreate: 'No hay estados creados',
            },
            deleteModalText: {
                title: '¿Desea eliminar los estados seleccionados?',
                namePlural: 'estados',
                textSingular: 'Se eliminará el estado seleccionado',
                errorSingular: 'No se puede eliminar el estado seleccionado porque se está usando en expedientes',
                errorPlural: 'No se han podido eliminar algunos de los estados seleccionados porque están usandose en expedientes'
            }
        }
    },
    methods: {
        goBack() {
            window.history.back();
        }
    },
}

</script>
