<template>
    <div ref="topDiv">
        <CreateEditCategory :title="name.title" :message="isEdit ? editMessage : createdMessage"
            :onClickMethod="isEdit ? updateCategory : addCategory" v-model:firstModelValue.trim="categoryInfo.name"
            v-model:secondModelValue.trim="categoryInfo.description" v-model:threeModelValue="color"
            :buttonName="name.button" :breadcrumbs="breadcrumbs" :getError="getError" :isLoading="isLoading"
            :isState="isState" :focusado="focusado" @setFocusadoToFalse="setFocusadoToFalse" />
    </div>
</template>

<script>
import axios from 'axios';
import CreateEditCategory from "@/components/categories/CreateEditCategory.vue";
import { mapGetters } from 'vuex';
import { useRoute } from 'vue-router';

export default {
    name: 'CreateAndEditLayaout',
    components: { CreateEditCategory },
    props: {
        name: {
            type: Object,
            required: true
        },
        path: {
            type: String,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        breadcrumbs: {
            type: Array,
            required: true
        },
        isState: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            newCategoryInfo: { name: "", description: "" },
            color: "#163F49",
            editCategoryInfo: { name: "", description: "" },
            createdMessage: { active: false, text: "", icon: "", error: false },
            editMessage: { active: false, text: "", icon: "", error: false },
            createdOpenMessage: false,
            editOpenMessage: false,
            getError: false,
            isLoading: false,
            focusado: false
        };
    },
    computed: {
        ...mapGetters(['getUserCompany']),
        userCompany() {
            return this.getUserCompany;
        },
        categoryInfo: {
            get() {
                return this.isEdit ? this.editCategoryInfo : this.newCategoryInfo;
            },
            set(value) {
                if (this.isEdit) {
                    this.editCategoryInfo = value;
                } else {
                    this.newCategoryInfo = value;
                }
            }
        }
    },
    watch: {
        'newCategoryInfo.name'() {
            this.createdMessage.error = false;
            if (this.createdOpenMessage) {
                this.createdMessage.active = true
                this.createdOpenMessage = false
            } else {
                this.createdMessage.active = false
            }
        },
        'newCategoryInfo.description'() {
            this.createdMessage.error = false;
            if (!this.newCategoryInfo.name && !this.newCategoryInfo.description) return
            this.createdMessage.active = false
        },
        //EDITAR
        'editCategoryInfo.name'() {

            if (this.getError) return
            this.editMessage.error = false;
            if (this.editOpenMessage) {
                this.editMessage.active = true
                this.editOpenMessage = false
            } else {
                this.editMessage.active = false
            }
        },
        'editCategoryInfo.description'() {
            if (this.getError) return
            this.editMessage.error = false;

            if (this.editOpenMessage) {
                this.editMessage.active = true
                this.editOpenMessage = false
            } else {
                this.editMessage.active = false
            }
        }
    },
    methods: {
        setFocusadoToFalse() {
            this.focusado = false
        },
        async addCategory() {
            this.createdMessage.active = false;
            if (this.newCategoryInfo.name === "") {
                this.createdMessage.error = true;
                this.createdMessage.active = false;
                return;
            }

            try {
                let data = {}
                if (this.isState) {
                    data = {
                        name: this.newCategoryInfo.name,
                        description: this.newCategoryInfo.description,
                        language: "/api/v1/languages/lang_d4u6e5d8x9049",
                        company: `/api/v1/companies/${this.userCompany}`,
                        color: this.color
                    }
                } else {
                    data = {
                        name: this.newCategoryInfo.name,
                        description: this.newCategoryInfo.description,
                        language: "/api/v1/languages/lang_d4u6e5d8x9049",
                        company: `/api/v1/companies/${this.userCompany}`,
                    }
                }
                await axios.post(`${this.path}`, data);

                this.createdMessage.text = "Se ha enviado correctamente";
                this.createdMessage.icon = 'info';
                this.newCategoryInfo.description = "";
                this.newCategoryInfo.name = "";
                this.createdOpenMessage = true

                const element = this.$refs.topDiv;
                element.scrollIntoView({ behavior: "smooth" });
                this.focusado = true


            } catch (error) {
                this.createdMessage.active = true;
                this.createdMessage.text = "Se ha producido un error. Consulte con el servicio técnico";
                this.createdMessage.icon = 'error';

                const element = this.$refs.topDiv;
                element.scrollIntoView({ behavior: "smooth" });
                this.focusado = true
            }
        },
        async updateCategory() {
            this.editMessage.active = false;
            if (this.editCategoryInfo.name === "") {
                this.editMessage.error = true;
                this.editMessage.active = false;
                return;
            }

            const url = `${this.path}/${this.identifier}`;
            const data = {
                name: this.editCategoryInfo.name,
                description: this.editCategoryInfo.description
            }
            if (this.isState) {
                data.color = this.color
            }

            try {
                await axios.patch(url, data, {
                    headers: {
                        //  'Content-Type': 'application/merge-patch+json'
                        'Content-Type': 'application/json'
                    }
                });
                this.editMessage.text = "Se ha editado correctamente";
                this.editMessage.icon = 'info';
                this.editMessage.active = true;

                const element = this.$refs.topDiv;
                element.scrollIntoView({ behavior: "smooth" });
                this.focusado = true

            } catch (error) {
                this.editMessage.active = true;
                this.editMessage.text = "Se ha producido un error. Consulte con el servicio técnico";
                this.editMessage.icon = 'error';
                console.error('Error:', error.response ? error.response.data : error.message);

                const element = this.$refs.topDiv;
                element.scrollIntoView({ behavior: "smooth" });
                this.focusado = true
            }
        }
    },
    async created() {
        if (!this.isEdit) return;
        this.isLoading = true
        try {
            const router = useRoute();
            const { id } = router.params;
            this.identifier = id;
            const response = await axios.get(`${this.path}/${id}`);
            this.editCategoryInfo.name = response.data.name;
            this.editCategoryInfo.description = response.data.description;
            if (this.isState) {
                this.color = response.data.color
            }
            this.isLoading = false
        } catch (error) {
            this.editMessage.active = true;
            this.editMessage.text = "Se ha producido un error. Consulte con el servicio técnico";
            this.editMessage.icon = 'error';

            this.getError = true
            console.error('Error:', error.response ? error.response.data : error.message);
            this.isLoading = false
        }
    }
};
</script>