

const formsInfo = {
    state: {
        company:null,
        departments:[],
    },
    mutations: {
        setCompany(state, company){
            state.company = company;
        },
        addDepartment(state, department){
            state.departments.push(department);
        },
        editDepartment(state, department){
            state.departments[department.index] = department;
        },
        deleteDepartment(state, index){
            state.departments.splice(index, 1);
        },
        setDepartments(state, departments){
            state.departments = departments;
        }

        
    },
    actions: {
        setCompany({commit}, company){
            commit('setCompany', company);
        },
        addDepartment({commit}, department){
            commit('addDepartment', department);
        },
        editDepartment({commit}, department){
            commit('editDepartment', department);
        },
        deleteDepartment({commit}, index){
            commit('deleteDepartment', index);
        },
        setDepartments({commit}, departments){
            commit('setDepartments', departments);
        }

    },
    getters: {
        getCompany: state => state.company,
        getDepartments: state => state.departments
    }
}
export default formsInfo;