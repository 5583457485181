<template>
    <!-- Rounded switch -->
    <label class="switch">
         
        <input type="checkbox" v-model="isChecked" @change="handleChange">
        <span class="slider round"></span>
    </label>
 
</template>

<script>
export default {
    name: 'ToggleSwitch ',
    data() {
        return {
            isChecked: this.value
        };
    },
    props: {
        value: Boolean,
    },
    watch: {
        value(newValue) {
            this.isChecked = newValue;
        }
    },
    methods: {
        handleChange() {

            this.$emit('input', this.isChecked);
        }
    }
}
</script>


<style scoped lang="scss">
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #163F49;
}

input:focus+.slider {
    box-shadow: 0 0 1px #163F49;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/*Media queries*/

@media (max-width: 768px) {
    .switch {
        width: 50px;
        height: 28px;
    }

    .slider {
        width: 48px;
        height: 24px;
    }

    .slider:before {
        width: 15px;
        height: 15px;
    }
}
</style>