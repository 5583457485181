<template>
  <div>
    <div class="loader">
      <LoaderComponent v-if="isLoader" />
    </div>
    <InfoMessage :isActive="infoMessage.isActive" :color="infoMessage.icon === 'info' ? null : '#C74D4D'"
      :text="infoMessage.text" :icon="infoMessage.icon" />
    <div v-show="!isLoader && !infoMessage.isActive" class="casefile-details">
      <!--Título con botones-->
      <div class="title-buttons">
        <div class="edit-casefile">
          <generalTitle :title="(isSubCaseFile ? 'Subexpediente ' : 'Expediente ') + caseFile.caseCode"
            :breadcrumbs="breadcrumbs" @goBack="this.goBack()" :root="false" />
          <div>
            <div class="font-size-sm">
              <GeneralButton :href="'/expedientes/editar-expediente/' + identify" text="Editar" :invertColor="true"
                width="119px" />
            </div>
          </div>

        </div>

        <div class="buttons">
          <div class="font-size-sm">
            <GeneralButton :isButton="true" @action="toggleModalActive('isModalInforman')"
              text="Enviar mensaje al informante" :invertColor="true" />
          </div>
          <div class="font-size-sm">
            <GeneralButton :isButton="true" @action="toggleModalActive('isModalExtendDeadline')"
              text="Ampliar plazo de resolución" :invertColor="true" />
          </div>
          <div class="font-size-sm">
            <GeneralButton :isButton="true" @action="toggleModalActive('isModalCaseClose')" text="Cerrar expediente" />
          </div>
        </div>
      </div>

      <!--Todos los modales de detalles del expediente-->
      <div>
        <!--Para crear y editar notas-->
        <ModalAddFile :titleActive="true" ref="noteModal" v-if="isModalActive && isModalNotes"
          placeholderText="Escriba aquí la nota" :title="isEditActive ? 'Editar nota' : 'Crear nueva nota'"
          :btnTitle="isEditActive ? 'Editar Nota' : 'Guardar Nota'" @handleButton="handleButton"
          @toggleModalActive="toggleModalActive('isModalNotes')" :isEditActive="isEditActive" :elemntData="noteElement"
          :infoMessageData="infoMessageData" :cleanInforMessage="cleanInforMessage" :isModalLoader="isModalLoader" />

        <!-- Para visualizar la nota -->
        <ModalVisualice v-if="isModalActive && isModalNoteVisualice"
          @toggleModalActive="toggleModalActive('isModalNoteVisualice')" :elemntData="noteElement"
          :isModalLoader="isModalLoader" :infoMessageData="infoMessageData" />

        <!-- Para enviar un mensaje al informante-->
        <ModalAddFile v-if="isModalActive && isModalInforman" placeholderText="Escriba aquí el mensaje"
          title="Enviar mensaje al informante" btnTitle="Enviar" @handleButton="sendMessageInforman"
          @toggleModalActive="toggleModalActive('isModalInforman')" />

        <!-- Para ampliar el plazo de resolucion -->
        <ModalExtendDeadline v-if="isModalActive && isModalExtendDeadline" placeholderText=""
          title="Solicitud de ampliación del plazo de resolución para el Expediente EX00024" btnTitle="Enviar solicitud"
          @handleButton="sendMessageInforman" @toggleModalActive="toggleModalActive('isModalExtendDeadline')" />

        <!-- Cerrar el expediente -->
        <ModalAddFile v-if="isModalActive && isModalCaseClose" placeholderText="Escriba aquí la resolución final"
          title="Resolución final" btnTitle="Confirmar y cerrar expediente" @handleButton="closeCase"
          @toggleModalActive="toggleModalActive('isModalCaseClose')" />
      </div>


      <!--Dos columnas principales de los detalles del expediente-->
      <div class="wrapper">
        <div class="column left">
          <div class="widget state-widget">
            <InfoMessage v-if="stateInfoMessage.isActive" :isActive="stateInfoMessage.isActive"
              :color="stateInfoMessage.icon === 'info' ? null : '#C74D4D'" :text="stateInfoMessage.text"
              :icon="stateInfoMessage.icon" />
            <div class="state">
              <div class="aling-center">
                <h2 class="font-size-sm">Estado</h2>
                <SelectComponent v-model:selectedValue="selectState" :optionArray="caseFilestates" />
              </div>
            </div>
          </div>

          <div class="widget state-widget ">
            <InfoMessage v-if="instructorInfoMessage.isActive" :styleDiv="{ marginBottom: '10px' }"
              :isActive="instructorInfoMessage.isActive" :color="stateInfoMessage.icon === 'info' ? null : '#C74D4D'"
              :text="stateInfoMessage.text" :icon="stateInfoMessage.icon" />
            <div class="instructor">
              <div class="aling-center">
                <h2 class="font-size-sm">Instructor</h2>
                <SelectComponent v-model:selectedValue="selectInstructor" :optionArray="caseFilesInstructor" />
              </div>
            </div>
          </div>


          <div class="widget casefile-info">
            <h2 class="font-size-very-sm">Faltan <span>3</span> días para el cierre del caso</h2>

            <ProgressBar :progress="progress" />

            <h2 class="title">
              {{ caseFile.subject }}
            </h2>

            <div class="inline-data">
              <p><span>Código</span>{{ caseFile.caseCode }}</p>
              <p><span>Fecha</span>{{ caseFile.createdAt }}</p>
            </div>

            <div class="inline-data">
              <p class="category">
                <span>Categoría</span>{{ caseFile.category?.name }}
              </p>
              <p><span>Prioridad</span>{{ caseFile.priority }}</p>
            </div>

            <div class="inline-data">
              <p><span>Tiempo última actividad</span>{{ caseFile.lastActivity }}</p>
            </div>

            <p>
              <span>Comentario final</span>Falta por crearse
            </p>

            <p v-if="isSubCaseFile"><span>Expediente a subsanar</span>{{ caseCodeParent }}</p>
          </div>

          <div class="widget subcasefiles">
            <span class="subcasefile-number">{{ caseFile.subCaseFiles?.length }}</span>
            <h2>Subexpedientes <span>asociados</span></h2>
            <div class="font-size-very-sm grid-full-space">
              <GeneralButton :href="'/expedientes/sub-expediente/' + identify" text="Gestionar Subexpedientes"
                :invertColor="true" fontSize="14px" />
            </div>
          </div>



          <!--NOTAS -->
          <div class="widget notes" ref="widgetNotes">
            <div ref="notesScroll" class="notes-flex">
              <div class="note-title">
                <h2>Notas</h2>
                <button @click="toggleModalActive('isModalNotes')"><img src="@/assets/icons/add-border.svg" /></button>
              </div>
              <div v-for="(note, index) in notesData" :key="note.id" class="note">
                <h3 :title="note.name">{{ note.name }}</h3>
                <div @click="(e) => calculateDistance(e)">
                  <Dropdown @toggleModalEdit="toggleModalEdit('isModalNotes', '/notes/', note.id)"
                    @toggleModalVisualice="toggleModalEdit('isModalNoteVisualice', '/notes/', note.id, 'visualice')"
                    @toggleModalDelete="toggleModalDelete(note.id)" @activeDropdownOpen="activeIsNoteDropdownOpen"
                    :isEditPosition="true"
                    :estilos="{ top: `${noteDropdownPositionY}px`, left: `${noteDropdownPositionX}px` }"
                    :ref="'dropdownNote' + index" :links="[
                      { text: 'Visualizar', data: { action: 'toggleModalVisualice' } },
                      { text: 'Editar', data: { action: 'toggleModalEdit' } },
                      { text: 'Eliminar', data: { action: 'toggleModalDelete' }, estilos: { color: '#C74D4D' } },
                    ]" />
                </div>
              </div>
            </div>
          </div>


        </div>

        <div class="column right">
          <div class="inline-wrapper">
            <!--Listado de tareas-->
            <div class="widget-container">
              <TaskListWidget :caseFilesInstructor="caseFilesInstructor" :caseFile="caseFile" />
            </div>

            <!--Descarga de la denuncia-->
            <div class="widget-container">
              <TextAreaFile :noFile="true" title="Denuncia del informante" textContent="Ut rhoncus sapien in urna bibendum lacinia. Nunc posuere mi quis sapien finibus, non tristique justo finibus. Etiam tempus fringilla lorem sit amet cursus. In accumsan, neque at iaculis venenatis, turpis odio dapibus mi, sit amet hendrerit ligula eros id magna. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Fusce ac lacus non lacus pretium pretium. Sed lacinia accumsan lacus. Praesent placerat orci elit, vel sollicitudin arcu dapibus sit amet. Pellentesque eu ultricies velit. Proin quis est vitae lorem fermentum dapibus non in tortor. Donec volutpat est nec nulla pellentesque, eget aliquam quam ultricies. Cras nec nisl ut leo vulputate pretium.

Cras faucibus, ex vel maximus pharetra, ipsum ligula euismod nisl, nec dictum lacus nibh sit amet velit. Sed facilisis, eros at rhoncus cursus, orci justo consequat metus, vitae dignissim neque tellus id sapien. Integer nec molestie erat. Cras nec risus erat. Proin at felis in mi feugiat pharetra vitae vel nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam sit amet efficitur lorem. Mauris et diam sed urna efficitur pulvinar. Aenean sed efficitur risus, ac imperdiet felis. Donec ultricies pulvinar mauris vitae sodales. In hac habitasse platea dictumst. Nam pulvinar iaculis arcu. Phasellus odio tortor, rhoncus sed justo lacinia, fermentum tincidunt nulla. Curabitur dapibus interdum euismod. Nunc laoreet ac lacus nec tincidunt.
              " DownloadButtonText="Descargar Denuncia" />
            </div>
          </div>

          <!--File explorer
          
          <div>
            <FileExplorer v-if="!matchesMediaQuery" title="Documentos" />
          </div>
          -->

        </div>
        <!-- -->
        <div class="file-explorer-outside-colum">
          <FileExplorer title="Documentos" />
        </div>
      </div>
    </div>

    <DeleteModalTable v-if="isDeleteActive" option="simple" :lastSingularId="deleteNoteIndetify"
      @toggleModal="toggleModalDelete" @trigger-fetch="getNotes(identify)" path="/notes"
      :deleteModalText="deleteModalText" />
  </div>
</template>

<script>
import GeneralButton from "@/components/general/Button.vue";
import ProgressBar from "@/components/general/ProgressBar.vue";
import Dropdown from "../general/Dropdown.vue";
import TaskListWidget from "../widgets/TaskListWidget.vue";
import TextAreaFile from "../widgets/TextAreaFile.vue";
import FileExplorer from "../widgets/FileExplorer.vue";
import generalTitle from "../general/generalTitle.vue";
import SelectComponent from "../general/SelectComponent.vue";
import ModalAddFile from "../general/ModalAddFile.vue";
import ModalExtendDeadline from "../general/ModalExtendDeadline.vue";
import DeleteModalTable from "../categories/DeleteModalTable.vue";
import ModalVisualice from "../general/ModalVisualice.vue";

import axios from "axios";
import { useRoute } from 'vue-router';
import { mapGetters, mapMutations } from 'vuex';
import LoaderComponent from "../general/LoaderComponent.vue";
import InfoMessage from "../general/InfoMessage.vue";

export default {
  name: "CaseFileDetailsComponent",
  components: {
    GeneralButton,
    ProgressBar,
    Dropdown,
    TaskListWidget,
    TextAreaFile,
    FileExplorer,
    generalTitle,
    SelectComponent,
    ModalAddFile,
    ModalExtendDeadline,
    DeleteModalTable,
    ModalVisualice,
    LoaderComponent,
    InfoMessage

  },
  props: {},

  data() {
    return {
      caseCodeParent: '',
      isSubCaseFile: false,
      isError: false,
      isLoader: false,
      isModalActive: false,
      isModalNotes: false,
      isModalInforman: false,
      isModalCaseClose: false,
      isModalExtendDeadline: false,
      isEditActive: false,
      isDeleteActive: false,
      isModalNoteVisualice: false,
      noteDropdownPositionX: 0,
      noteDropdownPositionY: 0,
      isNoteDropdownOpen: false,
      isModalLoader: false,
      infoMessageData: {
        isActive: false,
        isError: false,
        text: 'Se ha producido un error. Consulte con el servicio técnico.',
      },
      breadcrumbs: [{ label: 'Expedientes', to: '/expedientes' }, { label: 'Poner nombre', to: '' }],
      caseFile: {},
      selectState: "",
      selectInstructor: "",
      identify: "",
      matchesMediaQuery: false,
      caseFilestates: [],
      caseFilesInstructor: [],
      notesData: [],
      noteElement: {},
      deleteModalText: {
        title: '¿Desea eliminar la nota seleccionada?',
        textSingular: 'Se eliminará la nota seleccionada',
        errorSingular: 'No se puede eliminar la nota seleccionada porque se está usando en expedientes',
      },
      deleteNoteIndetify: '',
      infoMessage: {
        isActive: false,
        icon: 'error',
        text: 'Se ha producido un error. Consulte con el servicio técnico.',
      },
      stateInfoMessage: {
        isActive: false,
        icon: 'error',
        text: 'Se ha producido un error. Consulte con el servicio técnico.',
      },
      instructorInfoMessage: {
        isActive: false
      },
      progress: 20,
    };
  },
  computed: {
    ...mapGetters(['getUserCompany']),

    userCompany() {
      return this.getUserCompany;
    }
  },

  async created() {

    try {
      this.isLoader = true
      const router = useRoute();
      const { id } = router.params;
      this.breadcrumbs[1].to = `/expedientes/${id}`
      this.identify = id
      // añadir datos
      await this.getCaseFile(id)
      await this.getCaseFileStates()
      await this.getCaseFileInstructor()

      //añadir notas
      await this.getNotes(id)

      //Para solucionar el problema del fileExplorer que no se seleccionan los archivos durante la siguiente mediaquery
      this.mediaQuery = window.matchMedia("(min-width: 1200px) and (max-width: 1565px)");

      // Establece el valor inicial
      this.matchesMediaQuery = this.mediaQuery.matches;

      // Agrega el listener para detectar cambios
      this.mediaQuery.addListener(this.handleMediaQueryChange);

    }
    catch (error) {
      console.log(error)
      this.infoMessage.isActive = true

    }
    finally {
      this.isLoader = false
      this.$refs.notesScroll.addEventListener('scroll', this.handleScroll);
      this.toggleUserCompanyDisable()
    }



  },

  beforeUnmount() {
    this.mediaQuery.removeListener(this.handleMediaQueryChange);
    this.$refs.notesScroll.removeEventListener('scroll', this.handleScroll);
    this.toggleUserCompanyDisable()
  },
  watch: {
    selectState(newValue, oldValue) {
      if (oldValue === "") return
      this.updateState(newValue)

    },
    selectInstructor(newValue, oldValue) {
      if (oldValue === "") return
      this.updateInstructor(newValue)

    }
  },


  methods: {
    goBack() {
      window.history.back();
    },

    closeLoader() {
      this.isLoader = false
    },
    ...mapMutations(['toggleUserCompanyDisable']),
    handleButton(textArea) {
      if (this.isEditActive) {
        this.editNote(textArea);
      } else {
        this.saveNote(textArea);
      }
    },
    calculateDistance(e) {

      if (this.isModalActive || e.target.closest('.dropdown-menu') !== null) return

      //cogemos la posicion x e y del padre de las notas y del boton clicado
      const widgetNotes = this.$refs.widgetNotes;
      const dropdown = e.target

      const widgetRect = widgetNotes.getBoundingClientRect();
      const dropdownRect = dropdown.getBoundingClientRect();

      // buscamos la diferencia de ambos valores para saber cuanto top y left darle al elemento position absolute
      this.noteDropdownPositionX = dropdownRect.left - widgetRect.left
      this.noteDropdownPositionY = dropdownRect.top - widgetRect.top

      // si es movil, necesitamos quitarle px en left porque se sale de la pantalla al ser una sola columna
      if (window.innerWidth <= 768) {
        this.noteDropdownPositionX -= 50
      }

    },
    activeIsNoteDropdownOpen() {
      this.isNoteDropdownOpen = true
    },
    handleScroll() {
      //ocultar los dropdwon de notas cuando se haga scroll ya que debido al overflow

      //Para que solo se ejecute quitar los dropdown cuando este abierto uno y se haga scroll 
      if (!this.isNoteDropdownOpen) return
      this.notesData.forEach((_, index) => {
        const refName = 'dropdownNote' + index
        this.$refs[refName][0].closeDropdown()
      });
      this.isNoteDropdownOpen = false
    },
    async editNote(data) {

      const url = `/notes/${this.noteElement.id}`
      const dataPatch = {
        description: data.textArea,
        name: data.inputTitle,
        //TO DO: Usuario = usuario nuevo que ha editado o solo el que se creo ?¿
      }

      try {
        await axios.patch(url, dataPatch, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        this.infoMessageData.isActive = true
        this.infoMessageData.text = 'La nota se ha editado'
        this.$refs.noteModal.incrementCount()
      } catch (error) {
        this.infoMessageData.isActive = true
        this.infoMessageData.text = 'Se ha producido un error. Consulte con el servicio técnico.'
        this.infoMessageData.isError = true
        this.$refs.noteModal.incrementCount()
        console.log(error)
      }
      finally {
        this.getNotes(this.identify)
      }

    },
    cleanInforMessage() {
      this.infoMessageData = {
        isActive: false,
        isError: false,
        text: 'Se ha producido un error. Consulte con el servicio técnico.'
      }
    },
    async saveNote(data) {

      const dataNote = {
        instructor: "/api/v1/user_instructors/u_66c5be17939b9",
        description: data.textArea,
        caseFile: this.caseFile['@id'],
        name: data.inputTitle
      }

      try {
        await axios.post(`/notes`, dataNote);
        this.infoMessageData.isActive = true
        this.infoMessageData.text = 'La nota se ha creado correctamente'
        this.$refs.noteModal.cleanTextArea()
        this.$refs.noteModal.cleanInputTitle()

      } catch (error) {
        this.infoMessageData.isActive = true
        this.infoMessageData.text = 'Se ha producido un error. Consulte con el servicio técnico.'
        this.infoMessageData.isError = true
        this.$refs.noteModal.incrementCount()
        console.log(error)
      } finally {
        this.getNotes(this.identify)
      }
    },
    getNotes(id) {
      const url = `notes?caseFile.id=${id}&itemsPerPage=200`
      axios.get(url)
        .then(res => this.notesData = res.data['hydra:member'])
        .catch(err => {
          console.log(err)
          this.infoMessage.isActive = true
        })
    },
    sendMessageInforman(textAreaData) {
      console.log(textAreaData)
      //TO DO: Enviar mensaje al informante
    },
    closeCase(textAreaData) {
      console.log(textAreaData)
      //TO DO: implementar logica para cerrar el expediente
    },
    changePriorityNumberForText(number) {
      if (number === 1) {
        return 'Baja'
      } else if (number === 2) {
        return 'Media'
      } else {
        return 'Alta'
      }
    },

    handleMediaQueryChange(event) {
      // Actualiza la propiedad reactiva cuando cambia la media query
      this.matchesMediaQuery = event.matches;
    },

    toggleModalActive(specifyModal) {
      this.isModalActive = !this.isModalActive
      this[specifyModal] = !this[specifyModal]
      if (this.isEditActive) {
        this.isEditActive = false
      }
      this.cleanInforMessage()

    },
    toggleModalEdit(specifyModal, path, id, key) {
      this.toggleModalActive(specifyModal)
      if (key !== 'visualice') {
        this.isEditActive = true
      }

      this.isModalActive = true
      this.isModalLoader = true


      axios.get(path + id).then(res => this.noteElement = res.data)
        .catch(err => {
          console.log(err)
          this.infoMessageData.isActive = true
          this.infoMessageData.isError = true
        })
        .finally(() => {
          this.isModalLoader = false
        })

    },
    toggleModalDelete(id) {
      this.isDeleteActive = !this.isDeleteActive
      this.deleteNoteIndetify = id
    },


    dateFormated(dateData) {

      const date = new Date(dateData);
      let day = date.getUTCDate();
      if (day < 10) {
        day = '0' + day
      }
      let month = date.getUTCMonth() + 1;
      if (month < 10) {
        month = '0' + month
      }
      const year = date.getUTCFullYear();

      return `${day}/${month}/${year}`;
    },

    async getCaseFile(id) {
      const res = await axios.get(`/case_files/${id}`);
      this.caseFile = res.data
      this.selectState = res.data.state.name
      this.selectInstructor = res.data.instructor.name

      if (res.data['@type'] === 'SubCaseFile') {

        const subcaseResponse = await axios.get(`/sub_case_files/${id}`);

        this.caseCodeParent = subcaseResponse.data.parentCaseFile.caseCode

        this.breadcrumbs[1].label = 'Subexpediente ' + this.caseFile.caseCode
        this.isSubCaseFile = true
      } else {
        this.breadcrumbs[1].label = 'Expediente ' + this.caseFile.caseCode
      }

      this.caseFile.priority = this.changePriorityNumberForText(this.caseFile.priority)
      this.caseFile.createdAt = this.dateFormated(this.caseFile.createdAt)
      this.caseFile.lastActivity = this.dateFormated(this.caseFile.lastActivity)

    },

    async updateState(stateToModify) {
      const stateId = this.caseFilestates.filter(state => state.label === stateToModify)

      const dataPatch = {
        state: `/api/v1/states/${stateId[0].value}`
      }
      try {
        await axios.patch(`/case_files/${this.identify}`, dataPatch, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      }
      catch (err) {
        console.log(err)

        this.stateInfoMessage.isActive = true
        this.stateInfoMessage.icon = 'error'
        this.stateInfoMessage.text = 'Se ha producido un error. Consulte con el servicio técnico.'

      }
      finally {
        this.stateInfoMessage.isActive = true
        this.stateInfoMessage.icon = 'info'
        this.stateInfoMessage.text = 'Se ha modificado correctamente'

        //quitar el mensaje tras pasar un tiempo
        setTimeout(() => {
          this.stateInfoMessage.isActive = false
        }, 2000)
      }

    },

    async updateInstructor(instructorToModify) {
      const instructorId = this.caseFilesInstructor.filter(instructor => instructor.label === instructorToModify)

      const dataPatch = {
        instructor: `/api/v1/users/${instructorId[0].value}`
      }
      try {
        await axios.patch(`/case_files/${this.identify}`, dataPatch, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      }
      catch (err) {
        console.log(err)

        this.instructorInfoMessage.isActive = true
        this.stateInfoMessage.icon = 'error'
        this.stateInfoMessage.text = 'Se ha producido un error. Consulte con el servicio técnico.'

      }
      finally {
        this.instructorInfoMessage.isActive = true
        this.stateInfoMessage.icon = 'info'
        this.stateInfoMessage.text = 'Se ha modificado correctamente'

        //quitar el mensaje tras pasar un tiempo
        setTimeout(() => {
          this.instructorInfoMessage.isActive = false
        }, 2000)
      }
    },
    async getCaseFileStates() {
      //obtener todos los state de la compañia
      try {
        const response = await axios.get(`/states?company=${this.userCompany}&itemsPerPage=200`);
        const states = response.data["hydra:member"].map((state) => ({
          value: state.id,
          label: state.name,
        }));

        this.caseFilestates = states;
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        //para activar el catch en created
        throw new Error('Network response was not ok');
      }
    },

    async getCaseFileInstructor() {
      //obtener todos los isntructores
      try {
        const response = await axios.get(`/user_instructors?company=${this.userCompany}&itemsPerPage=200`);
        const instructor = response.data["hydra:member"].map((instructor) => ({
          value: instructor.id,
          label: instructor.name
        }));

        this.caseFilesInstructor = instructor;
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        //para activar el catch en created
        throw new Error('Network response was not ok');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  display: flex;
  justify-content: center;
}

.casefile-details {

  .aling-center {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 25px;

    h2 {
      margin: 0
    }



    @media (max-width: 400px) {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
  }


  .title-buttons {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 30px;


    .edit-casefile {
      display: inline-flex;
      align-items: flex-end;
      gap: 10px;
      min-width: fit-content;

      @media(max-width: 1024px) {
        align-items: center;
      }

      span {
        font-size: 30px;
        font-family: "InterRegular";
        font-weight: 400;
      }
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 24px;
      justify-content: flex-end;
    }

  }

  .state-widget {
    padding: 11px 24px;
  }


  .widget {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 24px;

    h2 {
      margin-top: 0;
    }
  }

  .wrapper {
    width: 100%;
    display: inline-flex;
    margin-top: 55px;

    .column.left {
      width: 411px;
      margin-right: 24px;


      .widget.state {
        width: 100%;
        display: inline-flex;
        align-items: center;
        padding: 11px 24px;

        h2 {
          font-size: 16px;
          color: #0e0e0f;
          margin-right: 20px;
          font-family: "InterSemiBold", sans-serif;
          margin-bottom: 0;
        }


      }

      .widget.casefile-info {
        padding: 24px 32px 32px 32px;

        h2 {
          font-size: 16px;
          font-family: "InterMedium", sans-serif;
          font-weight: 500;
          text-align: left;
          margin-bottom: 4px;


          span {
            font-size: 24px;
            font-family: "InterSemiBold", sans-serif;
            font-weight: 600;
          }
        }

        h2.title {
          color: #0e0e0f;
          margin-top: 24px;
          margin-bottom: 14px;
          font-size: 18px;
          font-family: "InterSemiBold", sans-serif;
          font-weight: 600;
        }

        p {
          width: 100%;
          margin-bottom: 12px;

          span {
            display: block;
            font-family: "InterMedium", sans-serif;
            font-size: 12px;
            color: #b3bbbc;

            margin-bottom: 8px;
          }

          text-align: left;

          font-family: "InterRegular",
          sans-serif,
          sans-serif;
          font-size: 14px;
          color: #0e0e0f;
        }

        .inline-data {
          width: 100%;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;

          p.category {
            max-width: 400px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* Limita a dos líneas */
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            white-space: normal;
            padding-right: 25px;
          }
        }
      }

      .widget.subcasefiles {
        display: inline-flex;
        align-items: center;
        padding: 24px;
        gap: 10px;



        span.subcasefile-number {
          font-size: 31px;
          font-family: "InterSemiBold", sans-serif;
        }

        h2 {
          font-size: 12px;
          text-align: left;
          font-weight: 500;
          font-family: "InterMedium", sans-serif;
          margin: 0;
        }

        .grid-full-space {
          margin-left: auto;

          & a:hover {
            border: 1px solid transparent
          }
        }

        a {
          display: inline-block;
          padding-block: 10px;
          padding-inline: 10px !important;
          width: fit-content !important;
        }

        @media (max-width: 400px) {
          display: grid;
          grid-template-columns: 30% 70%;

          .grid-full-space {
            grid-column: 1 / -1;
            width: 100%;
          }

          a {

            width: 100% !important;
          }
        }

      }



      .widget.notes {
        width: 100%;
        padding: 16px 18px 12px 24px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        position: relative;

        .note-title {
          display: flex;
          align-items: center;
          margin-right: 18px;

          button {
            display: flex;
            align-items: center;
          }
        }

        h2 {
          font-size: 16px;
          color: #0e0e0f;
          margin-right: 12px;
          font-family: "InterSemiBold", sans-serif;
          margin: 0 12px 0 0;
        }

        button {
          border: none;
          background: none;
          padding: 0;
          cursor: pointer;

          img {
            width: 28px;
          }
        }

        .notes-flex {
          margin-left: 0;
          display: flex;
          flex-wrap: nowrap;
          padding-bottom: 12px;
          display: flex;
          overflow-x: auto;
          white-space: nowrap;
          gap: 9px 13px;
        }

        .note {

          display: grid;
          grid-template-columns: auto auto;
          grid-template-rows: 32px;
          min-width: 100px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #e0ebee;
          padding: 4px 8px;
          margin-right: 0px;
          border-radius: 2px;

          h3 {
            font-size: 14px;
            color: #163f49;
            font-family: "InterMedium", sans-serif;
            margin: 0;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .column.right {
    .inline-wrapper {
      width: 100%;
      display: inline-flex;
      margin-bottom: 24px;
    }

    .widget-container {
      width: 50%;
      height: 340px;
      display: flex;
      align-items: stretch;
    }

    .widget-container:first-child {
      margin-right: 24px;
    }
  }
}

@media (min-width: 1566px) {
  .casefile-details {

    .wrapper {
      display: grid;
      grid-template-columns: 30% 35% 35%;
      grid-template-rows: auto auto;

      margin-top: 55px;

      .column.right {
        grid-column: 2 / -1;
        grid-row: 1 / 2;
      }

      .column.left {
        width: auto;
        grid-column: 1 / 2;
        grid-row: 1 / -1;
      }
    }

    .file-explorer-outside-colum {
      grid-column: 2 / -1;
      grid-row: 2 / -1;
    }

  }
}




@media (max-width: 1565px) {

  .casefile-details {
    .file-explorer-outside-colum {
      display: block;
      grid-column: 1 / -1;
    }

    .file-explorer-inside-colum {
      display: none;
    }

    .wrapper {
      display: grid;
      grid-template-columns: 411px auto;
      gap: 24px;
    }

    .column.left {
      margin-right: 0 !important;
    }

    .column.right {
      .inline-wrapper {
        flex-direction: column;
        gap: 24px;
        height: 100%;

      }

      .widget-container {
        width: 100%;
        display: flex;
        align-items: stretch;
        flex-grow: 1;
      }

      .widget-container:first-child {
        margin-right: 24px;
        order: 1
      }
    }
  }


}


@media (max-width: 1200px) {
  .casefile-details {

    .wrapper {
      width: 100%;
      flex-direction: column;
      display: flex;

      .column.left {
        width: 100%;
        display: grid;
        grid-template-columns: 50% calc(50% - 24px);
        grid-template-rows: auto auto auto auto;
        gap: 24px;


        .state-widget {
          align-self: flex-start;
        }

        .widget.state {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          justify-content: start;
          align-items: center;

        }

        .subcasefiles {
          grid-column: 1 / 2;
          grid-row: 3 / 4;
          align-self: center;
        }

        .casefile-info {
          grid-column: 2 / 3;
          grid-row: 1 / -1;
          margin-bottom: 0;
        }

        .notes {
          align-self: flex-end;
        }

      }
    }


  }
}


@media (max-width: 768px) {

  .font-size-very-sm {
    font-size: 12px !important;

    a {
      font-size: 12px !important;
    }
  }

  .font-size-sm {
    font-size: 14px !important;

    a {
      font-size: 14px !important;
    }
  }


  .widget.subcasefiles {
    margin-top: 24px;
  }

  .casefile-details {
    margin-bottom: 28px;


    .title-buttons {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;



      .edit-casefile {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px 0;
        width: 100%;
      }

      .buttons {
        flex-direction: column;
        width: 100%;
      }

      .edit-casefile,
      .buttons {

        div {
          width: 100%;

          a,
          button {
            width: 100% !important;

          }
        }
      }
    }

    .wrapper {
      width: 100%;
      flex-direction: column;
      margin-top: 24px;

      .column.left {
        width: 100%;
        margin-right: 24px;
        grid-template-columns: 100%;
        display: block;


        .widget.notes {
          display: block;

          .notes-flex {
            display: grid;
            align-items: center;
            grid-template-columns: repeat(auto-fill, minmax(96px, 1fr));
            margin-left: 0;
            overflow-x: hidden;
            flex-wrap: nowrap;
            padding-bottom: 12px;

            overflow-y: auto;
            max-height: 150px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
</style>
