<template>
    <div @mousedown="(e) => existModal(e)" class="modal">

        <div class="box">

            <div class="loader">
                <LoaderComponent v-if="isModalLoader" />
            </div>

            <InfoMessage :styleDiv="{ margin: '0' }" v-if="infoMessageData?.isActive"
                :isActive="infoMessageData?.isActive" :color="infoMessageData?.isError ? '#C74D4D' : ''"
                :text="infoMessageData?.text" :icon="infoMessageData?.isError ? 'error' : 'info'" />


            <div v-show="!isModalLoader && !infoMessageData?.isError" class="flex-container">


                <div class="title">
                    <div>
                        <h2>{{ elemntData.name }}</h2>
                        <p class="date">{{ date }}</p>
                    </div>
                    <p class="author"><span>Creada por:</span> {{elemntData?.createdPerson}}</p>
                </div>
                <textarea :value="elemntData.description" disabled>
            </textarea>
                <!-- Añadir lo de los archivos -->
                <div class="files" v-if="files?.length > 0">
                    <FileComponent v-for="file in files" :key="file.name" :file="file"
                        @eliminar-archivo="eliminarArcivo" />
                </div>
            </div>

            <button @click="this.$emit('toggleModalActive')" class="close">Cerrar</button>
        </div>

    </div>

</template>

<script>
import FileComponent from '../complaint-process/FileComponent.vue';
import InfoMessage from './InfoMessage.vue';
import LoaderComponent from './LoaderComponent.vue';


export default {
    name: "ModalVisualice",
    data() {
        return { date: '' }
    },
    components: { FileComponent, LoaderComponent, InfoMessage },
    props: {
        files: {
            type: Array,
            // required: true
        },
        elemntData: {
            type: Object
        },
        isModalLoader: {
            type: Boolean,
            required: false,
            default: false
        },
        infoMessageData: {
            type: Object
        },

    },
    methods: {
        existModal(e) {
            if (e.target.closest('.box')) return
            this.$emit('toggleModalActive')

        },
        formatedDate(fechaISO) {
            // Crear un objeto Date a partir de la cadena de fecha ISO 8601
            const fecha = new Date(fechaISO);

            // Extraer componentes de la fecha en la zona horaria local
            const dia = fecha.getDate().toString().padStart(2, '0'); // Día
            const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Mes (se suma 1 porque los meses en JavaScript son 0-indexados)
            const año = fecha.getFullYear().toString().slice(-2); // Últimos dos dígitos del año

            // Extraer componentes de la hora en la zona horaria local
            const horas = fecha.getHours().toString().padStart(2, '0'); // Hora en formato 24 horas
            const minutos = fecha.getMinutes().toString().padStart(2, '0'); // Minutos

            // Formatear la fecha y la hora en el formato deseado
            const fechaFormateada = `${dia}/${mes}/${año} - ${horas}:${minutos}`;

            return fechaFormateada;

        }
    },
    watch: {
        elemntData(newValue) {
            this.date = this.formatedDate(newValue.createdAt)
            
        }
    }
}

</script>

<style scoped lang="scss">
button {
    cursor: pointer;
}

.loader {
    margin-inline: auto;
}

.close {
    color: #163F49;
    font-size: 16px;
    font-family: 'InterMedium';
    border: 1px solid #163F49;
    background-color: transparent;
    border-radius: 4px;
    padding: 7px 14px;
    width: 100%;


}

.modal {

    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;
    padding-bottom: 20px;
    


    .box {
        width: 100%;
        max-width: 497px;
        background-color: #FFFFFF;
        padding: 24px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.flex-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.title {
    display: flex;
    flex-direction: column;
    gap: 14px;

    h2,
    .date {
        color: #0E0E0F;
        font-family: 'InterMedium';
        margin: 0;
    }

    h2 {
        font-size: 24px;

    }

    .date {
        font-size: 16px;
        margin-left: auto;

    }

    .author {
        text-align: right;
        font-family: 'InterRegular';
        color: #0E0E0F;

        span {
            font-family: 'InterMedium';
        }
    }

    &>div {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 14px;
    }

}

textarea {
    border: none;
    min-height: 206px;
    line-height: 18.2px;
    font-family: 'InterRegular';
    font-size: 14px;
    resize: none;
    color: #0E0E0F;
    padding-right: 5px;
    background-color: transparent;

}

.files {
    position: relative;
    width: calc(100% - 24px);
    margin: 0 10px 0 16px;
    border-top: none;
    border-radius: 0 0 7.03px 7.03px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    overflow-y: scroll;
    max-height: 75px;

}

@media (max-width: 475px) {
    .title {
        h2 {
            font-size: 20px;
        }
    }
}
</style>