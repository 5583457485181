<template>
    <div class="template">
        <div class="title">
            <img src="@/assets/icons/vector.svg" alt="vector" @click="goBack">
            <h1>Notificaciones</h1>
        </div>
        <div class="notificaciones-box">
            <div class="si-no-leidas">
                <button ref="noLeidasBtn" class="no-leidas active" @click="changeFilter(false)">No leídas</button>
                <button ref="leidasBtn" class="leidas" @click="changeFilter(true)">Leídas</button>
            </div>
            <div v-if="tabInRead">
                <div class="notificaciones">
                    <NotificationBox 
                        v-for="notification in notificationsRead" 
                        :key="notification.fileNumber"
                        :read="notification.read" 
                        :acceptance="notification.acceptance" 
                        :date="notification.date"
                        :time="notification.time" 
                        :instructor="notification.instructor"
                        :fileNumber="notification.fileNumber" 
                    />
                </div>
            </div>
            <div v-else>
                <div class="notificaciones">
                    <NotificationBox 
                        v-for="notification in notificationsNotRead" 
                        :key="notification.fileNumber"
                        :read="notification.read" 
                        :acceptance="notification.acceptance" 
                        :date="notification.date"
                        :time="notification.time" 
                        :instructor="notification.instructor"
                        :fileNumber="notification.fileNumber" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NotificationBox from '@/components/notificaciones/NotificationBox.vue';

export default {
    name: 'NotificationsComponent',
    components: {
        NotificationBox
    },
    props: {},
    data() {
        return {
            notifications: [
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
            ],
            notificationsRead: [],
            notificationsNotRead: [],
            tabInRead: false,
            loadingMore: false
        };
    },
    methods: {
        goBack() {
            window.history.back();
        },
        read() {
            this.notificationsRead = this.notifications.filter(notification => notification.read);
        },
        notRead() {
            this.notificationsNotRead = this.notifications.filter(notification => !notification.read);
        },
        changeFilter(isRead) {
            this.tabInRead = isRead;
            if (isRead) {
                this.$refs.leidasBtn.classList.add('active');
                this.$refs.noLeidasBtn.classList.remove('active');
            } else {
                this.$refs.noLeidasBtn.classList.add('active');
                this.$refs.leidasBtn.classList.remove('active');
            }
        },
        loadMoreNotifications() {
            setTimeout(() => {
                const newNotifications = [
                    { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                    { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                    { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                    { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                    { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                    { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                    { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                    { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                    { read: false, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                    { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                    { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                    { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                    { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                    { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                    { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                    { read: true, acceptance: true, date: "18/04/24", time: "12:13", instructor: "enblanco", fileNumber: 123456677 },
                    { read: true, acceptance: true, date: "18/04/23", time: "12:13", instructor: "enblanco" },
                ];
                this.notifications.unshift(...newNotifications);

                this.notRead();
                this.read();
                this.loadingMore = false;
            }, 1000);
        }
    },
    created() {
        this.notRead();
        this.read();
        document.addEventListener("scroll", () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.clientHeight;
            if (scrollPosition + windowHeight >= documentHeight - 200 && !this.loadingMore) {
                this.loadingMore = true;
                this.loadMoreNotifications();
            }
        });
    }
};
</script>

<style scoped lang="scss">
* {
    font-family: "InterRegular";
}

.template {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
        display: flex;
        margin: 0px 0 24px 0px;

        img {
            width: 8px;
            height: 15px;
            margin-top: 16px;
            margin-right: 26px;
            cursor: pointer;
        }

        h1 {
            width: 233px;
            height: 19px;
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            text-align: left;
            color: #163F49;
        }
    }

    .notificaciones-box {
        .si-no-leidas {
            display: flex;
            justify-content: center;

            button {
                width: 100%;
                height: 32px;
                background: none;
                border: none;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                line-height: 16.94px;
                text-align: center;
                width: 170px;
                color: #94A7AA;
                background-color: #E7F0F2;
            }

            .no-leidas {
                border-radius: 4px 0 0 0;
                margin-left: 24px;

            }

            .leidas {
                border-radius: 0 4px 0 0;
                margin-right: 36px;
            }

            .active {
                background-color: #CBDEE2;
                color: #365961;
            }
        }
    }

    .notificaciones {
        border-top: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
    
    }
}

@media (min-width: 769px) {
    .template {
        .title {
          

            img {
                width: 10px;
                height: 20px;
                margin-top: 30px;
            }

            h1 {
                width: 263px;
                height: 33px;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
                text-align: left;
                color: #163F49;
            }
        }

        .notificaciones-box {
            .si-no-leidas {
                justify-content: start;

                button {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 21.78px;
                    text-align: center;
                    width: 239px;
                    height: 48px;
                }

                .leidas {
                    margin: 0;
                    width: 239px;
                }

                .no-leidas {
                    margin: 0;
                }

                .active {
                    border-bottom: 4px solid #365961;
                }
            }

            .notificaciones {
                border-top: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
            }
        }
    }
}

@media (min-width: 1200px) {
    .template {
      

        .title {
        

            img {
                display: none;
            }

            h1 {
                width: 263px;
                height: 33px;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
                text-align: left;
                color: #163F49;
            }
        }

        .notificaciones-box {
            margin-left: 52px;
            margin-top: 52px;

            .si-no-leidas {
                justify-content: start;

                button {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 21.78px;
                    text-align: center;
                    width: 239px;
                    height: 48px;
                }

                .leidas {
                    margin: 0;
                    width: 239px;
                }

                .no-leidas {
                    margin: 0;
                }

                .active {
                    border-bottom: 4px solid #365961;
                }
            }

            .notificaciones {
                border-top: 1px solid var(--Gris--1-Dedavara, #B3BBBC);
            }
        }
    }
}
</style>